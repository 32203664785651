<template>
  <div class="media-share">
    <p>
      <strong
        class="markup--strong markup--p-strong"
        style="font-size: 20px; line-height: 46px"
        >Learn more about CPChain:<br /> </strong
      ><a
        class="markup--anchor markup--p-anchor link"
        href="https://twitter.com/cpchain_io"
        target="_blank"
        rel="nofollow noopener nofollow nofollow nofollow noopener nofollow nofollow noopener nofollow nofollow nofollow noopener"
        data-href="https://twitter.com/cpchain_io"
        ><strong class="markup--strong markup--p-strong">Twitter</strong></a
      ><strong class="markup--strong markup--p-strong link"> | </strong
      ><a
        class="markup--anchor markup--p-anchor"
        href="https://t.me/CPChain"
        target="_blank"
        rel="nofollow noopener nofollow nofollow nofollow noopener nofollow nofollow noopener nofollow nofollow nofollow noopener"
        data-href="https://t.me/CPChain"
        ><strong class="markup--strong markup--p-strong link"
          >Telegram</strong
        ></a
      ><strong class="markup--strong markup--p-strong link"> | </strong
      ><a
        class="markup--anchor markup--p-anchor link"
        href="https://reddit.com/r/CPChain_Official"
        target="_blank"
        rel="nofollow noopener nofollow nofollow nofollow noopener nofollow nofollow noopener nofollow nofollow nofollow noopener"
        data-href="https://reddit.com/r/CPChain_Official"
        ><strong class="markup--strong markup--p-strong link">Reddit</strong></a
      ><strong class="markup--strong markup--p-strong link"> | </strong
      >
      <!-- <a
        class="markup--anchor markup--p-anchor link"
        href="https://discord.gg/9agsfFJ8gg"
        target="_blank"
        rel="nofollow noopener nofollow nofollow nofollow noopener nofollow nofollow noopener nofollow nofollow nofollow noopener"
        data-href="https://discord.gg/9agsfFJ8gg"
        ><strong class="markup--strong markup--p-strong link"
          >Discord</strong
        ></a>
        <strong class="markup--strong markup--p-strong link"> | </strong> -->
        
        <a
        class="markup--anchor markup--p-anchor link"
        href="https://medium.com/@cpchain"
        target="_blank"
        rel="nofollow noopener nofollow nofollow noopener nofollow nofollow nofollow noopener"
        data-href="https://www.youtube.com/channel/UC3y901Z6TUYi3ciW2tDiAtg"
        ><strong class="markup--strong markup--p-strong link">Medium</strong></a
      ><strong class="markup--strong markup--p-strong link"> | </strong
      ><a
        class="markup--anchor markup--p-anchor link"
        href="https://github.com/cpchain"
        target="_blank"
        rel="nofollow noopener nofollow nofollow nofollow noopener nofollow nofollow noopener nofollow nofollow nofollow noopener"
        data-href="https://github.com/cpchain"
        ><strong class="markup--strong markup--p-strong link">GitHub</strong></a
      ><strong class="markup--strong markup--p-strong link"> | </strong
      ><a
        class="markup--anchor markup--p-anchor link"
        href="https://www.youtube.com/channel/UC3y901Z6TUYi3ciW2tDiAtg"
        target="_blank"
        rel="nofollow noopener nofollow nofollow noopener nofollow nofollow nofollow noopener"
        data-href="https://www.youtube.com/channel/UC3y901Z6TUYi3ciW2tDiAtg"
        ><strong class="markup--strong markup--p-strong link"
          >Youtube</strong
        ></a
      ><strong class="markup--strong markup--p-strong link"> | </strong
      ><a
        class="markup--anchor markup--p-anchor link"
        href="https://mp.weixin.qq.com/mp/profile_ext?action=home&#038;__biz=MzUwOTg2MjA5Ng==&#038;scene=124#wechat_redirect"
        target="_blank"
        rel="nofollow noopener nofollow nofollow noopener nofollow nofollow nofollow noopener"
        data-href="https://mp.weixin.qq.com/mp/profile_ext?action=home&#038;__biz=MzUwOTg2MjA5Ng==&#038;scene=124#wechat_redirect"
        ><strong class="markup--strong markup--p-strong link">WeChat</strong></a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "MediaList",
};
</script>
