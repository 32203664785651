<template>
  <section
    class="d-flex align-items-center justify-content-center position-relative application-pages security"
    :style='{"background-image": "url(" + img + ")"}'
  >
    <div class="container h-100">
      <div
        class="row pt-0 position-relative text-center h-100 flex-sm-column flex-column align-items-center justify-content-end justify-content-md-center justify-content-center"
      >
        <div class="text-center mt-sm-0 pt-sm-0 pt-0 mt-0">
          <h1
            class="col-10 col-sm-12 mt-4 mt-sm-0 px-0 mx-auto text-white poppins-font"
          >
            {{ title }}
          </h1>
        </div>

        <div
          class="pt-lg-5 flex-lg-row flex-column mt-sm-3 mt-3 d-flex main-buttons mx-auto"
        >
          <router-link
            :to="s.url"
            v-for="s in other_solutions"
            v-bind:key="s.name"
            style="margin-left: 5px;margin-right: 5px;"
          >
            <v-btn
              x-large
              depressed
            >
              {{ s.name }}
            </v-btn>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SolutionHeader",
  props: ["name", "others", "cover"],
  data() {
    return {
      title: this.name,
      img: this.cover,
      /**
       * {
       *   name: '',
       *   url: ''
       * }
       */
      other_solutions: this.others,
    };
  },
};
</script>

<style scoped>

section.application-pages {
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  max-height: 550px;
  margin-top: 100px;
  overflow: hidden;
  background-position: bottom;
  background-image: url("../../assets/img/solutions/security.jpg")
}

</style>
