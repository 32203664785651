<template>
  <div id="app" data-app="true">
    <Header />
    <transition name="fade">
      <router-view />
    </transition>
    <Footer />
  </div>
</template>
<script>
import { Header, Footer } from "@/layout";
import Vue from 'vue'

Vue.prototype.$ethers = window.ethers

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
Header {
  position: fixed;
}

.active {
  background: white;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

</style>
