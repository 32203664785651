<template>
  <div class="search-result">
    <search-header :q="q" />
    <section
      class="section-category py-5 d-flex align-items-center justify-content-center"
    >
      <div class="container h-100">
        <div class="row">
          <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 mx-auto">
            <h3 class="text-center w-100 my-3">Search Results for: {{ q }}</h3>
          </div>

          <div class="col-12 col-sm-12 mt-4">
            <hr class="middle-hr" />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-12 mt-4">
            <div
              class="text-center"
              v-if="loading"
              style="margin-top: 100px; margin-bottom: 100px"
            >
              <v-progress-circular
                indeterminate
                color="primary"
                :size="30"
              ></v-progress-circular>
            </div>
            <p style="text-align: center" v-if="!loading && this.count == 0">
              Sorry, but nothing matched your search terms. Please try again
              with some different keywords.
            </p>
          </div>
          <news-card
            v-for="item in items"
            v-bind:key="item.title"
            :news="item"
          />
        </div>

        <div v-if="!loading">
          <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="6"
          ></v-pagination>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NewsCard from "../NewsCard.vue";
import SearchHeader from "../SearchHeader.vue";
export default {
  components: { SearchHeader, NewsCard },
  props: {
    q: {
      required: true,
      type: String,
    },
  },
  name: "SearchResult",
  data() {
    return {
      loading: false,
      page: 1,
      count: 0,
      limit: 12,
      items: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    q() {
      this.items = [];
      this.fetchData();
    },
    page() {
      this.items = [];
      this.fetchData();
    }
  },
  computed: {
    pageLength() {
      return (this.count - this.count % this.limit)/ this.limit + (this.count % this.limit == 0 ? 0 : 1);
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http
        .get(`/news/search/?q=${this.q}&page=${this.page}`)
        .then((data) => {
          this.items = data.results;
          for (var i in this.items) {
            this.items[i].banner =
              process.env.VUE_APP_BASE_API + "/media/" + this.items[i].banner;
          }
          this.count = data.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.search-result {
  margin-top: 100px;
}
</style>
