<template>
  <main class="site-main" role="main">
    <solution-header :name="title" :others="others" :cover="cover"/>
    <!-- end of application-pages  -->
    <br/>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <v-card style="box-shadow: none;">
            <v-tabs v-model="tab" align-with-title show-arrows background-color="teal darken-3 accent-4">
              <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
              <v-tab v-for="item in i_solutions" :key="item.name">
                {{ item.name }}
              </v-tab>
            </v-tabs>
            <br/>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="s in i_solutions" :key="s.name">
                <v-card flat>
                  <solution
                    :name="'content$' + s.name"
                    :background="s.background"
                    :solution="s.solution"
                    :features="s.features"
                  />
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </main>
</template>

<script>
import { Solution, SolutionHeader } from "@/components/solution";
import store from '@/store'

export default {
  name: "PublicSecurity",
  props: ["name", "solutions", "img"],
  components: {
    Solution,
    SolutionHeader,
  },
  data() {
    return {
      tab: null,
      title: this.name,
      cover: this.img,
      others: [],
      /**
       * {
          name: 'Elevator Operation Data Record',
          background: {
            img: require('@/assets/img/solutions/slide-1.png'),
            content: 'Elevator operation data, such as the real-time height of the elevator, up & down signal, alarm status, and emergency power status, are all useful for running status monitoring, fault prediction, fault handling and accident rescue.'
          },
          solution: {
            content: 'By installing sensors in elevators and elevator hoistway, the key data needed for maintenance can be collected and uploaded to the CPChain platform.<br /><br /> By utilizing the characteristics of Blockchain, in which data can be traced back but cannot be tampered with, the data records of elevator movement and the snapshots of key maintenance operations are stored on the chain, to provide reliable records for ACI, insurance investigation and quality assessment of maintenance.'
          },
          features: [
            {
              img: require('@/assets/img/solutions/solution-1.png'),
              value: 'Medical Industry Side Chain'
            }
          ]
        }
       */
      i_solutions: this.solutions,
    };
  },
  created() {
    let all = store.state.industries
    for(var i in all) {
      if(all[i].name != this.title) {
        this.others.push(all[i])
      }
    }
  }
};
</script>
