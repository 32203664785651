<template>
  <section
    class="search-bar h-75 d-flex align-items-center justify-content-center position-relative bg-light"
  >
    <div class="container">
      <div class="col-12 px-0">
        <div
          class="navbar navbar-light d-flex flex-column flex-sm-row justify-content-between align-items-center flex-row w-100"
        >
          <a class="navbar-brand col-sm-6 col-12 mb-4 mb-sm-0">
            <h6 class="m-0">Official CPChain Media</h6>
          </a>
          <form
            class="form-inline col-sm-6 col-12 d-flex align-items-center justify-content-end"
            v-on:submit.prevent="submit"
          >
            <input
              class="form-control col-sm-10 col-12 mr-sm-2"
              name="q"
              v-model="q"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SearchHeader",
  props: {
    q: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    submit() {
      if (this.q) {
        this.$router.push(`/news/search/${this.q}`);
      }
    },
  },
};
</script>
