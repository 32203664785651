<template>
  <div>
    <v-row>
      <v-col lg="3" class="left">Staking Balance:</v-col>
      <v-col lg="8" class="right">{{ parseFloat(total_balance).toFixed(2) }}&nbsp;CPC</v-col>
    </v-row>
    <v-row>
      <v-col lg="3" class="left">Annual Revenue Rate:</v-col>
      <v-col lg="8" class="right">{{ parseFloat(ror).toFixed(2) }}&nbsp;%</v-col>
    </v-row>
    <v-row>
      <v-col lg="3" class="left">Reward Yesterday:</v-col>
      <v-col lg="8" class="right">{{ parseFloat(interest).toFixed(2) }}&nbsp;CPC</v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "StakingOverview",
  data() {
    return {
      //staking 数据快照
      ror:0,
      total_balance:0,
      interest:0,
    }
  },
  mounted() {
    this.fetchinterestsnapshot()
  },
  methods: {
    fetchinterestsnapshot(){  // staking interest-snapshot staking的相关信息获取
      this.$http.get('/staking/interest-snapshot/?limit=1').then(results => {
          let snapshot = results.results[0]
          this.ror = snapshot.ror*100
          this.total_balance = snapshot.total_balance
          this.interest = snapshot.interest
        })
    }
  }
}
</script>
<style scoped>
.left {
  margin-top: 13px;
  font-size: 17px;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}

.right {
  margin-top: 13px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 18px;
  font-weight: 400;
}
</style>
