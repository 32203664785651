<template>
  <v-container class="explorer-search-bar">
    <v-row>
      <v-col cols="2" lg="7"/>
      <v-col cols="10" lg="3">
        <v-text-field
          label="Address/Contract/Hash/Number"
          v-model="input"
          @keydown="onKeydown"
        ></v-text-field>
      </v-col>
      <v-col cols="0" lg="2"/>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      top
      centered
      :timeout="1500"
    >
      Please input Number/Block Hash/Transaction Hash/Address/Contract
    </v-snackbar>
  </v-container>
</template>

<script>
function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

export default {
  name: "SearchBar",
  data() {
    return {
      input: "",
      snackbar: false
    };
  },
  methods: {
    onKeydown(e) {
      if (e.key === "Enter") {
        let val = this.input;
        if (isNumeric(val)) {
          this.$router.push("/explorer/block/" + val);
        } else {
          if(!val.startsWith('0x')) {
            val = '0x'+ val
          }
          if(val.length == 42) {
            // address
            this.$router.push(`/explorer/address/${val}`)
          } else if(val.length == 66) {
            this.checkIfBlock(val)
          } else {
            this.snackbar = true
          }
        }
      }
    },
    checkIfBlock(hash) {
      this.$http
        .get(`/explorer/api/blocks/${hash}/`)
        .then(() => {
          this.$router.push("/explorer/block/" + hash);
        })
        .catch((err) => {
          if(err.response.status == 404) {
            this.$router.push("/explorer/tx/" + hash)
          }
        });
    }
  },
};
</script>

<style scoped>
.explorer-search-bar {
  background-color: #f7f9fc;
  min-width: 100%;
  border-bottom: #eaeaeaaa 1px solid;
}
</style>
