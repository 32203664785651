<template>
  <v-container>
    <div class="text-center" v-if="loading" style="margin-top: 100px; margin-bottom: 100px">
      <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <v-col lg="12">
        <div class="info-table" style="width: 100%; margin-left: 0px; margin-right: 0px">
          <div class="info-table-list">
            <div class="info-table-list-content">
              <div class="table">
                <div class="row list-header">
                  <div class="col text-truncate  ">Time</div>
                  <div class="col text-truncate  ">Block</div>
                  <div class="col text-truncate  ">Type</div>
                  <div class="col text-truncate  ">Value</div>
                  <div class="col text-truncate  ">Status</div>
                </div>
                <div v-if="txs.length == 0" style="
                        color: #aaa;
                        font-size: 0.8rem;
                        width: 100%;
                        text-align: center;
                      ">
                  No transactions
                </div>
                <div class="row row-content" v-for="tx in txs" v-bind:key="tx.id">
                  <div class="col text-truncate  ">
                    <span>Age:</span>{{ convertTime(tx.updated_at) }}
                    ago
                  </div>

                  <div class="col text-truncate  ">
                    <span>Block:</span>
                    <router-link :to="'/explorer/block/' + tx.lastBlockNumber" v-cloak>{{ tx.lastBlockNumber }}</router-link>
                  </div>
                  <div class="col text-truncate type-img "> 
                    <p :class="{
                          'interest': tx.op_type == 'INTEREST',
                          'withdraw': tx.op_type == 'WITHDRAW',
                          'deposit': tx.op_type == 'DEPOSIT',
                        }">{{ tx.op_type }}</p>
                  </div>

                  <div class="col text-truncate  ">
                    <span>Value:</span> {{ (+tx.amount).toFixed(8) }} CPC
                  </div>
                  <div class="col text-truncate  ">
                    <span>TxFee:</span>{{ tx.status }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <pagination style="margin-top: 20px" :page="page" :count="count" :limit="limit" :change="pageChange" v-if="  count > 0" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatTS } from "@/utils/ts";
import Pagination from "../Pagination.vue";

export default {
  components: { Pagination },
  name: "StakingList",
  props: ['address'],
  filters: {
    truncateAddr(addr) {
      if (addr.length < 40) {
        return addr
      }
      return addr.substr(0, 14) + "…"
    }
  },
  data() {
    return {
      loading: false,
      txs: [],
      page: 1,
      limit: 25,
      count: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    page() {
      this.fetchData();
    },
    address() {
      this.reload()
    },

  },
  methods: {
    reload() {
      this.page = 1;
      this.count = 0;
      this.fetchData();
    },
    pageChange(page) {
      this.page = page;
    },

    fetchData() {
      this.loading = true;
      let params = {
        offset: (this.page - 1) * this.limit,
        limit: this.limit,
      };
      if (this.address) {
        params.address = this.address;
      }

      this.$http
        .get(`/staking/fund-flow/`, { params: params })
        .then((data) => {
          console.log(!this.address, data)
          this.txs = data.results;
          this.count = data.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    convertTime: function (time) {
      let tss = new Date(time).getTime() / 1000
      var cts = parseInt((Date.now() + 500) / 1000);
      return formatTS(cts - tss);
    },
    convertDate(date) {
      return Math.round(new Date(date).getTime() / 1000);
    },
    getImg(type) {
      let img = ''
      switch (type) {
        case 'DEPOSIT':
          img = 'deposit.png'
          break
        case 'WITHDRAW':
          img = 'withdraw.png'
          break
        case 'INTEREST':
          img = 'income.png'
          break
      }
      return img
    }
  },
};
</script>

<style scoped>
.container {
  padding-left: 0 !important;
}
.row {
  padding-left: 15px !important;
}
.deposit {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #96DBFF;
  color: #138EED;
  margin-top: 15px;
}
.withdraw {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #ffeecf;
  color: #e77e1b;
  margin-top: 15px;
}
.interest {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #d9f7be;
  color: #5b8c00;
  margin-top: 15px;
}
.staking-type {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.type-img { 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
</style>
