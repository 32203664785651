import StackingAbi from './abi/staking.json'

const staking = {
    contractAddress: '0xFd25E1550e7030C9d2f3234C00a1187D0f580140',
    abi: StackingAbi,
}


const getContract = (ethers, { contractAddress, abi }) => {
    try {
        const provider =  ethers.getDefaultProvider('https://civilian.cpchain.io');  
        // 使用Provider 连接合约，将只有对合约的可读权限
        let contract = new ethers.Contract(contractAddress, abi, provider);
        return contract
    } catch (error) {
        console.log(error)
    }
}



export const StakingService = {
    /**
     * 获取质押合约实例
     * @returns 刷新用户操作状态
     */
    contract: (ethers) => {
        return getContract(ethers, staking)
    }
}