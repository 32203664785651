<template>
  <div class="site">
    <div class="site-content-contain">
      <div class="site-content">
        <search-header/>
        <div class="container d-flex align-items-center" style="padding-top: 1em; justify-content: right">
          <div class="col-4" style="font-size: 1.5em; display: flex; justify-content: right"><social-media /></div>
        </div>
        <!-- end of search bar -->
        <category
          v-for="item in categories"
          v-bind:key="item.name"
          :name="item.name"
          :category="item.category"
        />
      </div>
      <!-- #content -->
    </div>
    <!-- .site-content-contain -->
  </div>
  <!-- #page -->
</template>

<script>
import SocialMedia from '../../components/SocialMedia.vue';
import Category from "./Category.vue";
import SearchHeader from './SearchHeader.vue';

export default {
  components: { Category, SearchHeader, SocialMedia },
  name: "News",
  data() {
    return {
      categories_zh: [
        {
          category: "重大发布",
          name: "重大发布",
        },
        {
          category: "项目进展",
          name: "项目进展",
        },
      ],
      categories_en: [
        {
          category: "Official Announcement",
          name: "Official Announcement",
        },
        {
          category: "Community Updates",
          name: "Community Updates",
        },
        {
          category: "Community Events",
          name: "Community Events",
        },
      ]
    };
  },
  computed: {
    categories() {
      console.log(this.$store.state.locale)
      if(this.$store.state.locale == 'zhCN') {
        return this.categories_zh
      }
      return this.categories_en
    }
  }
};
</script>

<style type="text/css" scoped>
.site {
  margin-top: 100px;
}
</style>
