import Vue from 'vue'
import App from './App.vue'

import vuetify from '@/plugins/vuetify' // path to vuetify export
import VueI18n from 'vue-i18n'
import VueClipboard from 'vue-clipboard2'
import VueGtag from "vue-gtag";

import '@/styles/font-awesome.css'
import '@/styles/main.css'
import '@/styles/style.css'
import '@/styles/other.css'
import '@/styles/explorer.css'

import router from './router'
import store from './store'
import request from "@/utils/request";
import requestip from "@/utils/requestip"
import messages from './locales'

Vue.config.productionTip = false

Vue.prototype.$http = request
Vue.prototype.$httpip = requestip

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'enUS', // 设置语言 
  fallbackLocale: 'enUS',
  messages// 语言包
})

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

// Google Analysis (GA)
Vue.use(VueGtag, {
  config: { id: "G-10JWL164Y7" }
}, router);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
