<template>
  <v-container class="proposer-history-page">
    <search-bar />
    <v-row>
      <v-col lg="3" />
      <v-col lg="6">
        <v-row>
          <v-col lg="12">
            <div class="page-header">
              <div class="page-header-main">
                <p class="text-center poppins-font">Proposer History</p>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="body">
          <v-col lg="12">
            <v-row>
              <v-col lg="1">
                <img
                  :src="require('@/assets/img/explorer_contract.png')"
                  alt=""
                />
              </v-col>
              <v-col lg="11" class="address">
                <span style="margin-right: 15px">{{ address }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="12">
                <div class="txs-header">Blocks</div>
              </v-col>
            </v-row>

            <v-row class="pl-0">
              <v-col lg="12" class="pl-0">
                <blocks :address="address" class="pl-0" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="3" />
    </v-row>
  </v-container>
</template>

<script>
import Blocks from '../../../components/explorer/Blocks.vue';
import SearchBar from "../SearchBar.vue";
export default {
  components: { SearchBar, Blocks },
  name: "ProposerHistory",
  props: ["address"],
};
</script>

<style scoped>
.proposer-history-page {
  margin-top: 100px;
  margin-bottom: 50px;
  min-width: 100%;
  font-family: "Poppins", sans-serif !important;
}

.txs-header {
  border-bottom: solid 2px #305278;
  padding-bottom: 5px;
  width: 226px;
  font-size: 20px;
  font-weight: bold;
  margin: 36px 0px 15px;
}

.pl-0 {
  padding-left: 0px;
}

.info-table {
  margin: 0px !important;
}

</style>

