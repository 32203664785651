<template>
  <div class="card blog border-0 col-sm-4 my-4">
    <router-link :to="detail_url">
      <div class="image-holder position-relative">
        <img
          width="800"
          height="400"
          :src="item.banner"
          class="d-block w-100 h-100 img-fluid disable-select wp-post-image"
          alt=""
          sizes="(max-width: 767px) 89vw, (max-width: 1000px) 54vw, (max-width: 1071px) 543px, 580px"
          loading="lazy"
        />
      </div>
    </router-link>

    <div class="card-body px-0 pb-0">
      <h5 class="card-title">
        <router-link :to="detail_url" v-html="item.title"></router-link>
      </h5>

      <div class="card border-0 d-flex flex-row ml-0 w-100">
        <router-link :to="detail_url">
          <div class="image-smaller-holder">
            <img
              class="card-img-top bottom-cards disable-select h-100"
              :src="logo"
              alt="Card image cap"
              loading="lazy"
            />
          </div>
        </router-link>
        <div class="card-body py-0 d-flex align-items-center">
          <p class="card-text card-bottom-text">
            <a href=""> CPChain </a>
            <br />
            {{ item.update_time }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsCard",
  props: ["news"],
  data() {
    return {
      detail_url: "/news/" + this.news.id,
      logo: require("@/assets/img/logo.svg"),
      item: this.news,
    };
  },
};
</script>
