<template>
  <section class="section-thirteen pt-5" id="contact">
    <div class="container pt-sm-5 pt-0 pb-2s">
      <div class="row py-sm-5 py-0">

        <div class="left-side main col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div class="left-side-holder col-sm-9 col-12 px-0">
            <h3 class=" font-weight-bold poppins-font">{{$t('homepage.Your_opinion_is_important')}}</h3>
            <hr class="right-hr">
            <p class="pt-3">
              {{$t('homepage.If_you_have_suggestion')}}
            </p>
          </div>
        </div>

        <div class="right-side col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div class="right-side-holder">
            <div class="swiper-container-contact m-1">
              <div class="swiper-wrapper pb-sm-5">
                <div class="swiper-slide">
                  <form id="contact-form">
                    <div class="mx-3 form-group row flex-column">
                      <label for="inputEmail3" class="col-sm-5 col-form-label px-0"> {{$t('homepage.YourEmail')}} * </label>
                      <div class="col-sm-12 px-0">
                        <input v-model="email" type="email" name="email" class="form-control" id="inputEmail3" placeholder="" autocomplete="off" required>
                      </div>
                    </div>
                    <div class="mx-3 form-group ">
                      <label for="exampleFormControlTextarea1">{{$t('homepage.YourPurpose')}} * </label>
                      <textarea v-model="description" name="message" class="form-control" style="resize:none;" id="exampleFormControlTextarea1" maxlength="250" rows="3" required></textarea>
                    </div>
                    <div class="mx-3 form-group row">
                      <div class="col-sm-10 px-0">
                        <button @click="createSuggest" href='#' class="btn btn-sm btn-light rounded-circle font-weight-bold bg-white" target="_blank">
                          {{$t('homepage.SEND')}}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div> <!-- end of swiper-container-contact -->
          </div>

        </div> <!-- end of right side  -->

      </div>
    </div>
  </section> <!-- section-thirteen -->
</template>

<script> 

import request from "@/utils/request";

export default {
  name: "Market",
  data() {
    return {
      email: '',
      description: ''
    };
  },
  methods: {
    async createSuggest() {
      if (!this.email || !this.description) return
      let res = await request.post('/suggest/suggest/', {
        email: this.email,
        description: this.description,
        platform: 'website'
      })

      console.log(res, 'res')
      if (res.email) {
        alert(this.$t('homepage.Submitted'));
      } else if (res.detail) {
        alert(res.detail);
      }
    }
  },
};
</script> 

<style>
</style>