<template>
  <v-container class="address-page">
    <search-bar />
    <v-overlay :value="show_qr" @click.native="show_qr = false">
      <qrcode-vue :value="address" :size="300" level="H" />
    </v-overlay>
    <v-row>
      <v-col cols="0" lg="3" xl="3" />
      <v-col cols="12" lg="6" xl="6">
        <v-row >
          <v-col lg="12">
            <div class="page-header">
              <div class="page-header-main">
                <p class="text-center poppins-font">
                  {{ address_title }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12">
            <div class="text-center" v-if="loading" style="margin-top: 100px; margin-bottom: 100px">
              <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
            </div>
          </v-col>
        </v-row>
        <v-row class="body">
          <v-col cols="12" lg="12">
            <v-row v-if="!loading">
              <v-col cols="11" lg="11" xl="11">
                <img :src="require('@/assets/img/explorer_contract.png')" alt="" />
                <div class="address" style="display: inline-block; margin-left: 20px; position: relative; top: 2px;">
                  <span style="margin-right: 15px">{{ address | addrHandler }}</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="op" @mouseover="copy_hover = true" @mouseleave="
                          copy_hover = false;
                          copy_text = copy_text_before;
                        " v-bind="attrs" v-on="on" @click="copy">
                        <v-icon aria-label="Copy address" :dark="copy_hover">fas fa-copy</v-icon>
                      </span>
                    </template>
                    <span>{{ copy_text }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="op" @mouseover="qrcode_hover = true" @mouseleave="qrcode_hover = false" v-bind="attrs" v-on="on" @click="show_qr = true">
                        <v-icon aria-label="QR code" :dark="qrcode_hover">fas fa-qrcode</v-icon>
                      </span>
                    </template>
                    <span>Click to view QR code</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="op" @mouseover="reload_hover = true" @mouseleave="reload_hover = false" v-bind="attrs" v-on="on" @click="reload_page()">
                        <v-icon aria-label="reload" :dark="reload_hover">fas fa-sync-alt</v-icon>
                      </span>
                    </template>
                    <span>Reload this page</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="op" @mouseover="dapp_action_add_contacts = true" @mouseleave="dapp_action_add_contacts = false" v-bind="attrs" v-on="on" @click="show_add_to_contacts()">
                        <v-icon aria-label="reload" :dark="dapp_action_add_contacts">fas fa-address-book</v-icon>
                      </span>
                    </template>
                    <span>Add to contacts</span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row v-if="!loading">
              <v-col lg="3" class="overview"> Overview </v-col>
              <v-spacer />
            </v-row>
            <!-- 常规信息 -->
            <v-row v-if="!loading">
              <v-col lg="3" class="left">Balance:</v-col>
              <v-col lg="9" class="right">
                {{ balance }} CPC
                <span v-if="is_rnode" class="locked-balance-hint">(include the 200k CPC locked in the RNode contract)</span>
              </v-col>
            </v-row>
            <v-row v-if="!loading">
              <v-col lg="3" class="left">Transactions:</v-col>
              <v-col lg="8" class="right">{{ txs_count }}&nbsp;txs</v-col>
            </v-row>
            <!-- 合约信息 -->
            <v-row v-if="creator && !loading">
              <v-col lg="3" class="left">Contract Creator:</v-col>
              <v-col lg="8" class="right">
                <router-link :to="'/explorer/address/' + creator">{{
                  creator | addrHandler
                }}</router-link>
              </v-col>
            </v-row>
            <!-- RNODE信息 -->
            <v-row v-if="total_reward > 0 && !loading">
              <hr />
            </v-row>
            <v-row v-if="proposer_history_cnt > 0 && !loading">
              <v-col lg="3" class="left">Proposer History:</v-col>
              <v-col lg="8" class="right">
                <router-link :to="'/explorer/proposer-history/' + this.address">{{ proposer_history_cnt }}&nbsp;blocks</router-link>
              </v-col>
            </v-row>
            <v-row v-if="total_reward > 0 && !loading">
              <v-col lg="3" class="left">Total Reward:</v-col>
              <v-col lg="8" class="right">{{ total_reward }}&nbsp;CPC</v-col>
            </v-row>
            <v-row v-if="is_rnode && !loading">
              <v-col lg="3" class="left">Today Reward:</v-col>
              <v-col lg="8" class="right">{{ today_reward }}&nbsp;CPC</v-col>
            </v-row>
            <v-row v-if="is_rnode && !loading">
              <v-col lg="3" class="left">Today Blocks:</v-col>
              <v-col lg="8" class="right">{{ today_blocks }}&nbsp;blocks</v-col>
            </v-row>
            <!-- staking 信息 -->
            <v-row v-if=" !creator &&  !loading">
              <hr />
            </v-row>
            <v-row v-if=" !creator && !loading">
              <v-col lg="3" class="left">Staking Balance:</v-col>
              <v-col lg="8" class="right">{{ stakingBalance }}&nbsp;CPC</v-col>
            </v-row>
            <v-row v-if="!creator && !loading">
              <v-col lg="3" class="left">Staking Rewards:</v-col>
              <v-col lg="8" class="right">{{ stakingRewards }}&nbsp;CPC</v-col>
            </v-row>
            <!-- 总的staking 信息 -->
            <div v-if="overview && !loading">
              <v-row>
                <hr />
              </v-row>
              <component v-bind:is="overview"></component>
            </div>
            <!-- RNODE奖励历史图标 -->
            <v-row v-if="is_rnode &&!loading">
              <v-col lg="12" v-if="reward_history.count > 0">
                <chart :chart_data="reward_history.results" />
              </v-col>
            </v-row>
            <!-- 交易记录 -->
            <v-row :style="{ 'margin-top': (is_rnode &&!loading && reward_history.count > 0) ? '-84px' : '0px'}">
              <v-col lg="12" md="12">
                <v-tabs v-model="tab" style="margin-top:60px;margin-bottom:40px;width:300px">
                  <v-tab>Transactions</v-tab>
                  <v-tab>{{ section_title }}</v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-row v-if="!creator">
                  <v-col lg="3" md="3">
                    <v-select :items="tx_selectors" v-model="tx_selector" disable-lookup dense style="margin-top: 21px;"></v-select>
                  </v-col>
                  <v-col lg="3" md="3">
                    <v-text-field label="From" v-model="query_from"></v-text-field>
                  </v-col>
                  <v-col lg="3" md="3">
                    <v-text-field label="To" v-model="query_to"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="4" md="4" v-if="total_reward > 0">
                    <v-checkbox class="exclude-checkbox" v-model="exclude_campaign" label="Exclude campaign txs"></v-checkbox>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row style="margin-left:-28px!important">
                  <v-col lg="12" style="padding: 0px;">
                    <txs :address="handle(address)" :key="updateListCnt" :tx_type="tx_selector" :query_from="query_from" :query_to="query_to" :query_exclude_campaign="exclude_campaign" />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item style="margin-left:-14px!important">
                <v-row>
                  <v-col lg="12" style="padding: 0px;">
                    <component :address="handle(address)" v-bind:is="list_compoent"></component>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from "../SearchBar.vue";
import QrcodeVue from "qrcode.vue";
import Txs from "@/components/explorer/Txs";
import Staking from "@/components/explorer/Staking";

import Chart from './Chart.vue';
import { is_mobile } from '@/utils'
import { StakingService } from '@/utils/contract';
import NodeList from '../../../components/explorer/NodeList.vue';
import IdentityList from '../../../components/explorer/Identity.vue';
import StakingOverview from './components/staking/Overview.vue'
// TODO 本组件在 AWS Amplify 环境下无法正常运行，暂时先放弃
// import EncryptedChatList from '../../../components/explorer/EncryptedChatList.vue'
import EncryptedGroupChatList from '../../../components/explorer/EncryptedGroupChatList.vue'

const STAKING_ADDRESS = '0xFd25E1550e7030C9d2f3234C00a1187D0f580140'
const IDENTITY_ADDRESS = '0xC53367856164DA3De57784E0c96710088DA77e20'
// const ENCRYPTEDCHAT_ADDRESS = '0x856c36486163dB6f9aEbeD1407a3c6C51FD7566E'
const ENCRYPTEDGROUPCHAT_ADDRESS = '0x99a1241822011A95aBF6d32c00BB3dF0A6b717Ee'

const Contracts  = {
  [STAKING_ADDRESS.toLowerCase()]: {
    'title': 'Staking Contract',
    'section-title': 'Nodes',
    'component': 'NodeList',
    'overview': 'StakingOverview'
  },
  [IDENTITY_ADDRESS.toLowerCase()]: {
    'title': 'Identity Contract',
    'section-title': 'Identities',
    'component': 'IdentityList'
  },
  // [ENCRYPTEDCHAT_ADDRESS.toLowerCase()]: {
  //   'title': 'Encrypted Chat Contract',
  //   'section-title': 'ChatList',
  //   'component': 'EncryptedChatList'
  // },
  [ENCRYPTEDGROUPCHAT_ADDRESS.toLowerCase()]: {
    'title': 'Encrypted GroupChat Contract',
    'section-title': 'GroupList',
    'component': 'EncryptedGroupChatList'
  }
}

export default {
  components: { SearchBar, QrcodeVue, Txs, Chart, Staking, NodeList, IdentityList, StakingOverview, EncryptedGroupChatList }, /* EncryptedChatList */
  name: "Address",
  props: ["address"],
  data() {
    return {
      tab: null,
      is_mobile: is_mobile,
      loading: false,
      show_qr: false,

      balance: 0,
      is_rnode: false,
      creator: false,  //临时更改，仅用于开发，之后需要改回来
      proposer_history_cnt: 0,
      txs_count: 0,
      copy_hover: false,
      qrcode_hover: false,
      reload_hover: false,
      dapp_action_add_contacts: false,
      copy_text_before: "Copy address to clipboard",
      copy_text_after: "The address have been copied",
      copy_text: this.copy_text_before,

      total_reward: 0,
      today_reward: 0,
      today_blocks: 0,

      updateListCnt: 0,

      tx_selectors: ['ALL', 'IN', 'OUT'],
      tx_selector: "ALL",
      exclude_value_0: false, // 排除 value 为 0 的交易

      // reward history for chart
      reward_history: {
        count: 0,
        results: []
      },

      query_from: '',
      query_to: '',
      exclude_campaign: true, // 默认不包含 Campaign 交易

      // staking data
      stakingBalance: 0,
      stakingRewards: 0,
    };
  },
  computed: {
    address_title() {
      if (Contracts[this.address.toLowerCase()]) {
        return Contracts[this.address.toLowerCase()]['title']
      }
      if (this.creator) {
        return 'Contract'
      }
      return "Address"
    },
    section_title() {
      if (Contracts[this.address.toLowerCase()]) {
        return Contracts[this.address.toLowerCase()]['section-title']
      }
      return 'Staking'
    },
    overview() {
      return Contracts[this.address.toLowerCase()] && Contracts[this.address.toLowerCase()]['overview']
    },
    list_compoent() {
      if (Contracts[this.address.toLowerCase()]) {
        return Contracts[this.address.toLowerCase()]['component']
      }
      return 'Staking'
    }
  },
  watch: {
    address() {
      this.reward_history = {
        count: 0,
        results: []
      }
      this.is_rnode = false
      this.tx_selector = "ALL";
      this.fetchData();
    },
  },
  filters: {
    addrHandler(address) {
      if (is_mobile) {
        let len = 8
        return address.substr(0, len) + "…" + address.substr(address.length - len, len)
      }
      return address
    }
  },
  created() {
    document.title = "Address " + this.$route.params.address + " - CPChain"
    this.copy_text = this.copy_text_before;
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchStakingBalance() {
      const contract = StakingService.contract(this.$ethers)
      contract.balanceOf(this.handle(this.address)).then(val => {
        this.stakingBalance = this.$ethers.utils.formatEther(val, { commify: true })
      }).catch(err => console.log(err))
    },
    fetchStakingStats() {
      this.$http.get(`/staking/stats/${this.handle(this.address)}/`).then(stats => {
        this.stakingRewards = stats.total_interest
      })
    },
    forceUpdateTxs() {
      this.updateListCnt += 1;
    },
    handle(address) {
      if (address[0] != '0' && address[1] != 'x') {
        return '0x' + address
      }
      return address
    },
    fecthRNodesReward(address) {
      return this.$http.get(`/chain/rnodes-reward/?address=${this.handle(address)}`);
    },
    fecthRewardHistory(address) {
      return this.$http.get(`/chain/reward-history/${this.handle(address)}/`);
    },
    fetchData() {
      this.loading = true;
      this.fetchStakingBalance()
      this.fetchStakingStats()
      this.$http
        .get(`/explorer/api/address/${this.handle(this.address)}/`)
        .then((data) => {
          this.is_rnode = data.is_rnode;
          this.creator = data.creator;   
          this.balance = data.balance;
          this.proposer_history_cnt = data.proposer_history;
          this.txs_count = data.txs_count;
          Promise.all([
            this.fecthRNodesReward(this.address),
            this.fecthRewardHistory(this.address),
          ])
            .then((results) => {
              // rnode reward
              let rnodesReward = results[0];
              this.total_reward = rnodesReward["reward"];
              this.today_reward = rnodesReward["today_reward"];
              this.today_blocks = rnodesReward["today_blocks"];
              var now = new Date();
              var ts = new Date(rnodesReward["timestamp"]);
              if ((now - ts) / 1000 / 60 / 60 >= 24) {
                this.today_reward = 0;
                this.today_blocks = 0;
              }
              // reward history
              this.reward_history = results[1]
              this.reward_history.results.reverse()

              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    copy() {
      this.$copyText(this.address).then(
        () => {
          this.copy_text = this.copy_text_after;
        },
        (err) => {
          console.log(err);
        }
      );
    },
    reload_page() {
      this.fetchData()
      this.forceUpdateTxs()
      this.reload_hover = false
    },
    show_add_to_contacts() {
      // TODO 移动端 v1.5.1 拼写有问题，AddContract 应为 AddContact，等移动端校正后，此处要修改
      const content = `APPLICATION:
APP_NAME:Identity
ACTION:AddContract
ARGS:"${this.address}"
END`
      const data = {
        'client': 'CPC-Wallet',
        'version': 'v1.5.3',
        'content': content
      }
      const s = JSON.stringify(data)
      const url = process.env.VUE_APP_BASE_API + '/qr_code/?data=' + encodeURIComponent(s)
      console.log(url)
      window.open(url, '_blank').focus()
    }
  },
};
</script>

<style scoped>

* {
  text-transform: none !important;
}

.v-tab {
  padding-left: 0;
  /* padding-right: 20px; */
  /* margin-right: 10px; */
  padding-bottom: 5px;
  font-size: 24px;
  font-weight: bold;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0 !important;
}
.address-page {
  margin-top: 100px;
  margin-bottom: 50px;
  min-width: 100%;
  font-family: "Poppins", sans-serif !important;
}

.body {
  padding-left: 0px;
}

.body .address {
  font-size: 18px;
}

.body .address .op {
  margin-left: 5px;
  padding: 3px 7px;
  border-radius: 50%;
  box-shadow: 0 0 4px #eee;
  cursor: pointer;
}

.body .address .op:hover {
  background-color: #999;
}

.body .address .v-icon {
  font-size: 14px;
  line-height: 18px;
}

.overview {
  font-size: 25px;
  font-weight: bold;
  margin-top: 54px;
}

.locked-balance-hint {
  color: #cecece;
  font-size: 0.8em;
  font-style: italic;
}

.left {
  margin-top: 13px;
  font-size: 17px;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}

.right {
  margin-top: 13px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 18px;
  font-weight: 400;
}

.txs-header {
  border-bottom: solid 2px #305278;
  padding-bottom: 5px;
  width: 226px;
  font-size: 25px;
  font-weight: bold;
  margin: 36px 0px 15px;
}
</style>
