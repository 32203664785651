<template>
  <header class="py-4 mx-auto w-100" id="header">
    <!-- <Airdrop /> -->
    <div class="container">
      <div class="row">
        <!--logo-->
        <div class="col-3 d-flex flex-row align-items-center">
          <a href="/" class="logo-hov">
            <div class="image-header-holder d-flex flex-row align-items-center justify-content-start">
              <img src="@/assets/img/logo_new.svg" class="disable-select" />
            </div>
          </a>
        </div>
        <div class="menu col-9 d-flex align-items-center justify-content-end">
          <ul :class="{
              'list-unstyled': true,
              mainul: true,
              'flex-column': true,
              'flex-md-row': true,
              sidenav: true,
              'd-flex': true,
              'align-items-xl-center': true,
              'justify-content-xl-between': true,
              'align-items-md-center': true,
              'justify-content-md-between': true,
              'align-items-lg-center': true,
              'justify-content-lg-between': true,
              'align-items-md-center': true,
              'justify-content-md-between': true,
              'align-items-start': true,
              'justify-content-start': true,
              'w-100': true,
              'mb-0': true,
              active: menuOpened,
              'col-8': menuOpened,
            }" id="mySidenav">
            <li v-if="!is_testnet">
              <router-link to="/downloadApp" class="font-weight-bold" @click="openMenu(0)">
                {{ $t("download") }}
              </router-link>
            </li>
            <li class="d-md-none d-block w-100">
              <a href="javascript:void(0);" class="closebtn text-right p-0" @click="openMenu(0)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff" />
                </svg>
              </a>
            </li>
            <li v-if="is_testnet" style="margin-left: -2em; font-size: 1.5em;">
              <router-link to="/explorer" class="font-weight-bold" @click="openMenu(0)">
                Explorer
              </router-link>
            </li>
            <li v-if="is_testnet" style="margin-left: -2em; font-size: 1.5em;">
              <router-link to="/faucet" class="font-weight-bold" @click="openMenu(0)">
                Faucet
              </router-link>
            </li>
            <!-- TODO 优化此处，需优化整个 Header 的样式-->
            <li v-if="is_testnet"></li>
            <li v-if="is_testnet"></li>
            <li v-if="is_testnet"></li>
            <li v-if="is_testnet"></li>
            <li v-if="is_testnet"></li>
            <li v-if="is_testnet"></li>
            <li v-if="is_testnet"></li>
            <li v-if="is_testnet"></li>
            <li v-if="is_testnet"></li>
            <li v-if="!is_testnet">
              <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <a class="font-weight-bold" v-bind="attrs" v-on="on" @click="openMenu(0)">
                    {{ $t("Ecosystem") }}
                  </a>
                </template>

                <v-list>
                  <v-list-item v-for="(item, index) in ecosystemItems" :key="index">
                    <v-list-item-title>
                      <a :href="'#' + item.url" style="color: #1ba3ff; cursor: pointer">{{ $t(item.title) }}</a>
                    </v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item v-if="!is_testnet">
                    <v-list-item-title>
                      <a target="_blank" href="https://airdrop.cpchain.io" style="color: #1ba3ff; cursor: pointer" @click="openMenu(0)">
                        Claim Airdrop
                      </a>
                    </v-list-item-title>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </li>
            <li v-if="!is_testnet">
              <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <a class="font-weight-bold" v-bind="attrs" v-on="on" href="#/application" @click="openMenu(0)">
                    {{ $t("solutions") }}
                  </a>
                </template>

                <v-list>
                  <v-list-item v-for="(item, index) in items" :key="index">
                    <v-list-item-title>
                      <a :href="'#' + item.url" style="color: #1ba3ff; cursor: pointer">{{ $t(item.title) }}</a>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </li>
            <li v-if="!is_testnet">
              <router-link to="/roadmap" class="font-weight-bold" @click="openMenu(0)">
                {{ $t("roadmap") }}
              </router-link>
            </li>
            <li v-if="!is_testnet">
              <router-link to="/news" class="font-weight-bold" @click="openMenu(0)">
                {{ $t("community") }}
              </router-link>
            </li>
            <li v-if="!is_testnet">
              <a target="_blank" href="https://docs.cpchain.io" class="font-weight-bold" @click="openMenu(0)">
                {{ $t("documentation") }}
              </a>
            </li>
            <li v-if="!is_testnet">
              <router-link to="/explorer" class="font-weight-bold" @click="openMenu(0)">
                {{ $t("explorer") }}
              </router-link>
            </li>
            <li v-if="!is_testnet">
              <router-link to="/contact" class="font-weight-bold" @click="openMenu(0)">
                {{ $t("contact") }}
              </router-link>
            </li>
            <li class="line" v-if="!is_testnet"></li>
            <li class="dropdown bg-transparent" v-if="!is_testnet">
              <div class="dropdown-holder d-flex flex-row align-items-center justify-content-center">
                <a class="dropbtn-links p-0 small font-weight-bold d-none d-md-block" v-html="current_locale_name">
                </a>
                <div class="image-holder d-flex align-items-center justify-content-center">
                  <svg height="24px" class="d-none d-md-block" id="723dc0ec-7619-4a51-9a43-c524926a5ebc" data-name="drop_down_arrow" xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24">
                    <g opacity="0.4">
                      <path d="M7,10l5,5,5-5Z" fill="#305278" />
                      <path d="M0,0H24V24H0Z" fill="none" />
                    </g>
                  </svg>
                </div>
                <ul class="dropdown-menu list-unstyled rounded">
                  <a class="small" v-for="(i, index) in locales" v-bind:key="i.name" v-html="i.name" v-on:click="changeLocale(index)"></a>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-8 menuopen position-absolute d-flex justify-content-end align-items-center">
          <a @click="openMenu(1)"><i class="fas fa-bars d-block d-md-none"></i></a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// import Airdrop from './airdrop.vue';

window.addEventListener("scroll", function () {
  var header = document.getElementById("header");
  if (header != null) {
    window.scrollY > 30
      ? header.classList.add("active")
      : header.classList.remove("active");
  }
});

export default {
    name: "Header",
    data() {
        return {
            ecosystemItems: [
                // { title: 'CPChainWallet', url: "/downloadApp" },
                {
                    title: "DApps",
                    url: "/dapp/market",
                },
                { title: "map", url: "/explorer/map" },
            ],
            items: [
                { title: "public_security", url: "/solutions/public_security" },
                {
                    title: "intelligent_transportation",
                    url: "/solutions/intelligent_transportation",
                },
                { title: "smart_medical", url: "/solutions/smart_medical" },
            ],
            current_locale: 0,
            locales: [
                {
                    name: "EN",
                    locale: "enUS",
                },
                {
                    name: "中文",
                    locale: "zhCN",
                },
            ],
            menuOpened: false,
        };
    },
    computed: {
        current_locale_name() {
            return this.locales[this.current_locale].name;
        },
        is_testnet() {
            return process.env.VUE_APP_IS_TESTNET === "true";
        }
    },
    mounted() {
        if (this.$store.state.locale) {
            this.$i18n.locale = this.$store.state.locale;
            this.locales.forEach((l, i) => {
                if (l.locale == this.$store.state.locale) {
                    this.current_locale = i;
                }
            });
        }
    },
    methods: {
        openMenu(type) {
            this.menuOpened = type;
        },
        changeLocale(index) {
            this.current_locale = index;
            this.$store.dispatch("changeLocale", {
                i18n: this.$i18n,
                locale: this.locales[this.current_locale].locale
            });
        },
    },
    components: { }
};
</script>


<style type="text/css">
#header {
  box-shadow: none !important;
}

.recentcomments a {
  display: inline !important;
  padding: 0 !important;
  margin: 0 !important;
}

header {
  font-family: "Poppins", sans-serif !important;
}
* {
  padding: 0;
  margin: 0;
}

.swiper-pagination-main {
  text-align: center;
}

/* 手机端 */
@media (min-width: 125px) {
  .roadmap-web {
    display: none;
  }

  .roadmap-mob {
    display: block;
  }

  .videoContainer {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, auto) !important;
  }

  .website {
    display: none;
  }

  .mob {
    width: 100%;
    height: 12.24rem;
    /* background-image:  url("/static/img/mainnetwelcome/bubble.png");    */
    background-image: url("/static/img/wallet/bj.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2.64rem;
    padding-right: 2.16rem;
  }

  .mobleft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3.12rem 0;
  }

  .cpcwallet {
    width: 8.88rem;
    height: 2rem;
    font-size: 1.44rem;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: rgba(48, 82, 120, 1);
    line-height: 2.16rem;
  }

  .info {
    width: 15.84rem;
    height: 1.36rem;
    font-size: 0.96rem;
    font-family: "Poppins-Regular";
    font-weight: 400;
    color: rgba(48, 82, 120, 1);
    line-height: 1.44rem;
    margin-top: 0.24rem;
    margin-bottom: 0.32rem;
  }

  .split {
    width: 1.68rem;
    height: 2px;
    background-color: #305278;
  }

  .btnwarp {
    width: 9.28rem;
    height: 2.4rem;
    border-radius: 1.6rem;
    background-image: url("/static/img/wallet/button.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  .btntext {
    /* width: 5.68rem; */
    /* height: 17px; */
    font-size: 0.96rem;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: rgba(48, 82, 120, 1);
    /* line-height: 18px; */
  }

  .mobright {
    padding-top: 2.71rem;
    padding-left: 1.36rem;
  }
}

/* 电脑端 */
@media (min-width: 1024px) {
  .roadmap-web {
    display: block;
  }

  .roadmap-mob {
    display: none;
  }

  .videoContainer {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, auto) !important;
  }

  .mob {
    display: none;
  }

  .website {
    display: block;
    width: 100%;
    height: 540px;
    background-image: url("/static/img/wallet/beijing1.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .webleft {
    margin-right: 228px;
    margin-top: -7px;
    height: 322px;
    /* background-color: brown; */
    width: 221px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .webright {
    width: 319px;
    height: 430px;
    background-image: url("/static/img/wallet/iPhone.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .cpcwallet {
    width: 221px;
    height: 51px;
    font-size: 36px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: rgba(48, 82, 120, 1);
    line-height: 55px;
  }

  .group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info {
    width: 198px;
    /* height: 34px; */
    font-size: 12px;
    font-family: "Poppins-Regular";
    font-weight: 400;
    color: rgba(48, 82, 120, 1);
    line-height: 18px;
  }

  .split {
    width: 40px;
    height: 2px;
    background-color: #305278;
  }

  .qr {
    width: 108px;
    height: 159px;
    background: rgba(255, 255, 255, 1);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* padding: 12px 0 25px 0; */
  }

  .qrimg {
    width: 86px;
    height: 85px;
    margin-top: 12px;
  }

  .qrtext {
    margin-bottom: 25px;
    /* width: 65px;
                    height: 16px; */
    font-size: 11px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: rgba(48, 82, 120, 1);
    line-height: 17px;
  }
}
</style>
