<template>
  <div style="margin-bottom:120px">
    <div class="top">
      <div class="img-container">
        <img src="" alt="" srcset="@/assets/img/dapp/market.png">
      </div>
      <section class=" py-sm-5 my-sm-5 py-5 my-0" id="cpchain">
        <div class="container">
          <div class="row align-items-center justify-content-center">

            <div class="first col-sm-12 col-md-6 py-5 py-sm-0">
              <div style="padding-right: 10px;margin-top: 60px;">
                <h2 class="display-5 poppins-font mb-7 market-title  title-text"> {{ $t("dapp.CPCHAIN_DApps_Market") }} </h2>

                <p class="market-desc" style="margin-top:60px">
                  {{ $t("dapp.description1") }}
                </p>
                <p class="market-desc">
                  {{ $t("dapp.description2") }}
                </p>
              </div>
            </div>
            <div class="second col-sm-12 col-md-6">

            </div>

          </div>
        </div>
      </section>
    </div>

    <section class="steps">
      <div class="step">
        <span class="step1">1. {{ $t("dapp.step1") }} </span>
      </div>

      <div class="step">
        <span class="step2">2. {{ $t("dapp.step2") }}</span>
        <div class="download">
          <a :href="iosLink"><img src="@/assets/img/dapp/apple.png" alt="" srcset=""></a>
          <a :href="googleLink"><img src="@/assets/img/dapp/google.png" alt="" srcset=""></a>
          <a :href="androidLink"><img src="@/assets/img/dapp/android.png" alt="" srcset=""></a>
        </div>
      </div>
      <div class="step">
        <span class="step3">3. {{ $t("dapp.step3") }}</span>
      </div>
    </section>

    <section class="section-two py-sm-4 my-sm-4 py-4 my-0" id="cpchain">
      <div class="container">
        <div class="row align-items-center justify-content-center">

          <div class="first col-sm-12 col-md-12 py-5 py-sm-0">
            <div class="col-12 col-sm-11 ">
              <h2 class="display-5 poppins-font mb-7  title-text"> {{ $t("dapp.ExploreDapps") }} </h2>

              <p class="explore-desc desc-text">
                {{$t("dapp.ExploreDappsDescription")}}
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-for="(type,index) in  dapps" :key="index" class="section-two py-sm-2 my-sm-2 py-2 my-0" id="cpchain">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="first col-sm-12 col-md-12 py-5 py-sm-0">
            <div class="col-12 col-sm-11 poppins-font" style="padding-right: 10px;">
              <h3 class="display-5  type-title mb-7"> {{ type.name}} </h3>
              <p class="type-desc">
                {{type.description}}
              </p>

              <div class="dapps">
                <div v-for="(dapp,i) in type.data" :key="i" class="dapp">
                  <img :src="dapp.logo" alt="" class="logo">
                  <div class="desc">
                    <p>{{dapp.name}}</p>
                    <span>{{dapp.brief}}</span>
                    <a :href="'#/explorer/address/' + dapp.address">{{$t("dapp.link")}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Market",
  data() {
    return {
      dapps: [],
      iosLink: '',
      googleLink: 'https://play.google.com/store/apps/details?id=com.cpcwallet',
      androidLink: ''
    };
  },
  created() {
    this.fetchLink()
    this.fetchData();

  },
  methods: {
    fetchLink() {
      fetch('https://app.services.cpc-servers.com/getAppSettings/').then(res => res.json()).then(settings => {
        let iosSetting = settings.filter(p => p.fields.name == 'iOSUpdateUrl')
        this.iosLink = iosSetting[0].fields.value
      })

      fetch('https://app.services.cpc-servers.com/getAppVersion/').then(res => res.json()).then(version => {
        if (version[0].fields.AndroidAppName) {
          this.androidLink = 'https://app.services.cpc-servers.com/getApp/' + version[0].fields.AndroidAppName
        }
      })
    },
    fetchData() {
      let types = []
      let lang = 'en-us'
      switch (this.$store.state.locale) {
        case 'enUS': lang = 'en-us'
          break
        case 'zhCN': lang = 'zh-cn'
          break
        default: lang = 'en-us'
      }
      request.get("/dapps/info/?locale=" + lang).then((res) => {
        // 按类型整理数据 
        res.results.forEach(dapp => {
          let dappType = types.find(p => p.id === dapp.type.id)
          if (!dappType) {
            dappType = { ...dapp.type, data: [] }
            types.push(dappType)
          }
          dappType.data.push(dapp)
        });
        this.dapps = types
        console.log(this.dapps)
      });
    },
  },
  watch: {
    '$store.state.locale': {
      handler() {
        this.fetchData()
      },
      deep: true //true 深度监听
    }
  }
};
</script>

<style scoped>
.type-title {
  font-size: 24px;
  color: #000000;
}

.type-desc {
  font-size: 14px;
  color: #595959;
  margin-top: 14px;
  margin-left: 2px;
}
.top {
  margin-top: 140px;
  margin-bottom: 80px;
  height: 419px;
  width: 100%;
  background: linear-gradient(rgba(250, 250, 250, 0.5), rgba(0, 140, 206, 0.5));
}

.img-container {
  width: 1185px;
  margin: auto;
  position: relative;
}

.img-container img {
  position: absolute;
  left: 400px;
  height: 462px;
  width: 821px;
}

.title-text {
  color: #000000 !important;
}

.desc-text {
  color: #595959;
}
.market-title {
  font-size: 36px;
}
.market-desc {
  font-size: 14px;
  color: #595959;
  line-height: 20px;
}

.steps {
  width: 1185px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.step {
  background: #fafafa;
  border: 1px solid #efefef;
  margin-right: 24px;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
}

.step1 {
  width: 186px;
}

.step2 {
  width: 184px;
}

.step3 {
  width: 191px;
}

.step span {
  font-size: 24px;
  color: #000000;
}

.download {
  margin-left: 10px;
}

.download img {
  margin-left: 10px;
}
.explore-desc {
  margin-top: 20px;
  font-size: 14px;
}
.dapps {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: 14px;
}

.dapp {
  width: 480px;
  height: 200px;
  background: #efefef;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  padding: 40px 30px 30px 30px;
}

.dapp img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.desc p {
  font-size: 20px;
  color: #595959;
}

.desc span {
  word-break: break-all;
  width: 280px;
  font-size: 14px;
  color: #595959;
  margin-bottom: 10px;
}

.desc a {
  width: 80px;
  height: 35px;
  line-height: 35px;
  padding: auto;
  background: #2876ff;
  border: 1px solid #a3ceff;
  border-radius: 20px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}
</style>