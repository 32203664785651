<template>
  <section class="section-six py-sm-5 py-0">
    <div class="container py-1 py-sm-5">
      <div class="row">
        <div class="upper col-12 col-sm-4 mx-0">
          <h2
            class="text-left poppins-font"
            style="font-weight: bold; padding-top: 150px"
            id="application"
          >
            {{ $t("solutions") }}
          </h2>
          <hr class="right-hr w-100 ml-0" />
        </div>
        <div
          class="middle d-flex flex-row w-100 col-xl-12 col-md-12 mb-sm-5 mb-2"
        >
          <p style="line-height: 1.8">
            CPChain's distributed infrastructure will provide a full process
            solution for data acquisition, storage, sharing and transacting for
            large-scale distributed IoT systems. Our technology has the
            potential to revolutionize multiple industries including intelligent
            transportation, smart medical, public security, and intelligent
            manufacturing, etc.
          </p>
        </div>
        <div class="bottom col-12">
          <div class="box-holder">
            <div class="item item-one">
              <div class="image-holder position-relative" data-tilt>
                <router-link to="/solutions/public_security">
                  <img
                    src="@/assets/img/public.jpg"
                    class="w-100 d-block disable-select"
                    alt="Intelligent Manufacturing - CPChain"
                  />
                  <div class="overlay-image w-100 h-100"></div>
                  <p
                    class="position-absolute w-100 text-right text-white pr-4"
                    style="font-family: 'Poppins', sans-serif !important"
                    v-html="$t('public_security')"
                  >
                  </p>
                </router-link>
              </div>
            </div>
            <div class="item item-two">
              <div class="image-holder position-relative" data-tilt>
                <router-link to="/solutions/intelligent_transportation">
                  <img
                    src="@/assets/img/IntelligentTransportation.png"
                    class="w-100 d-block disable-select"
                    alt="Intelligent Transportation - CPChain"
                  />
                  <div class="overlay-image w-100 h-100"></div>
                  <p
                    class="position-absolute w-100 text-right text-white pr-4"
                    style="font-family: 'Poppins', sans-serif !important"
                    v-html="$t('intelligent_transportation')"
                  >
                  </p>
                </router-link>
              </div>
            </div>
            <div class="item item-three">
              <div class="image-holder position-relative" data-tilt>
                <router-link to="/solutions/smart_medical">
                  <img
                    src="@/assets/img/SmartMedical.png"
                    class="w-100 d-block disable-select"
                    alt="Smart Medical - CPChain"
                  />
                  <div class="overlay-image w-100 h-100"></div>
                  <p
                    class="position-absolute w-100 text-right text-white pr-4"
                    style="font-family: 'Poppins', sans-serif !important"
                    v-html="$t('smart_medical')"
                  >
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section-six -->
</template>

<script>
import VanillaTilt from "vanilla-tilt";

export default {
  name: "Solutions",
  mounted() {
    var elements = document.querySelectorAll(".image-holder");
    VanillaTilt.init(elements);
  },
};
</script>

<style lang="scss" scoped>
.section-six {
  .box-holder {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    grid-template-rows: 400px;
    img {
      object-fit: cover;
      height: 400px;
      object-position: 35% 0;
    }
    .item {
      p {
        // font-family: $font-family-monospace !important;
        font-weight: 600;
        bottom: 0;
        font-size: 18px;
      }
    }
    h2 {
      font-weight: 600;
    }
    .image-holder {
      transform-style: preserve-3d;
      img {
        transform-style: preserve-3d;
      }
      &:hover {
        p {
          transform: translateZ(50px);
        }
      }
    }
    .overlay-image {
      position: absolute;
      background: linear-gradient(
        to right,
        rgba(22, 68, 120, 0),
        rgba(22, 68, 120, 0.47)
      );
      bottom: 0;
    }
  }
}
</style>
