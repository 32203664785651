<template>
  <div>
    <a
      :href="share_url"
      target="_blank"
      rel="noopener noreferrer"
      class="twitter-share-button btn btn-sm btn-light rounded-circle font-weight-bold bg-white text-primary mr-0 mr-sm-2"
      style="margin-top: 80px; margin-bottom: 50px"
    >
      <img
        :src="twitter_logo"
        alt="Share to Twitter"
        style="height: 20px; width: 90px; margin-bottom: 0px"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: "TwitterShare",
  props: {
    text: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      share_url:
        "https://twitter.com/intent/tweet?text=" +
        encodeURIComponent(this.text) +
        "&hashtags=cpchain,blockchain" +
        "&url=" +
        encodeURIComponent(this.url),
      twitter_logo: require("@/assets/img/twitter_2x.png"),
    };
  },
};
</script>
