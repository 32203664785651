<template>
  <v-row>
    <v-spacer />
    <v-col lg="4" mb="12">
        <auto-header :title="'Blocks'" to="/explorer/blocks" />
        <div class="table-content table-blocks">
          <transition-group tag="div" name="slide-fade">
            <div
              class="card table-item"
              v-for="block in blocks"
              :key="block.id"
            >
              <auto-block-card :block="block" />
            </div>
          </transition-group>
        </div>
    </v-col>
    <v-col lg="4" mb="12">
        <auto-header :title="'Transactions'" to="/explorer/txs" />
        <div class="table-content table-transactions">
          <transition-group tag="div" name="slide-fade">
            <div class="card table-item" v-for="tx in txs" :key="tx.hash">
              <auto-tx-card :tx="tx" />
            </div>
          </transition-group>
        </div>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import Vue from "vue";
import { formatTS } from "@/utils/ts";
import AutoHeader from "./AutoHeader.vue";
import AutoBlockCard from "./AutoBlockCard.vue";
import AutoTxCard from './AutoTxCard.vue';

export default {
  name: "AutoBlocks",
  components: {
    AutoHeader,
    AutoBlockCard,
    AutoTxCard,
  },
  props: {
    blocks: {
      default: () => [],
      type: Array,
    },
    txs: {
      default: () => [],
      type: Array,
    },
  },
  watch: {
    blocks() {
      this.updateTimeTicker();
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.startInterval();
  },
  methods: {
    updateTimeTicker() {
      var cts = parseInt((Date.now() + 500) / 1000);
      this.blocks.forEach((block) => {
        Vue.set(block, "timeTicker", formatTS(cts - block.timestamp));
      });
      this.txs.forEach((tx) => {
        Vue.set(tx, "timeTicker", formatTS(cts - tx.timestamp));
      })
    },
    startInterval() {
      setInterval(this.updateTimeTicker, 1000);
    },
  },
};
</script>
