<template>
    <div class="table-header">
      <div class="table-header-main">{{ title }}</div>
      <div class="table-header-sub card-link"><router-link :to="to">{{ $t("see_all") }}</router-link></div>
    </div>
</template>

<script>
export default {
    name: "AutoHeader",
    props: ["title", "to"]
}
</script>

<style scoped>

.card-link {
    color: #1ba3ff;
    font-family: 'Poppins';
    cursor: pointer;
}

</style>
