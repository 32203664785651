<template>
  <div class="card-body">
    <v-row>
      <v-col :cols="8" class="table-item-icon">
        <p class="text-left">
          <img
            :src="require('@/assets/img/explorer_transfer_list.png')"
            alt=""
          />
        </p>
      </v-col>
      <v-col :cols="4" class="table-item-sub">
        <p class="text-right text-truncate">{{ tx.amount }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="8" class="table-item-id">
        <p class="text-left text-truncate2">
          <router-link :to="'/explorer/tx/' + tx.hash" :title="tx.hash">{{
            tx.hash && tx.hash.substr(0, 32) + "…"
          }}</router-link>
        </p>
      </v-col>
      <v-col :cols="4" class="table-item-bonus">
        <p class="text-right"></p>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="8" class="table-item-desc">
        <p class="text-left row mx-0 card-link text-truncate">
          <span class="d-flex" style="color: #a9bcd4; margin-right: 5px;">From&nbsp;</span>
          <router-link
            :to="'/explorer/address/' + tx.sellerID"
            class="text-truncate col px-0"
            :title="tx.sellerID"
            >{{ tx.from_alias || tx.sellerID }}</router-link>
          <span class="d-flex" style="color: #a9bcd4; margin-right: 5px;">&nbsp;To&nbsp;</span>
          <router-link
            :to="'/explorer/address/' + tx.buyerID"
            v-if="tx.buyerID"
            class="text-truncate col px-0"
            :title="tx.buyerID"
            >{{ tx.to_alias || tx.buyerID || "" }}</router-link>
          <router-link
            :href="'/explorer/address/' + tx.contract"
            v-if="!tx.buyerID"
            class="text-truncate col px-0"
            :title="tx.contract"
            >Contract Creation</router-link>
        </p>
      </v-col>
      <v-col :cols="4" class="table-item-time">
        <p class="text-right card-grey">{{ tx.timeTicker }} ago</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AutoTxCard",
  props: {
      tx: {
          required: true,
          type: Object
      }
  }
};
</script>

<style scoped>
.row {
  margin: 0px;
}

.card-body {
  margin-bottom: -10px;
  min-height: 105px;
}

.card-body .row {
  margin-top: -5px;
  margin-bottom: -10px;
}

.card-body .col {
  padding: 0%;
}
</style>

