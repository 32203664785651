<template>
  <v-container class="page-500">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h1 class="text-muted display-1">500</h1>
          <p class="decs text-capatalized h4">
            We're sorry, but something went wrong.
          </p>
          <hr class="middle-hr w-100" />

          <div class="desc text-muted my-5">
            If you are the application owner check the logs for more
            information.

            <div class="action mt-4 mb-5 pb-5">
              <router-link
                to="/"
                class="btn btn-sm btn-light rounded-circle font-weight-bold bg-white mr-0 mr-sm-2 mb-sm-0 mb-3 text-uppercase"
              >
                Home
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Page500",
};
</script>

<style scoped>
.page-500 {
  margin-top: 100px;
  margin-bottom: 50px;
  min-width: 100%;
  font-family: "Poppins", sans-serif !important;
}
</style>
