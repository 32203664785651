<template>
  <div>
    <industry :name="title" :solutions="solutions" :img="cover" />
    <section class="container my-5 my-sm-5">
      <div class="row">
        <div class="video-section col-12 w-100">
          <iframe
            width="100%"
            class="video-class"
            height="658px"
            src="https://www.youtube.com/embed/WAdHZaea4gY"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Industry from "@/components/industry";

export default {
  name: "IntelligentTransportation",
  components: {
    Industry,
  },
  data() {
    return {
      title: "Intelligent Transportation",
      cover: require("@/assets/img/solutions/security.jpg"),
      others: [
        {
          url: "",
          name: "Intelligent Transportation",
        },
        {
          url: "",
          name: "Smart Medical",
        },
      ],
      solutions: [
        {
          name: "Drive's License Training Record",
          background: {
            img: require("@/assets/img/intelligent/slide-1.svg"),
            content:
              "There is an increase of more than 25 million new drivers each year in China. In 2017, there was an estimated 380 million drivers in total. According to the regulations of the Ministry of Transportation and Communications, Driving Schools must ensure that the training hours of the cadets meet specific requirements. However, in recent years, many Driving Schools have been prioritizing profit over quality performance. Driving Schools are forging training records, which affects the quality of training and causes potential public safety concerns.",
          },
          solution: {
            content:
              "Through IoT terminal equipment, the cadets’ image is sampled at random intervals, and compared with the registered image of the cadet to verify the identity of each trainee. At the same time, with PDash, the fundamental data platform for CPChain, each driving data sample will be encrypted, and the data hash will be stored on the blockchain, which will be available for the government regulators to audit. By integrating with blockchain technology, records cannot be tampered with and thus eliminating forged training records.",
          },
          features: [
            {
              img: require("@/assets/img/intelligent/solution-1.png"),
              value: "Original Image Sampling",
            },
            {
              img: require("@/assets/img/intelligent/solution-2.png"),
              value: "PDash Encrypted Storage",
            },
            {
              img: require("@/assets/img/intelligent/solution-3.png"),
              value: "Untampered Data",
            },
          ],
        },
        {
          name: "Vehicle and driver’s operations data records",
          background: {
            img: require("@/assets/img/intelligent/slide-2.png"),
            content:
              "By implementing OBD or other on-board devices to monitoring the driving behavior, personalized insurance can be realized. When car accident happens, with driving and moving data, more precise judgement for accident cause canbe figured out.<br><br> In term of used car trading, through the driving data and operation records, we can determine the value of the vehicle more reasonably. In current Used Based Insurance (UBI), insurance companies usually collaborate with OBD manufacturers of devices and platforms to use these data to calculate UBI premiums, so there is a problem of data trust, as well as concerns about users’ privacy. It also limits the development of UBI services.",
          },
          solution: {
            content:
              "CPChain’s PDash platform can encrypt the UBI or other services necessary for the device to be cached in a certain period of time, and then store it in cloud storage or distributed storage, under the premise of ensuring user privacy. At the same time, the hash value of this part of the data is recorded on the main chain of CPChain, providing data support for UBI investigation and calculation, second-hand vehicle valuation and so on.",
          },
          features: [
            {
              img: require("@/assets/img/intelligent/solution-4.png"),
              value: "User Privacy Protection",
            },
            {
              img: require("@/assets/img/intelligent/solution-2.png"),
              value: "PDash Encrypted Storage",
            },
            {
              img: require("@/assets/img/intelligent/solution-5.png"),
              value: "UBI Investigation",
            },
            {
              img: require("@/assets/img/intelligent/solution-6.png"),
              value: "Used car value assessment",
            },
          ],
        },
        {
          name: "Oil product QR code anti-counterfeiting traceability",
          background: {
            img: require("@/assets/img/intelligent/qr_code.svg"),
            content:
              "In the after-sales segment of the car, the problem of oil counterfeiting is not uncommon. The existing anti-counterfeiting and recording methods are not effective and have poor practicality. The phenomenon of stockpiling in the process of circulation has been repeatedly banned, and counterfeit goods are difficult to source.",
          },
          solution: {
            content:
              "CPChain proposed a two-dimensional code anti-counterfeiting traceability system based on blockchain. After the manufacturer finished production, the traceability information related to the oil production date, raw material batch and origin was set as a two-dimensional code, and the blockchain was uploaded. End consumers can scan the QR code to verify the oil, ensure that the consumer's rights and interests are not damaged, and the oil production, logistics, warehousing, sales and other aspects are closely linked.",
          },
          features: [
            {
              img: require("@/assets/img/intelligent/solution-1.png"),
              value: "QR Code Sanning & Authentication",
            },
            {
              img: require("@/assets/img/intelligent/solution-2.png"),
              value: "Record source information on the chain",
            },
            {
              img: require("@/assets/img/intelligent/solution-3.png"),
              value: "Control the supply chain",
            },
          ],
        },
        {
          name: "Garage automatic parking",
          background: {
            img: require("@/assets/img/intelligent/garage_automatic_parking.svg"),
            content:
              "With the continuous intelligence of motor vehicles and the large amount of relevant data, the rational and full utilization of vehicle data has great practical significance.",
          },
          solution: {
            content:
              "CPChain envisions a vehicle equipped with an embedded wallet device that encrypts the data and uploads it to the PDash platform. When parking, payment of deposits and fee settlements can be done on blockchain-based smart contracts. People do not need to intervene, the whole process is not feeling, real-time, and safe. At the same time, data utilization is realized, and the terminal quickly and automatically trades.",
          },
          features: [
            {
              img: require("@/assets/img/intelligent/solution-1.png"),
              value: "Vehicle-carry Blockchain Coinpurse",
            },
            {
              img: require("@/assets/img/intelligent/solution-2.png"),
              value: "Benefit from Uploaded Data",
            },
            {
              img: require("@/assets/img/intelligent/solution-3.png"),
              value: "Senseless payment",
            },
          ],
        },
      ],
    };
  },
};
</script>
