<template>
  <v-container class="block-info-page">
    <search-bar />
    <div
      class="text-center"
      v-if="loading"
      style="margin-top: 100px; margin-bottom: 100px"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="30"
      ></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <v-col lg="2" />
      <v-col lg="8">
        <v-row>
          <v-col lg="12">
            <div class="page-header">
              <div class="page-header-main">
                <p class="text-center poppins-font">Block Information</p>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 30px">
          <v-col lg="12">
            <v-row>
              <v-col lg="3">
                <img
                  :src="require('@/assets/img/explorer_block_info.png')"
                  alt=""
                  style="margin-top: -5px; margin-right: 10px"
                />
                <span class="info-id">#{{ block.number.value }} <label v-if="is_impeach" class="impeach">Impeach</label></span>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row class="poppins-light" style="margin-top: 30px">
              <v-col lg="12">
                <v-row v-for="f in fields_orderer" :key="f">
                  <v-col lg="2" class="field">{{ block[f].name }}</v-col>
                  <v-col lg="10" class="value">
                    <router-link
                      :to="
                        typeof block[f].to == 'function'
                          ? block[f].to(block)
                          : block[f].to
                      "
                      v-if="block[f].to"
                      >{{ handle(f) }}</router-link
                    >
                    <span v-if="!block[f].to">{{ handle(f) }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="2" />
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from "./SearchBar.vue";
import { convertTime, convertDate } from "@/utils/ts";

export default {
  components: { SearchBar },
  props: ["hash"],
  name: "BlockInfo",
  data() {
    return {
      loading: false,
      is_impeach: false,
      fields_orderer: [
        "number",
        "hash",
        "timestamp",
        "txs_cnt",
        "parentHash",
        "miner",
        "size",
        "gasLimit",
        "gasUsed",
        "reward",
        "extraData",
      ],
      block: {
        number: {
          name: "Height:",
          value: 0,
        },
        hash: {
          name: "Hash:",
          value: "",
        },
        txs_cnt: {
          name: "Transactions:",
          value: 0,
          to(block) {
            return "/explorer/block_txs/" + block.hash.value;
          },
          handler(block, value) {
            return value + " transactions";
          },
        },
        timestamp: {
          name: "Time:",
          value: 0,
          handler(block, value) {
            return (
              convertDate(value) +
              (block.number.value == 0
                ? " (in memory of the day lbft2.0 debuted.)"
                : "")
            );
          },
        },
        miner: {
          name: "Produced By:",
          value: "",
          to(block) {
            return "/explorer/address/" + block.miner.value;
          },
          handler(block) {
            return (
              "" +
              block.miner.value +
              " in " +
              convertTime(block.timestamp.value)
            );
          },
        },
        parentHash: {
          name: "Parent Hash:",
          value: "",
          to(block) {
            return "/explorer/block/" + block.parentHash.value;
          },
        },
        size: {
          name: "Size:",
          value: 0,
        },
        gasLimit: {
          name: "Gas Limit:",
          value: 0,
        },
        gasUsed: {
          name: "Gas Used:",
          value: 0,
        },
        reward: {
          name: "Block Reward:",
          value: 0,
          handler(block) {
            return block.reward.value + " CPC";
          },
        },
        extraData: {
          name: "Extra Data:",
          value: "",
        },
      },
    };
  },
  watch: {
    hash() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http
        .get(`/explorer/api/blocks/${this.hash}/`)
        .then((data) => {
          console.log(data)
          for (let k in data) {
            if (k === 'miner' && data[k] === '0x0000000000000000000000000000000000000000') {
              data[k] = data['impeachProposer']
              this.is_impeach = true
            }
            if (this.block[k]) {
              this.block[k].value = data[k];
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if(err.response.status == 404) {
            this.$router.push(`/search404`)
          }
        });
    },
    convertTime(ts) {
      return convertTime(ts);
    },
    handle(f) {
      return this.block[f].handler
        ? this.block[f].handler(this.block, this.block[f].value)
        : this.block[f].value;
    },
  },
};
</script>

<style scoped>
.block-info-page {
  margin-top: 100px;
  margin-bottom: 50px;
  min-width: 100%;
  font-family: "Poppins", sans-serif !important;
}

.info-id {
  font-size: 1.5rem;
}

.field {
  font-size: 18px;
  font-weight: 600;
}

.value {
  font-size: 18px;
}

.impeach {
  background: #ccc;
  color: #555;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 14px;
  bottom: 3px;
  position: relative;
}

</style>
