<template>
    <section class="section-seven py-sm-5 py-0 ">
        <div class="container py-sm-5 py-0">
            <div class="row flex-row align-items-center py-5">

                <div class="left-side col-xl-6 col-md-6 col-sm-12">
                    <div class="left-side-holder col-xl-10 col-md-10 col-sm-12 px-0">
                        <h1 class="display-1 font-italic" style="color: #F3F5F7;">3</h1>
                        <h2 class="poppins-font" v-html="$t('homepage.scenarios_title')"></h2>
                        <hr class="right-hr w-100 ml-0">
                        <p style="color: #305278;font-family: 'Poppins';line-height: 30px;">
                            The value of CPChain comes from the improvement of the physical industry’s efficiency and the innovation of business models, which results from data aggregation and mobility.
                        </p>
                    </div>
                </div>
                <div class="right-side col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex flex-column align-items-end justify-content-between py-5 py-sm-0">
                    <div class="right-side-holder col-xl-9 col-md-9 col-lg-9 col-sm-12 px-0">
                        <div class="item d-flex flex-row" style="margin-bottom: 70px">
                            <div class="left-side">
                                <div class="image-holder mr-4">
                                    <img src="@/assets/img/multi-party.png"
                                            class="d-block disable-select w-100"
                                            alt="Multi-parties data transaction - CPChain">
                                </div>
                            </div>
                            <div class="right-side d-flex flex-column align-items-start justify-content-center">
                                <h6 class="poppins-font" style="line-height:30px;font-size: 20px;" v-html="$t('homepage.scenarios_1')"></h6>
                                <p class="mb-0"
                                    style="color: #305278;font-family: 'Poppins';line-height: 1.3;">
                                    Multi-party trust created by CPChain</p>
                            </div>
                        </div>
                        <div class="item d-flex flex-row mb-5">
                            <div class="left-side">
                                <div class="image-holder mr-4">
                                    <img src="@/assets/img/artificial.png"
                                            class="d-block disable-select w-100"
                                            alt="Artificial intelligence decisions - CPChain">
                                </div>
                            </div>
                            <div class="right-side d-flex flex-column align-items-start justify-content-center">
                                <h6 class="poppins-font" style="line-height:30px;font-size: 20px;" v-html="$t('homepage.scenarios_2')"></h6>
                                <p class="mb-0"
                                    style="color: #305278;font-family: 'Poppins';line-height: 1.3;">
                                    Interoperability of heterogeneous data, forming big data aggregation</p>
                            </div>
                        </div>
                        <div class="item d-flex flex-row">
                            <div class="left-side">
                                <div class="image-holder mr-4">
                                    <img src="@/assets/img/interaction.png"
                                            class="d-block disable-select w-100"
                                            alt="Interaction and application of data assets - CPChain">
                                </div>
                            </div>
                            <div class="right-side d-flex flex-column align-items-start justify-content-center">
                                <h6 class="poppins-font" style="line-height:30px;font-size: 20px;" v-html="$t('homepage.scenarios_3')"></h6>
                                <p class="mb-0"
                                    style="color: #305278;font-family: 'Poppins';line-height: 1.3;">
                                    Cross-chain relay and collaboration</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "MajorScenarios"
}
</script>
