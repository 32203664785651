<template>
  <div>
    <industry :name="title" :solutions="solutions" :img="cover"/>
  </div>
</template>

<script>

import Industry from '@/components/industry'

export default {
  name: "PublicSecurity",
  components: {
    Industry,
  },
  data() {
    return {
      title: "Public Security",
      cover: require("@/assets/img/solutions/security.jpg"),
      others: [
        {
          url: '',
          name: 'Intelligent Transportation'
        },
        {
          url: '',
          name: 'Smart Medical'
        },
      ],
      solutions: [
        {
          name: 'Elevator Operation Data Record',
          background: {
            img: require('@/assets/img/solutions/slide-1.png'),
            content: 'Elevator operation data, such as the real-time height of the elevator, up & down signal, alarm status, and emergency power status, are all useful for running status monitoring, fault prediction, fault handling and accident rescue.'
          },
          solution: {
            content: 'By installing sensors in elevators and elevator hoistway, the key data needed for maintenance can be collected and uploaded to the CPChain platform.<br /><br /> By utilizing the characteristics of Blockchain, in which data can be traced back but cannot be tampered with, the data records of elevator movement and the snapshots of key maintenance operations are stored on the chain, to provide reliable records for ACI, insurance investigation and quality assessment of maintenance.'
          },
          features: [
            {
              img: require('@/assets/img/solutions/solution-1.png'),
              value: 'Medical Industry Side Chain'
            },
            {
              img: require('@/assets/img/solutions/solution-2.png'),
              value: 'Medicine Quality Trace'
            },
            {
              img: require('@/assets/img/solutions/solution-3.png'),
              value: 'Medicine Quality'
            },
          ]
        }
      ]
    };
  },
};
</script>
