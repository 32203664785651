<template>
  <v-container class="faucet-page">
    <v-row>
      <v-col cols="2" lg="2"/>
      <v-col cols="8" lg="8">
        <div class="page-header">
          <div class="page-header-main">
            <p class="text-center poppins-font">Faucet</p>
          </div>
        </div>
      </v-col>
      <v-col cols="2" lg="2"/>
    </v-row>
    <v-row>
      <v-col cols="0" lg="3"/>
      <v-col cols="12" lg="6">
        <v-alert
            v-if="err"
            style="color: red; margin-top: 8px;"
            border="top"
            colored-border
            type="error"
            elevation="2"
        >
          {{ err }}
        </v-alert>
        <v-text-field label="Please enter your testnet address" v-model="addr"></v-text-field>
        <v-btn color="primary" :disabled="!addr" @click="submit()">Send me test CPC</v-btn>
        <v-row style="margin-top: 32px;">
          <p>This faucet drips 100 CPC every 10 seconds. You can register your account in our queue. Max queue size is currently 100.</p>
        </v-row>
        <v-row>
          <p>The ChainID of the testnet is: <b>{{ chainID }}</b></p><br/>
        </v-row>
        <v-row>
          <p>The RPC endpoint of the testnet is: <b>{{ endpoint }}</b></p>
        </v-row>
        <div class="queue" v-if="queue.length > 0">
          Queue
        </div>
        <v-row class="addr" v-for="item in queue" v-bind:key="item.address">
          {{ item.address }}
        </v-row>
      </v-col>
      <v-col cols="0" lg="3" />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Faucet",
  data() {
    return {
      err: null,
      timer: null,
      addr: '',
      limit: 100,
      queue: []
    };
  },
  computed: {
    endpoint() {
      return "https://civilian.testnet.cpchain.io"
    },
    chainID() {
      return "41"
    }
  },
  mounted() {
    this.fetchQueue()
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.fetchQueue()
      }, 3000)
    }
  },
  methods: {
    clearErr() {
      this.err = null
    },
    fetchQueue() {
      this.$http.get(`/api/faucet/faucet/?limit=${this.limit}&sent=false`).then(res => {
        this.queue = res.results
      }).catch(err => {
        console.error(err)
        this.err = err
      })
    },
    submit() {
      this.clearErr()
      this.$http.post(`/api/faucet/faucet/`, {
        address: this.addr
      }).then(() => {
        this.clearErr()
        this.fetchQueue()
        this.addr = ""
      }).catch(err => {
        let msg = err.response.data.message || err.message
        console.error(err)
        this.err = msg
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
};
</script>

<style scoped>
.faucet-page {
  margin-top: 100px;
  margin-bottom: 50px;
  min-width: 100%;
  font-family: "Poppins", sans-serif !important;
}

.queue {
  font-size: 2em;
  font-weight: bold;
  margin-top: 32px;
}

.addr {
  font-size: 1.2em;
  margin-top: 16px;
  color:darkgrey;
}

</style>
