<template>
  <div>
    <industry :name="title" :solutions="solutions" :img="cover"/>
  </div>
</template>

<script>
import Industry from "@/components/industry";

export default {
  name: "SmartMedical",
  components: {
    Industry,
  },
  data() {
    return {
      title: "Smart Medical",
      cover: require("@/assets/img/smart/smart.jpg"),
      others: [
        {
          url: "",
          name: "Intelligent Transportation",
        },
        {
          url: "",
          name: "Smart Medical",
        },
      ],
      solutions: [
        {
          name: "Medicine Anti-counterfeiting and Source-tracing",
          background: {
            img: require("@/assets/img/smart/smart.jpg"),
            content:
              "Medicine Safety is always a public affair. Counterfeit drugs will do more harm to our health rather than healing people. If pharmaceutical enterprises do not handle such issues well, there will be very serious consequences. We can see a lot of negative cases caused by manufacturers tampering with production records.",
          },
          solution: {
            content:
              "In order to eliminate consumers doubts fundamentally, it is necessary to perfect the traceability system of drug quality and realize the traceability of drug information from the supply of raw materials to the whole supply chain of drug sales. Pharmaceutical companies also urgently need to build up the supply chain upstream and downstream effective communication channels. When problems arise, we can quickly find out the reasons and reduce the risk of responsibility. Based on the mainnet of CPChain and the following medicine industry side chain, the drugs are recorded from raw materials to production, sales to avoid the problem of data tampering in centralized system and to improve drug safety.",
          },
          features: [
            {
              img: require("@/assets/img/smart/solution-1.png"),
              value: "Medical Industry Side Chain",
            },
            {
              img: require("@/assets/img/smart/solution-2.png"),
              value: "Medicine Quality Trace",
            },
            {
              img: require("@/assets/img/smart/solution-3.png"),
              value: "Medicine Quality",
            },
          ],
        },
        {
          name: "AI Support for Public Health Signal Data",
          background: {
            img: require("@/assets/img/smart/slide-2.png"),
            content:
              "Medicine Safety is always a public affair. Counterfeit drugs will do more harm to our health rather than healing people. If pharmaceutical enterprises do not handle such issues well, there will be very serious consequences. We can see a lot of negative cases caused by manufacturers tampering with production records.",
          },
          solution: {
            content:
              "With PDash, the fundamental platform of CPChain, patients who own health monitoring devices, can upload their health data at their will to centralized or distributed cloud space, meanwhile, creating description of their data set, recording hash for the data on CPChain mainnet for verification. CPChain has special design for privacy protection in the process of data sharing. Besides, due to the fact that there is no need for real-name authentication in AI training, CPChain can provide effective support for application such as AI, with refined security of privacy.",
          },
          features: [
            {
              img: require("@/assets/img/smart/solution-4.png"),
              value: "Medical Industry Side Chain",
            },
            {
              img: require("@/assets/img/smart/solution-5.png"),
              value: "Medicine Quality Trace",
            },
            {
              img: require("@/assets/img/smart/solution-6.png"),
              value: "Medicine Quality",
            },
          ],
        },
      ],
    };
  },
};
</script>
