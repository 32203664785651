export default {
    Partners:'合作伙伴',
    Ecosystem:'生态',
    CPChainWallet:'钱包',
    Dapps:'Dapps', 
    solutions: "解决方案",
    roadmap: "路线图",
    community: "社区",
    documentation: "文档",
    explorer: "区块链浏览器",
    map: "节点地图",
    download: "下载",
    contact: "联系我们",
    white_paper_file: "CPChain_Whitepaper_CN.pdf",
    homepage: {
        Submitted:'提交成功',
        Your_opinion_is_important:'您的意见很重要',
        If_you_have_suggestion:'如果您对企业合作有任何建议或想法，请随时与我们联系',
        YourEmail:'您的邮箱',
        YourPurpose :'您的意见',
        Send:'提交',
        all:'全部',
        title: "CYBER PHYSICAL CHAIN",
        subTitle: "新一代物联网分布式基础设施",
        white_paper_2: "白皮书 2.0",
        rnode: "荣誉节点",
        introduction: "简介",
        videos: "视频",
        ecosystem: "生态",
        industry: "企业合作",
        projects: "项目合作",
        academia: "学术合作",
        capital: "资方合作",
        association: "协会联盟",
        industryNode: "行业节点",
        exchanges: "交易所",
        download: "下载钱包",
        scenarios_title: "三大主要应用场景",
        scenarios_1: "多方数据处理",
        scenarios_2: "基于物联网大数据的<br/>人工智能决策",
        scenarios_3: "跨链数据资产的<br/>交互和应用",
    },
    public_security: "公共安全",
    intelligent_transportation: "智能交通",
    smart_medical: "智慧医疗",
    see_all: "查看全部",
    dapp:{ 
        CPCHAIN_DApps_Market :'CPCHAIN DApps Market',
        description1:'CPCHAIN POWERED DECENTRALIZED APPLICATIONS',
        description2:'该平台旨在让每个人都能在CPchain先进的技术支持下轻松发现、使用和开发dapp。',
        step1:'获取CPC',
        step2:'安装钱包',
        step3:'浏览DApp',
        ExploreDapps:'浏览DApps',
        ExploreDappsDescription:' 从实验性的尝试到功能性的实现，欢迎你的见证。',
        link:'获取', 
}
}
