<template>
  <v-container class="txs-page">
    <search-bar />
    <v-row>
      <v-col lg="2" />
      <v-col lg="8">
        <v-row>
          <div class="page-header" style="width: 100%; font-size: 36px">
            <p class="text-center poppins-font">Transactions</p>
          </div>
        </v-row>
        <v-row>
          <txs :blockHash="blockHash" />
        </v-row>
      </v-col>
      <v-col lg="2" />
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from "./SearchBar.vue";
import Txs from '../../components/explorer/Txs.vue';

export default {
  components: { SearchBar, Txs },
  name: "TxsList",
  props: ["blockHash"]
};
</script>

<style scoped>
.txs-page {
  margin-top: 100px;
  margin-bottom: 10px;
  min-width: 100%;
}
</style>
