export default { 
    Partners:'Partners',
    Ecosystem:'Ecosystem',
    CPChainWallet:'CPC-Wallet',
    Dapps:'Dapps', 
    solutions: "Solutions",
    roadmap: "Roadmap",
    community: "Community",
    documentation: "Documentation",
    explorer: "Explorer",
    map: "Map",
    download: "Download",
    contact: "Contact",
    white_paper_file: "CPChain_Whitepaper_English.pdf",
    homepage: {
        Submitted:'Submitted',
        Your_opinion_is_important:'Your opinion is important.',
        If_you_have_suggestion:'If you have any suggestion or idea of enterprise cooperation, please feel free to contact us.',
        YourEmail:'Your Email',
        YourPurpose :'Your Purpose for CPChain',
        SEND:'SEND',
        all:'All',
        title: "CYBER PHYSICAL CHAIN",
        subTitle: "A new distributed infrastructure for next generation IoT",
        white_paper_2: "WHITE PAPER 2.0",
        rnode: "RNode",
        introduction: "Introduction",
        videos: "Videos",
        ecosystem: "Ecosystem",
        industry: "Industry",
        projects: "Projects",
        academia: "Academia",
        capital: "Capital",
        association: "Association",
        industryNode: "Industry Node",
        exchanges: "Exchanges",
        download: "DOWNLOAD",
        scenarios_title: "Three major <br/> adoption scenarios",
        scenarios_1: "Multi-party data transaction",
        scenarios_2: "Artificial intelligence decisions based on IoT big data",
        scenarios_3: "Interaction and application of data assets in cross-chain, which lead to new business models"
    },
    public_security: "Public Security",
    intelligent_transportation: "Intelligent Transportation",
    smart_medical: "Smart Medical",
    see_all: "See all",
    dapp:{ 
            CPCHAIN_DApps_Market :'CPCHAIN DApps Market',
            description1:'CPCHAIN POWERED DECENTRALIZED APPLICATIONS',
            description2:'This platform is designed to for everyone to easily use, enjoy, and create dapps with the support of our advanced features and functionality.',
            step1:'Get some CPC',
            step2:'Set up wallet',
            step3:'Explore DApps',
            ExploreDapps:'Explore DApps',
            ExploreDappsDescription:' From experimental attempts to functional realization, you are welcome to witness.',
            link:'Get', 
    }
}
