import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    industries: [
      {
        name: "Public Security",
        url: "/solutions/public_security",
      },
      {
        name: "Smart Medical",
        url: "/solutions/smart_medical",
      },
      {
        name: "Intelligent Transportation",
        url: "/solutions/intelligent_transportation",
      },
    ],
    locale: 'enUS'
  },
  actions: {
    changeLocale(context, payload) {
      context.commit('changeLocale', payload)
    }
  },
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
    changeLocale(state, payload) {
      payload.i18n.locale = payload.locale;
      state.locale = payload.locale;
    }
  },
  plugins: [vuexLocal.plugin]
});

export default store;
