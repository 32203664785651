<template>
  <ul
    class="display-flex list-unstyled d-flex align-items-center justify-content-between my-auto w-75"
  >
    <li>
      <a
        href="https://github.com/CPChain"
        style="color: #305278"
        target="_blank"
        title="Github"
        rel="noopener noreferrer"
      >
        <i class="fab fa-github glyphicon"></i>
      </a>
    </li>
    <li>
      <a
        href="https://twitter.com/cpchain_io"
        style="color: #305278"
        target="_blank"
        title="Twitter"
        rel="noopener noreferrer"
      >
        <i class="fab fa-twitter glyphicon"></i>
      </a>
    </li>
    <li>
      <a
        href="https://medium.com/cpchain-tech"
        style="color: #305278"
        target="_blank"
        title="Medium"
        rel="noopener noreferrer"
      >
        <i class="fab fa-medium-m glyphicon"></i>
      </a>
    </li>
    <li>
      <a
        href="https://www.reddit.com/r/CPChain_Tech/"
        style="color: #305278"
        target="_blank"
        title="Reddit"
        rel="noopener noreferrer"
      >
        <i class="fab fa-reddit-alien glyphicon"></i>
      </a>
    </li>
    <li>
      <a
        href="https://t.me/cpchain_official"
        style="color: #305278"
        target="_blank"
        title="Telegram"
        rel="noopener noreferrer"
      >
        <i class="fab fa-telegram-plane glyphicon"></i>
      </a>
    </li>
    <!-- <li>
      <a
        href="https://discord.gg/9agsfFJ8gg"
        style="color: #305278"
        target="_blank"
        title="Discord"
        rel="noopener noreferrer"
      >
        <i class="fab fa-discord glyphicon"></i>
      </a>
    </li> -->
  </ul>
</template>

<script>
export default {
  name: 'SocialMedia'
}
</script>

