<template>
  <section class="section-twelve py-sm-5 py-0 mb-5">
    <div class="container" style="max-width: 100% !important">
      <div class="row flex-column align-items-center justify-content-center" style="margin-left: 1rem !important">
        <div class="upper col-xl-5 col-lg-5 col-md-5 col-sm-12">
          <h2 class="text-center poppins-font">
            {{ $t("Partners") }}
          </h2>
          <hr class="middle-hr mb-0" />
        </div>
        <div class="row pt-5" style="margin: 0 !important; width: 100%">
          <!-- 侧边菜单栏 -->
          <v-container style="min-width: 100%">
            <v-row v-if="is_mobile">
              <v-col cols="6">
                <v-select :items="ecosystem_arr" v-model="current_tab" disable-lookup dense></v-select>
              </v-col>
            </v-row>
            <v-row style="min-width: 100%">
              <v-col cols="6" lg="3" md="3" xl="3" v-if="!is_mobile">
                <div class="tabcontainer">
                  <div v-for="e in ecosystem" v-bind:key="e.order" @click="current_tab = e.order" :class="{
                      tabitem: true,
                      tabitemactive: current_tab == e.order,
                    }">
                    {{ $t(e["name"]) }}<i></i>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" lg="6" md="6" xl="6">
                <v-row>
                  <v-col class="image-holder carditem" cols="12" lg="4" md="4" xl="4" v-for="item in get_by_order(current_tab).data" v-bind:key="item.name">
                    <a :href="item.link" target="_blank" rel="noopener noreferrer">
                      <img class="disable-select img-fluid d-block" style="margin: auto 0" :src="item.image" />
                    </a>
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer />
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { is_mobile } from "@/utils";

export default {
  name: "Ecosystem",
  data() {
    return {
      is_mobile: is_mobile,
      current_tab: 1,
      MEDIA_URL: process.env.VUE_APP_BASE_API + "/media/",
      ecosystem_arr: [],
      ecosystem: {
        all: {
          name: "homepage.all",
          order: 0,
          data: [],
        },
        industry: {
          name: "homepage.industry",
          order: 1,
          data: [],
        },
        projects: {
          name: "homepage.projects",
          order: 2,
          data: [],
        },
        academia: {
          name: "homepage.academia",
          order: 3,
          data: [],
        },
        capital: {
          name: "homepage.capital",
          order: 4,
          data: [],
        },
        association: {
          name: "homepage.association",
          order: 5,
          data: [],
        },
        industryNode: {
          name: "homepage.industryNode",
          order: 6,
          data: [],
        },
        exchanges: {
          name: "homepage.exchanges",
          order: 7,
          data: [],
        },
      },
    };
  },
  created() {
    this.fecthData();
  },
  mounted() {
    for (let key in this.ecosystem) {
      let obj = this.ecosystem[key];
      this.ecosystem_arr.push({
        text: this.$t(obj.name),
        value: obj.order
      });
    }
  },
  methods: {
    fecthData() {
      this.$http.get("/get_ecosystem").then((res) => {
        this.ecosystem.all.data = [...res.industry, ...res.projects, ...res.academia, ...res.captial, ...res.association, ...res.industryNode, ...res.exchanges];
        this.ecosystem.industry.data = res.industry;
        this.ecosystem.projects.data = res.projects;
        this.ecosystem.academia.data = res.academia;
        this.ecosystem.capital.data = res.captial;
        this.ecosystem.association.data = res.association;
        this.ecosystem.industryNode.data = res.industryNode;
        this.ecosystem.exchanges.data = res.exchanges;
      });
    },
    get_by_order(order) {
      for (let key in this.ecosystem) {
        if (this.ecosystem[key].order === order) {
          return this.ecosystem[key];
        }
      }
      return {};
    },
  },
};
</script>

<style scoped>
.tabitemactive {
  color: rgba(245, 194, 68, 1);
  z-index: 100;
}

@media (min-width: 375px) {
  .tabitemactive i {
    background-color: rgba(245, 194, 68, 1) !important;
    height: 2.85rem;
    width: 3px !important;
    margin-left: -1px !important;
  }

  .tabcontainer {
    height: 100%;
    background-color: white;
    margin-left: 1rem;
    border-left: 1px solid rgba(193, 206, 220, 1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .tabitem i {
    height: 2.85rem;
    width: 2px;
    margin-right: 1.785rem;
  }

  .tabitem {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    font-size: 1.071rem;
    /* font-family: 'SFUIText'; */
    font-weight: 500;
    color: rgba(56, 82, 117, 1);
    margin-bottom: 0.476rem;
    height: 2.85rem;
    line-height: 2.85rem;
  }

  .carditem {
    width: 12.85rem;
    height: 4.5rem;
    box-shadow: 0px 1px 2px 0px rgba(7, 9, 12, 0.05);
    -moz-box-shadow: 0px 1px 2px 0px rgba(7, 9, 12, 0.05);
    -webkit-box-shadow: 0px 1px 2px 0px rgba(7, 9, 12, 0.05);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.carditem:hover {
    width: 12.85rem;
    height: 4.5rem;
    box-shadow: 0px 1px 2px 0px rgba(245, 194, 68, 1);
    -moz-box-shadow: 0px 1px 2px 0px rgba(245, 194, 68, 1);
    -webkit-box-shadow: 0px 1px 2px 0px rgba(245, 194, 68, 1);
  }

  .carditem img {
    max-height: 4rem;
    max-width: 12rem;
  }
}

@media (min-width: 1024px) {
  .tabitemactive i {
    background-color: rgba(245, 194, 68, 1) !important;
    height: 2.85rem;
    width: 3px !important;
    margin-right: -1px !important;
    margin-left: 1.785rem !important;
  }

  .tabitem i {
    height: 2.85rem;
    width: 2px;
    margin-left: 1.785rem;
    margin-right: 0;
  }

  .tabcontainer {
    height: 100%;
    background-color: white;
    border-right: 1px solid rgba(193, 206, 220, 1);
    border-left: 0px solid rgba(193, 206, 220, 1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .tabitem {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 1.071rem;
    /* font-family: 'SFUIText'; */
    font-weight: 500;
    color: rgba(56, 82, 117, 1);
    margin-bottom: 0.476rem;
    height: 2.85rem;
    line-height: 2.85rem;
  }

  .carditem {
    width: 17.85rem;
    height: 6.54rem;
    box-shadow: 0px 1px 5px 0px rgba(7, 9, 12, 0.05);
    -moz-box-shadow: 0px 1px 5px 0px rgba(7, 9, 12, 0.05);
    -webkit-box-shadow: 0px 1px 5px 0px rgba(7, 9, 12, 0.05);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.carditem:hover {
    width: 17.85rem;
    height: 6.54rem;
    box-shadow: 0px 1px 5px 0px rgba(245, 194, 68, 1);
    -moz-box-shadow: 0px 1px 5px 0px rgba(245, 194, 68, 1);
    -webkit-box-shadow: 0px 1px 5px 0px rgba(245, 194, 68, 1);
  }

  .carditem img {
    max-height: 6rem;
    max-width: 17rem;
  }
}
</style>
