<template>
  <v-container>
    <div class="text-center" v-if="loading" style="margin-top: 100px; margin-bottom: 100px">
      <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <div class="info-table" style="width: 100%; margin-left: 0px; margin-right: 0px">
        <div class="info-table-list">
          <div class="info-table-list-content">
            <div class="table">
              <div class="row list-header">
                <div class="col text-truncate  col-xl-2 col-lg-2 col-md-2 col-sm-12">Block Number</div>
                <div class="col text-truncate  col-xl-2 col-lg-2 col-md-2 col-sm-12">Group Name</div>
                <div class="col text-truncate  col-xl-2 col-lg-2 col-md-2 col-sm-12">Type</div>
                <div class="col text-truncate  col-xl-2 col-lg-2 col-md-2 col-sm-12">Owner</div>
                <div class="col text-truncate  col-xl-2 col-lg-2 col-md-2 col-sm-12">Description</div>
                <div class="col text-truncate  col-xl-2 col-lg-2 col-md-2 col-sm-12">Created time</div>
              </div>
              <div v-if="encryptedgroupchats.length == 0" style="
                      color: #aaa;
                      font-size: 0.8rem;
                      width: 100%;
                      text-align: center;
                    ">
                No encryptedgroupchat
              </div>
              <div class="row row-content" v-for="encryptedgroupchat in encryptedgroupchats" v-bind:key="encryptedgroupchat.id">
                <div class="col text-truncate col-xl-2 col-lg-2 col-md-2 col-sm-12"> 
                    <span>Block Number:</span><router-link :to="'/explorer/block/' + encryptedgroupchat.block_number" v-cloak>{{ encryptedgroupchat.block_number }}</router-link>
                </div>
                <div class="col text-truncate col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <span>Group Name:</span><router-link :to="'/explorer/groupchats-info/' + encryptedgroupchat.id" v-cloak>{{ encryptedgroupchat.name }}</router-link>
                </div>
                <div class="col text-truncate type-img col-xl-1 col-lg-1 col-md-1 col-sm-12"> 
                  <span>Type:</span>
                  <p :class="{
                        'public': encryptedgroupchat.is_private == false,
                        'private': encryptedgroupchat.is_private == true,
                      }">{{ encryptedgroupchat.is_private | TypeofGroup }}</p>
                </div>
                <div class="col text-truncate col-xl-3 col-lg-3 col-md-3 col-sm-12">
                  <span>Owner:</span><router-link :to="'/explorer/address/' + encryptedgroupchat.owner" v-cloak>{{ encryptedgroupchat.owner | addrHandler }}</router-link>
                </div>
                <div class="col text-truncate col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <span>Description:</span>{{ JSON.parse(encryptedgroupchat.extend).description }}
                </div>
                <div class="col text-truncate col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <span>Created time:</span>{{ convertTime(encryptedgroupchat.created_at) }} ago
                </div>
              </div>
            </div>
          </div>
        </div>
        <pagination style="margin-top: 20px" :page="page" :count="count" :limit="limit" :change="pageChange" v-if="  count > 0" />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { formatTS } from "@/utils/ts";
import Pagination from "../Pagination.vue";


export default {
  components: { Pagination },
  name: "EncryptedGroupChatList",
  props: ['address',],
  filters: {
    addrHandler(address) {
      let len = 12
      return address.substr(0, len) + "…" + address.substr(address.length - len, len)
    },
    TypeofGroup(is_private) {
      if (is_private == false) {
        return 'PUBLIC'
      }
      else {
        return 'PRIVATE'
      }
    }
  },
  data() {
    return {
      loading: false,
      encryptedgroupchats: [],
      page: 1,
      limit: 30,
      count: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    page() {
      this.fetchData();
    },
    address() {
      this.reload()
    },
  },
  methods: {
    reload() {
      this.page = 1;
      this.count = 0;
      this.fetchData();
    },
    pageChange(page) {
      this.page = page;
    },

    fetchData() {
      this.loading = true;
      let params = {
        offset: (this.page - 1) * this.limit,
        limit: this.limit,
        ordering: '-block_number',  // 按区块创建时间倒序排序，即第一条为最新创建的群，如果不需要倒序，则删除此行
      };

      this.$http
        .get(`/groupchats/group/`, { params: params })
        .then((data) => {
          this.encryptedgroupchats = data.results;
          this.count = data.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    convertTime: function (time) {
      let tss = new Date(time).getTime() / 1000
      var cts = parseInt((Date.now() + 500) / 1000);
      return formatTS(cts - tss);
    },
  },
};
</script>

<style scoped>
.container {
  padding-left: 0 !important;
  /* width: 110%; */
}
.row {
  padding-left: 15px !important;
}
.private {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #96DBFF;
  color: #138EED;
  margin-top: 15px;
}
.special-type {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #ffeecf;
  color: #e77e1b;
  margin-top: 15px;
}
.public {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #d9f7be;
  color: #5b8c00;
  margin-top: 15px;
}
.staking-type {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.type-img { 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.v-icon {
  font-size: 14px;
  line-height: 18px;
}
.v-icon:hover {
  color:#138EED;
}

</style>
