<template>
  <v-container class="proposers-page">
    <search-bar />
    <div
      class="text-center"
      v-if="loading"
      style="margin-top: 100px; margin-bottom: 100px"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="30"
      ></v-progress-circular>
    </div>
    <v-row style="margin-top: 50px" v-if="!loading">
      <v-col lg="2" />
      <v-col lg="8" class="page-header">
        <v-row>
          <v-col lg="2">
            <v-row class="number-font">{{ term }}</v-row>
            <v-row class="index-font">Current Term</v-row>
          </v-col>
          <v-col lg="1" />
          <v-col lg="2">
            <v-row class="number-font">{{ view }}</v-row>
            <v-row class="index-font">Current View</v-row>
          </v-col>
          <v-col lg="1" />
          <v-col lg="2">
            <v-row class="number-font">{{ proposers.length }}</v-row>
            <v-row class="index-font">Proposers</v-row>
          </v-col>
          <v-col lg="1" />
          <v-col lg="2">
            <v-row class="number-font">{{ block_number }}</v-row>
            <v-row class="index-font">Block Number</v-row>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row style="margin-top: 80px">
          <h3 class="text-left poppins-font">Proposers</h3>
        </v-row>
      </v-col>
      <v-col lg="2" />
    </v-row>
    <v-row>
      <v-col lg="2" />
      <v-col lg="8">
        <div class="info-table">
          <div class="info-table-list">
            <div class="info-table-list-content">
              <div class="table" style="padding-top: 47px">
                <div
                  class="row row-content"
                  v-for="(p, i) in proposers"
                  v-bind:key="p"
                >
                  <v-col lg="2" class="text-truncate">
                    <img :src="require('@/assets/img/currentProposer.png')" v-if="index == (i + 1)" style="margin-left: 20px">
                  </v-col>
                  <v-col lg="6" class="text-truncate">
                    <router-link
                      :to="'/explorer/address/'+p"
                      style="
                        font-family: 'Helvetica Neue,Helvetica,Arial,sans-serif';
                        color: #1ba3ff;
                        font-size: 15px;
                      "
                    >
                      {{ p }}
                    </router-link>
                  </v-col>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col lg="2" />
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from "./SearchBar.vue";
export default {
  components: { SearchBar },
  name: "Proposers",
  data() {
    return {
      loading: false,
      term: 0,
      view: 0,
      proposers: [],
      block_number: 0,
      index: 0,
      init: true
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      if(this.init){
        this.loading = true;
      }
      this.$http
        .get("/explorer/api/proposers/")
        .then((data) => {
          this.term = data.term
          this.proposers = data.proposers
          this.index = data.index
          this.view = data.view
          this.block_number = data.blockNumber
          if(this.init) {
            this.startInterval()
            this.loading = false
            this.init = false
          }
        })
        .catch(() => {
          this.loading = false
          this.init = false
        });
    },

    startInterval() {
      setInterval(this.fetchData, 5000);
    },
  },
};
</script>

<style scoped>
.proposers-page {
  margin-top: 100px;
  margin-bottom: 10px;
  min-width: 100%;
}

.number-font {
  color: #305278;
  font-size: 32px;
  font-family: poppins-italic;
}

.index-font {
  color: #305278;
  font-size: 20px;
  font-family: Poppins;
  letter-spacing: 3px;
}
</style>
