<template>
  <v-container class="page-404">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h1 class="text-muted display-1">404</h1>
          <p class="decs text-capatalized h4">PAGE NOT FOUND</p>
          <hr class="middle-hr w-100" />

          <div class="desc text-muted my-5">
            The page requested couldn't be found. This could be a spelling error
            in the URL or a removed page.

            <div class="action mt-4 mb-5 pb-5">
              <router-link
                to="/"
                class="btn btn-sm btn-light rounded-circle font-weight-bold bg-white mr-0 mr-sm-2 mb-sm-0 mb-3 text-uppercase"
              >
                Home
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
    name: "Page404"
}
</script>

<style scoped>
.page-404 {
  margin-top: 100px;
  margin-bottom: 50px;
  min-width: 100%;
  font-family: "Poppins", sans-serif !important;
}
</style>
