<template>
  <v-container>
    <div class="container" style="margin-top: 85px">
      <div class="table-header table-header-left">
        <div class="table-header-main">Blockchain Indicator</div>
      </div>
      <div class="container">
        <div id="chart1" class="explorer-chart"></div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { ChartOption, WaldenTheme } from "@/components/chart";

var echarts = require("echarts");

var echartOption = ChartOption;

WaldenTheme(echarts);

function chartResize(mainChart) {
  return function () {
    mainChart.resize();
  };
}

export default {
  name: "Chart",
  props: {
    chart_data: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      mainChart: null,
    };
  },
  watch: {
    chart_data() {
      this.updateChartData(this.chart_data);
    },
  },
  mounted() {
    this.mainChart = echarts.init(
      document.getElementById("chart1"),
      "echarts-theme-walden"
    );
    window.onresize = chartResize(this.mainChart);
    this.updateChartData(this.chart_data)
  },
  methods: {
    updateChartData(data) {
      var fetchedData = data;

      // 设置Echart的Y轴,取data中最大值最小值倍数，让曲线在中间位置
      const yAxisRate = [1.01, 1.06]
      echartOption.yAxis[0] = {
        type: 'value',
        min: ~~((1 / yAxisRate[0]) * Math.min(...fetchedData.map(item => item.bk))),
        max: ~~(yAxisRate[0] * Math.max(...fetchedData.map(item => item.bk))),
      }
      echartOption.yAxis[1] = {
        type: 'value',
        min: ~~((1 / yAxisRate[1]) * Math.min(...fetchedData.map(item => item.tx))),
        max: ~~(yAxisRate[1] * Math.max(...fetchedData.map(item => item.tx))),
      }

      echartOption.xAxis[0].data = [];
      echartOption.series[0].data = [];
      echartOption.series[1].data = [];

      fetchedData.forEach((item) => {
        echartOption.xAxis[0].data.push(item.time);
        echartOption.series[0].data.push(item.bk);
        echartOption.series[1].data.push(item.tx);
      });

      this.mainChart.setOption(echartOption);
    },
  },
};
</script>
