<template>
  <v-container>
    <div class="container">
      <div id="chart2" class="explorer-chart"></div>
    </div>
  </v-container>
</template>

<script>
import { RewardChartOption, WaldenTheme } from "@/components/chart";

var echarts = require("echarts");

var echartOption = RewardChartOption;

WaldenTheme(echarts);

function chartResize(mainChart) {
  return function () {
    mainChart.resize();
  };
}

export default {
  name: "Chart",
  props: {
    chart_data: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      mainChart: null,
    };
  },
  watch: {
    chart_data() {
      this.updateChartData(this.chart_data);
    },
  },
  mounted() {
    this.mainChart = echarts.init(
      document.getElementById("chart2"),
      "echarts-theme-walden"
    );
    window.onresize = chartResize(this.mainChart);
    this.updateChartData(this.chart_data);
  },
  methods: {
    updateChartData(data) {
      var fetchedData = data;

      echartOption.xAxis[0].data = [];
      echartOption.series[0].data = [];

      fetchedData.forEach((item) => {
        echartOption.xAxis[0].data.push(item.date);
        echartOption.series[0].data.push(item.rewards);
      });

      this.mainChart.setOption(echartOption);
    },
  },
};
</script>
