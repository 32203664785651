function _formatTS(mss) {
  var days = parseInt(mss / (1 * 60 * 60 * 24));
  var hours = parseInt((mss % (1 * 60 * 60 * 24)) / (1 * 60 * 60));
  var minutes = parseInt((mss % (1 * 60 * 60)) / (1 * 60));
  var seconds = parseInt((mss % (1 * 60)) / 1);
  seconds = seconds >= 0 ? seconds : 0;
  var res = "";
  if (days > 0) {
    res = `${days} days`;
  } else if (hours > 0) {
    res = `${hours} hr${hours > 1 ? "s" : ""} ${minutes} min${
      minutes > 1 ? "s" : ""
    }`;
  } else if (minutes > 0) {
    res = ` ${minutes} min${minutes > 1 ? "s" : ""} ${seconds} sec${
      seconds > 1 ? "s" : ""
    }`;
  } else {
    res = `${seconds} second${seconds > 1 ? "s" : ""}`;
  }
  return res;
}

module.exports.formatTS = _formatTS;

module.exports.convertTime = function(tss) {
  var cts = parseInt((Date.now() + 500) / 1000);
  return _formatTS(cts - tss);
};

function pad(val) {
	return ("" + val).padStart(2, "0")
}

module.exports.convertDate = function(mss) {
  const longmss = mss * 1000;
  const date = new Date(longmss);
  const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())
  const year = date.getFullYear();
  const hr = pad(date.getHours())
  const mn = pad(date.getMinutes())
  const ss = pad(date.getSeconds())

  return `${month}/${day}/${year} ${hr}:${mn}:${ss}`;
};
