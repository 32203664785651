<template>
  <section class="section-twelve py-sm-5 py-0 mb-5">
    <div class="container pt-5">
      <div class="row flex-column align-items-center justify-content-center">
        <div class="upper col-xl-5 col-lg-5 col-md-5 col-sm-12">
          <h2 class="text-center poppins-font">
            {{ $t("homepage.videos") }}
          </h2>
          <hr class="middle-hr mb-0" />
        </div>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="4"
              v-for="v in videos"
              v-bind:key="v.url"
              style="text-align: center;"
            >
              <video
                v-if="!v.is_youtube"
                width="308"
                height="200"
                controls="controls"
                preload="metadata"
              >
                <source :src="v.url" />
              </video>
              <iframe
                v-if="v.is_youtube"
                width="308"
                class="video-class"
                height="200"
                :src="v.url"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
              ></iframe>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </section>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Videos",
  data() {
    return {
      videos: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      request.get("/index_video").then((res) => {
        res
          .sort((x, y) => x.fields.weight - y.fields.weight)
          .forEach((v) => {
            v.url = v.fields.url;
            v.is_youtube = false
            if (v.url.indexOf('https://www.youtube.com') == 0) {
              v.is_youtube = true
            } else {
              v.url = v.url + "#t=" + v.fields.placeHolderTime
            }
          });
        this.videos = res;
      });
    },
  },
};
</script>

<style scoped>
/* 手机端 */
@media (min-width: 125px) {
  .videoContainer {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, auto) !important;
  }

  .website {
    display: none;
  }

  .mob {
    width: 100%;
    height: 12.24rem;
    /* background-image:  url("/static/img/mainnetwelcome/bubble.png");    */
    background-image: url("/static/img/wallet/bj.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2.64rem;
    padding-right: 2.16rem;
  }

  .mobleft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3.12rem 0;
  }

  .cpcwallet {
    width: 8.88rem;
    height: 2rem;
    font-size: 1.44rem;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: rgba(48, 82, 120, 1);
    line-height: 2.16rem;
  }

  .info {
    width: 15.84rem;
    height: 1.36rem;
    font-size: 0.96rem;
    font-family: "Poppins-Regular";
    font-weight: 400;
    color: rgba(48, 82, 120, 1);
    line-height: 1.44rem;
    margin-top: 0.24rem;
    margin-bottom: 0.32rem;
  }

  .split {
    width: 1.68rem;
    height: 2px;
    background-color: #305278;
  }

  .btnwarp {
    width: 9.28rem;
    height: 2.4rem;
    border-radius: 1.6rem;
    background-image: url("/static/img/wallet/button.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  .btntext {
    /* width: 5.68rem; */
    /* height: 17px; */
    font-size: 0.96rem;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: rgba(48, 82, 120, 1);
    /* line-height: 18px; */
  }

  .mobright {
    padding-top: 2.71rem;
    padding-left: 1.36rem;
  }
}

/* 电脑端 */
@media (min-width: 1024px) {
  .roadmap-web {
    display: block;
  }

  .roadmap-mob {
    display: none;
  }

  .videoContainer {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, auto) !important;
  }

  .mob {
    display: none;
  }

  .website {
    display: block;
    width: 100%;
    height: 540px;
    background-image: url("/static/img/wallet/beijing1.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .webleft {
    margin-right: 228px;
    margin-top: -7px;
    height: 322px;
    /* background-color: brown; */
    width: 221px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .webright {
    width: 319px;
    height: 430px;
    background-image: url("/static/img/wallet/iPhone.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .cpcwallet {
    width: 221px;
    height: 51px;
    font-size: 36px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: rgba(48, 82, 120, 1);
    line-height: 55px;
  }

  .group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info {
    width: 198px;
    /* height: 34px; */
    font-size: 12px;
    font-family: "Poppins-Regular";
    font-weight: 400;
    color: rgba(48, 82, 120, 1);
    line-height: 18px;
  }

  .split {
    width: 40px;
    height: 2px;
    background-color: #305278;
  }

  .qr {
    width: 108px;
    height: 159px;
    background: rgba(255, 255, 255, 1);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* padding: 12px 0 25px 0; */
  }

  .qrimg {
    width: 86px;
    height: 85px;
    margin-top: 12px;
  }

  .qrtext {
    margin-bottom: 25px;
    /* width: 65px;
                    height: 16px; */
    font-size: 11px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: rgba(48, 82, 120, 1);
    line-height: 17px;
  }
}
</style>
