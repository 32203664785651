<template>
  <v-container>
    <div
      class="text-center"
      v-if="loading"
      style="margin-top: 100px; margin-bottom: 100px"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="30"
      ></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <v-col lg="12">
        <v-row>
          <div class="info-table" style="width: 100%">
            <div class="info-table-list">
              <div class="info-table-list-content">
                <div class="table">
                  <div class="row list-header">
                    <div class="col text-truncate">Height</div>
                    <div class="col text-truncate marginfix">Age</div>
                    <div class="col text-truncate">Txn</div>
                    <div class="col text-truncate marginfix-l-sub70">
                      Producer
                    </div>
                    <div class="col text-truncate marginfix-l-add80">
                      Gas Used
                    </div>
                    <div class="col text-truncate">Gas Limit</div>
                    <div class="col text-truncate">Reward</div>
                  </div>
                  <div
                    v-if="blocks.length == 0"
                    style="
                      color: #aaa;
                      font-size: 0.8rem;
                      width: 100%;
                      text-align: center;
                    "
                  >
                    No Blocks
                  </div>
                  <div
                    class="row row-content"
                    style="padding-left: 7px"
                    v-for="b in blocks"
                    v-bind:key="b.number"
                  >
                    <div class="col text-truncate">
                      <span>Height:</span>
                      <img
                        :src="require('@/assets/img/explorer_impeach.png')"
                        alt=""
                        srcset=""
                        v-if="b.impeach"
                      />
                      <router-link
                        v-bind:style="{
                          'margin-left': !b.impeach ? '20px' : '4px',
                        }"
                        style="color: #1ba3ff"
                        :to="'/explorer/block/' + b.hash"
                        >{{ b.number }}</router-link
                      >
                    </div>
                    <div class="col text-truncate marginfix" style="padding-left: 16px;" v-cloak>
                      <span>Age:</span>{{ convertTime(b.timestamp) }} &nbsp;ago
                    </div>
                    <div class="col text-truncate">
                      <span>Txn:</span>
                      <a href="">{{ b.txs_cnt }}</a>
                    </div>
                    <div class="col text-truncate marginfix-l-sub70">
                      <span>Producer:</span
                      ><router-link :to="'/explorer/address/' + b.miner">{{
                        b.miner
                      }}</router-link>
                    </div>
                    <div class="col text-truncate marginfix-l-add80">
                      <span>Gas Used:</span>{{ b.gasUsed }}
                    </div>
                    <div class="col text-truncate">
                      <span>Gas Limit:</span>{{ b.gasLimit }}
                    </div>
                    <div class="col text-truncate">
                      <span>Reward:</span>{{ b.reward }} CPC
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <v-row class="pl-0">
              <v-col lg="12" style="font-size: 12px; color: #aaa;" class="pl-0">
                <span style="padding-left: 29px;  opacity: 0.8;">Total Blocks:&nbsp;</span>
                <label>{{ count }} txs</label>
              </v-col>
            </v-row>
            <pagination
              style="margin-top: 20px"
              v-if="count > 0"
              :page="page"
              :count="count"
              :limit="limit"
              :change="pageChange"
            />
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatTS } from "@/utils/ts";
import Pagination from "@/components/Pagination.vue";

export default {
  components: { Pagination },
  name: "BlocksList",
  props: ["address"],
  data() {
    return {
      loading: false,
      blocks: [],
      page: 1,
      limit: 25,
      count: 0,
    };
  },
  created() {
    if (this.address) {
      this.fecthProposerHistory();
    } else {
      this.fetchData();
    }
  },
  watch: {
    page() {
      if (this.address) {
        this.fecthProposerHistory();
      } else {
        this.fetchData();
      }
    },
    address() {
      this.page = 1;
      this.count = 0;
      this.fecthProposerHistory();
    },
  },
  methods: {
    pageChange(page) {
      this.page = page;
    },
    fecthProposerHistory() {
      this.fetch(
        `/explorer/api/proposer-history/${this.address}/?page=${this.page}&limit=${this.limit}`
      );
    },
    fetchData() {
      this.fetch(`/explorer/api/blocks/?page=${this.page}&limit=${this.limit}`);
    },
    fetch(url) {
      this.loading = true;
      this.$http
        .get(url)
        .then((data) => {
          this.blocks = data.results;
          this.count = data.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    convertTime: function (tss) {
      var cts = parseInt((Date.now() + 500) / 1000);
      return formatTS(cts - tss);
    },
  },
};
</script>

<style scoped>

.info-table {
  margin: 0px 0px 120px;
}

.pl-0 {
  padding-left: 0px;
}

</style>
