import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// pages
import Index from "@/pages";
import Example from "@/pages/example";
import RNode from "@/pages/rnode";
import {
  PublicSecurity,
  SmartMedical,
  IntelligentTransportation,
} from "@/pages/solutions";

import News from "@/pages/news";
import NewsList from "@/pages/news/NewsList";
import NewsDetail from "@/pages/news/NewsDetail"
import SearchResult from "@/pages/news/search"

import Explorer from "@/pages/explorer"
import RNodes from "@/pages/explorer/RNodes"
import Proposers from "@/pages/explorer/Proposers"
import BlocksList from "@/pages/explorer/BlocksList"
import TxsList from "@/pages/explorer/TxsList"
import BlockInfo from '@/pages/explorer/BlockInfo'
import TxInfo from '@/pages/explorer/TxInfo'

import Address from "@/pages/explorer/address"
import ProposerHistory from "@/pages/explorer/address/ProposerHistory"
import GroupInfo from "@/pages/explorer/address/GroupInfo"


import Market from '@/pages/dapp/market'
import Page404 from "@/pages/errors/Page404"
import Page500 from "@/pages/errors/Page500"
import Search404 from "@/pages/errors/Search404"

import NodeMap from "@/pages/map"
import Faucet from "@/pages/faucet"


const otherRoutes = [
  {
    path: "/downloadApp",
    component: Index,
    meta: {
      title: "Download - Mobile App"
    }
  },
  {
    path: "/contact",
    component: Index,
    meta: {
      title: "Contact"
    }
  },

  {
    path: "/rnode",
    component: RNode,
    meta: {
      title: "RNodes - CPChain",
    },
  },
  {
    path: '/dapp/market',
    component: Market,
    meta: {
      title: "Dapp - CPChain"
    }
  },

  {
    path: '/explorer/map',
    component: NodeMap,
    meta: {
      title: "NodeMap - CPChain"
    }
  },

  {
    path: '/404',
    component: Page404,
    meta: {
      title: "404 - CPChain"
    }
  },

  {
    path: '/search404',
    component: Search404,
    meta: {
      title: "Search 404 - CPChain"
    }
  },

  {
    path: '/500',
    component: Page500,
    meta: {
      title: "500 - CPChain"
    }
  },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const explorerRoutes = [
  {
    path: "/explorer",
    component: Explorer,
    meta: {
      title: "Explorer - CPChain"
    }
  },

  {
    path: "/explorer/rnodes",
    component: RNodes,
    meta: {
      title: "RNodes - CPChain"
    }
  },

  {
    path: "/explorer/proposers",
    component: Proposers,
    meta: {
      title: "Proposers - CPChain"
    }
  },

  {
    path: "/explorer/blocks",
    component: BlocksList
  },

  {
    path: "/explorer/txs",
    component: TxsList
  },

  {
    path: "/explorer/block_txs/:blockHash",
    component: TxsList,
    props: true
  },

  {
    path: '/explorer/block/:hash',
    component: BlockInfo,
    props: true
  },

  {
    path: '/explorer/tx/:hash',
    component: TxInfo,
    props: true
  },

  {
    path: '/explorer/address/:address',
    component: Address,
    props: true,
    meta: {
      title: "Address - CPChain"
    }
  },

  {
    path: '/explorer/proposer-history/:address',
    component: ProposerHistory,
    props: true,
    meta: {
      title: "Proposer History - CPChain"
    }
  },

  {
    path: '/explorer/groupchats-info/:group_id',
    component: GroupInfo,
    props: true,
    meta: {
      title: "GroupChats Info - CPChain"
    }
  }
]

let mainnetRoutes = [
  {
    path: "/example",
    component: Example,
  },
  {
    path: "/",
    component: Index,
  }, 
  {
    path: "/application",
    component: Index,
    meta: {
      title: 'Solutions'
    }
  },
  {
    path: "/roadmap",
    component: Index,
    meta: {
      title: 'Roadmap'
    }
  },

  {
    path: "/solutions/public_security",
    component: PublicSecurity,
    meta: {
      title: 'Solutions - Public Security'
    }
  },
  {
    path: "/solutions/smart_medical",
    component: SmartMedical,
    meta: {
      title: 'Solutions - Smart Medical'
    }
  },
  {
    path: "/solutions/intelligent_transportation",
    component: IntelligentTransportation,
    meta: {
      title: 'Solutions - Intelligent Transportation'
    }
  },

  {
    path: "/news",
    component: News,
    meta: {
      title: "News - CPChain"
    }
  },

  {
    path: "/news/list",
    component: NewsList,
    props: (route) => ({ category: route.query.title }),
  },

  {
    path: "/news/:id",
    component: NewsDetail,
    props: true
  },

  {
    path: "/news/search/:q",
    component: SearchResult,
    props: true
  },
];

let testnetRouts = [
  {
    path: "/",
    component: Explorer,
    meta: {
      title: "Explorer - CPChain"
    }
  },
  {
    path: "/faucet",
    component: Faucet,
    meta: {
      title: "Faucet - CPChain"
    }
  }
]

mainnetRoutes = mainnetRoutes.concat(explorerRoutes).concat(otherRoutes)
testnetRouts = testnetRouts.concat(explorerRoutes).concat(otherRoutes)

export const constantRoutes = process.env.VUE_APP_IS_TESTNET == 'true' ? testnetRouts: mainnetRoutes

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

const defaultTitle = 'CPChain - A new distributed infrastructure for next generation IoT'

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : defaultTitle
  next()
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
