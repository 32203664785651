<template>
  <v-container class="explorer-data-header">
    <v-row>
      <v-spacer />
      <v-col cols="8">
        <v-row class="data-header-wrapper">
          <router-link to="/explorer/blocks" class="col-md-2 col-sm-6 sep item">
            <div class="text-center">
              <h1 class="number-font" v-text="blockHeight"></h1>
            </div>
            <div class="text-center">
              <h3 class="index-font">Block Height</h3>
            </div>
          </router-link>
          <div class="col-md-2 col-sm-6 sep item" href="#">
            <div class="text-center">
              <h1 class="number-font" v-text="bps"></h1>
            </div>
            <div class="text-center">
              <h3 class="index-font">Blocks/second</h3>
            </div>
          </div>
          <router-link class="col-md-2 col-sm-6 sep item" to='/explorer/txs'>
            <div class="text-center">
              <h1 class="number-font" v-text="txs"></h1>
            </div>
            <div class="text-center">
              <h3 class="index-font">Transactions</h3>
            </div>
          </router-link>
          <div class="col-md-2 col-sm-6 sep item" href="#">
            <div class="text-center">
              <h1 class="number-font" v-text="tps"></h1>
            </div>
            <div class="text-center">
              <h3 class="index-font">Transaction(s)<br/>/second</h3>
            </div>
          </div>
          <router-link class="col-md-2 col-sm-6 sep item" to="/explorer/rnodes">
            <div class="text-center">
              <h1 class="number-font" v-text="rnode"></h1>
            </div>
            <div class="text-center"><h3 class="index-font">RNodes</h3></div>
          </router-link>
          <router-link
            class="col-md-2 col-sm-6 text-center item"
            to="/explorer/proposers"
          >
            <div class="number-font proposer" v-text="committee"></div>
            <div class="number-font proposer sub-font">of</div>
            <div class="number-font proposer" v-text="proposer"></div>
            <div class="text-center">
              <h3 class="index-font">Proposers</h3>
            </div>
          </router-link>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DataHeader",
  props: {
      blockHeight: {
          default: 0
      },
      txs: {
          default: 0
      },
      bps: {
          default: 0
      },
      tps: {
          default: 0
      },
      rnode: {
          default: 0
      },
      committee: {
          default: "1st"
      },
      proposer: {
          default: 0
      },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>

.explorer-data-header {
  min-width: 100%;
  background-color: #f7f9fc;
  padding-top: 20px;
  padding-bottom: 20px;
}

.sep {
  border-right: 2px #d5dce4 solid;
}

.number-font {
  color: #305278;
  font-size: 28px;
  font-family: poppins-italic;
}

.index-font {
  color: #305278;
  font-size: 16px;
  font-family: Poppins;
  letter-spacing: 3px;
}

.proposer {
  margin: 2px 4px 4px 4px;
  display: inline-block;
}

.sub-font {
  font-size: 12px;
  letter-spacing: 1px;
}

.item {
    padding: 0;
}

.data-header-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
}

</style>
