<template>
  <v-container class="node-map-page">
    <v-row>
      <v-col cols="2" lg="2"/>
      <v-col cols="8" lg="8">
        <div class="page-header">
          <div class="page-header-main">
            <p class="text-center poppins-font">Nodes Map</p>
          </div>
        </div>
      </v-col>
      <v-col cols="2" lg="2"/>
    </v-row>
    <v-row>
      <v-col lg="12">
        <div
          class="text-center"
          v-if="loading"
          style="margin-top: 100px; margin-bottom: 100px"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :size="30"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="0" lg="2"/>
      <v-col cols="12" lg="8">
        <div id="nodemap"></div>
      </v-col>
      <v-col cols="0" lg="2" />
    </v-row>
  </v-container>
</template>

<script>
import registerWorld from "./world";
let echarts = require("echarts");

registerWorld(echarts);

export default {
  name: "NodeMap",
  data() {
    return {
      loading: false,
      myChart: null,
    };
  },
  mounted() {
    if (!this.myChart) {
      this.myChart = echarts.init(document.getElementById("nodemap"));
    }
    this.fecthData();
  },
  methods: {
    makeMapDataFromList(list) {
      var mapData = [];
      for (let i = 0; i < list.length; i++) {
        mapData.push({
          name: list[i].city,
          value: [list[i].longitude, list[i].latitude],
        });
      }
      return mapData;
    },
    fecthData() {
      this.loading = true
      this.$http.get("/nodes/ip/?limit=1000").then((data) => {
        this.myChart.setOption(
          this.getOption(this.makeMapDataFromList(data.results)),
          true
        );
        this.loading = false
      }).catch(()=>{
        this.loading = false
      })
    },
    getOption(data) {
      return {
        backgroundColor: new echarts.graphic.RadialGradient(0.5, 0.5, 0.4, [
          {
            offset: 0,
            color: "#495769",
          },
          {
            offset: 1,
            color: "#604a59",
          },
        ]),
        title: {
          text: "CPChain Node Distribution Diagram",
          // subtext: 'data from macrofocus',
          // sublink: 'https://www.macrofocus.com/public/products/infoscope/datasets/pricesandearnings/',
          left: "center",
          top: 5,
          itemGap: 0,
          textStyle: {
            color: "#eee",
          },
          z: 200,
        },
        brush: {
          geoIndex: 0,
          brushLink: "all",
          inBrush: {
            opacity: 1,
            symbolSize: 14,
          },
          outOfBrush: {
            color: "#000",
            opacity: 0.2,
          },
          z: 10,
        },
        geo: {
          map: "world",
          silent: true,
          emphasis: {
            label: {
              show: false,
              areaColor: "#eee",
            },
          },
          itemStyle: {
            borderWidth: 0.1,
            borderColor: "#404a59",
          },
          left: "6%",
          top: 40,
          bottom: "6%",
          right: "6%",
          // center: [104.114129, 37.550339],//当前视角的中心点
          zoom: 1, //当前视角的缩放比例
          roam: true, //是否开启平游或缩放
          scaleLimit: {
            //滚轮缩放的极限控制
            min: 1,
            max: 2,
          },
        },
        series: [
          {
            name: "Nodes",
            type: "scatter",
            coordinateSystem: "geo",
            data: data,
            activeOpacity: 1,
            label: {
              formatter: "{b}",
              position: "right",
              show: false,
              color: "#577ceb",
            },
            symbolSize: 10,
            // symbolSize: function (data) {
            //     return Math.max(5, data[2] / 5);
            // },
            itemStyle: {
              borderColor: "#fff",
              color: "#577ceb",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.node-map-page {
  margin-top: 100px;
  margin-bottom: 50px;
  min-width: 100%;
  font-family: "Poppins", sans-serif !important;
}

#nodemap {
  width: 100%;
  margin-top: 20px;
  height: 600px;
}

@media (max-width: 400px) {
  #nodemap {
    height: 250px;
    margin-top: 0px;
  }
}

</style>
