<template>
  <footer class="footer pb-2 pb-sm-0">
    <div class="container">
      <div
        class="row flex-rox d-flex align-items-center justify-content-center"
      >
        <!--<hr class="w-100 middle-footer-hr">-->
        <div
          class="col-12 d-flex flex-row flex-wrap align-items-center justify-content-center px-0 py-3 py-sm-5"
        >
          <div class="col-sm-4 col-6 d-flex flex-row align-items-center">
            <a href="https://cpchain.io" class="logo-hov">
              <div
                class="image-footer-holder d-flex flex-row align-items-center justify-content-start"
              >
                <img src="@/assets/img/logo_new.svg" class="disable-select" />
              </div>
            </a>
          </div>
          <div
            class="col-sm-4 middle-col col-6 text-sm-right text-center d-flex align-items-center justify-content-end justify-content-sm-center"
          >
            <h6 id="contact" class="mb-0 pr-sm-0 poppins-font">
              <a href="mailto:info@cpchain.io" class=""
                ><span class="__cf_email__">info@cpchain.io</span></a
              >
            </h6>
          </div>
          <div
            class="col-sm-4 col-12 last-col d-flex flex-row align-items-xl-center justify-content-xl-end align-items-lg-center justify-content-lg-end align-items-md-center justify-content-md-end align-items-sm-center justify-content-sm-end justify-content-center list-unstyled mt-5 mt-sm-0"
          >
            <social-media />
          </div>
        </div>
        <hr class="middle-footer-hr w-100" />
        <div class="col-12 pb-sm-4 pt-sm-3 text-center">
          <h6 class="text-opacity small">
            2021 CPChain Team © All rights reserved.
          </h6>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import SocialMedia from '../components/SocialMedia.vue'

export default {
  components: {
    'social-media': SocialMedia
  },
  name: "Footer",
  data() {
    return {};
  },
  methods: {},
};
</script>
