<template>
  <div class="news-list">
    <search-header />
    <section
      class="section-category py-5 d-flex align-items-center justify-content-center"
    >
      <div class="container h-100">
        <div class="row">
          <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 mx-auto">
            <h3 class="text-center w-100 my-3">
              {{ category }}
            </h3>
          </div>

          <div class="col-12 col-sm-12 mt-4">
            <hr class="middle-hr" />
          </div>
        </div>

        <div
          class="text-center"
          v-if="loading"
          style="margin-top: 100px; margin-bottom: 100px"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :size="30"
          ></v-progress-circular>
        </div>

        <div class="row" v-if="!loading">
          <div
            class="card blog border-0 col-sm-4 my-4"
            v-for="n in news"
            v-bind:key="n.title"
          >
            <router-link :to="'/news/'+n.id">
              <div class="image-holder position-relative">
                <img
                  width="800"
                  height="400"
                  :src="n.banner"
                  class="d-block w-100 h-100 img-fluid disable-select wp-post-image"
                  alt=""
                  sizes="(max-width: 767px) 89vw, (max-width: 1000px) 54vw, (max-width: 1071px) 543px, 580px"
                  loading="lazy"
                />
              </div>
            </router-link>

            <div class="card-body px-0 pb-0">
              <h6 class="card-title text-primary news">
                <a href="" class="text-primary small"> {{ category }} </a>
              </h6>
              <h5 class="card-title">
                <a href=""> {{ n.title }} </a>
              </h5>

              <p class="card-text">
                {{ n.summary }}
              </p>
              <div class="card border-0 d-flex flex-row ml-0 w-100">
                <a href="">
                  <div class="image-smaller-holder">
                    <img
                      class="card-img-top bottom-cards disable-select h-100"
                      :src="logo"
                      alt="Card image cap"
                    />
                  </div>
                </a>
                <div class="card-body py-0 d-flex align-items-center">
                  <p class="card-text card-bottom-text">
                    <a href=""> CPChain </a>
                    <br />
                    {{ n.update_time }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!loading">
          <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="6"
          ></v-pagination>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SearchHeader from "./SearchHeader.vue";
export default {
  components: { SearchHeader },
  name: "NewsList",
  props: ["category"],
  data() {
    return {
      loading: false,
      page: 1,
      logo: require("@/assets/img/logo.svg"),
      count: 0,
      limit: 9,
      offset: 0,
      news: [],
    };
  },
  created() {
    document.title = this.category + " - CPChain"
    // offcial announcements
    this.fetchData();
  },
  watch: {
    page(val) {
      this.offset = (val - 1) * this.limit;
      this.fetchData();
    },
  },
  computed: {
    pageLength() {
      return (this.count - this.count % this.limit) / this.limit + (this.count % this.limit == 0 ? 0 : 1);
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http
        .get(
          `/news/news/?limit=${this.limit}&offset=${this.offset}&category=${this.category}`
        )
        .then((data) => {
          this.count = data.count;
          this.news = data.results;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.news-list {
  margin-top: 100px;
}
</style>
