<template>
  <v-container>
    <v-row>
      <v-col lg="4" md="4" style="margin-left: 16px;">
        <v-text-field label="Search" v-model="addr_or_name" @keydown="confirm"></v-text-field>
      </v-col>
    </v-row>
    <div class="text-center" v-if="loading" style="margin-top: 100px; margin-bottom: 100px">
      <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <div class="info-table" style="width: 100%; margin-left: 0px; margin-right: 0px">
        <div class="info-table-list">
          <div class="info-table-list-content">
            <div class="table">
              <div class="row list-header">
                <div class="col text-truncate  col-xl-1 col-lg-1 col-md-1 col-sm-12">ID</div>
                <div class="col text-truncate  col-xl-4 col-lg-4 col-md-4 col-sm-12">Address</div>
                <div class="col text-truncate  ">Block Number</div>
                <div class="col text-truncate  ">Name</div>
                <div class="col text-truncate  ">Update Time</div>
              </div>
              <div v-if="identities.length == 0" style="
                      color: #aaa;
                      font-size: 0.8rem;
                      width: 100%;
                      text-align: center;
                    ">
                No Identity
              </div>
              <div class="row row-content" v-for="(identity, index) in identities" v-bind:key="identity.id">

                <div class="col text-truncate  col-xl-1 col-lg-1 col-md-1 col-sm-12" >
                  <span>ID:</span>{{ count - index - (page - 1) * limit }}
                </div>

                <div class="col text-truncate  col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <span>Address:</span>
                  <router-link :to="'/explorer/address/' + identity.address" v-cloak>{{ identity.address | addrHandler }}</router-link>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <p class="copy_btn" @mouseover="copy_hover = true" @mouseleave="copy_hover = false;copy_text = copy_text_before;" v-bind="attrs" v-on="on" @click="copy(identity.address)">
                        <v-icon aria-label="Copy address" dark:true>fas fa-copy</v-icon>
                      </p>
                    </template>
                    <span>{{ copy_text }}</span>
                  </v-tooltip>
                </div>

                <div class="col text-truncate "> 
                    <span>Block Number:</span>{{ identity.block_number }}
                </div>
                <div class="col text-truncate  ">
                  <span>Name:</span> {{ identity.latest.name }} 
                </div>
                <div class="col text-truncate  ">
                  <span>Update time:</span>{{ convertTime(identity.updated_at) }} ago
                </div>
              </div>
            </div>
          </div>
        </div>
        <pagination style="margin-top: 20px" :page="page" :count="count" :limit="limit" :change="pageChange" v-if="  count > 0" />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { formatTS } from "@/utils/ts";
import Pagination from "../Pagination.vue";


export default {
  components: { Pagination },
  name: "IdentityList",
  props: ['address',],
  filters: {
    truncateAddr(addr) {
      if (addr.length < 40) {
        return addr
      }
      return addr.substr(0, 14) + "…"
    },
    addrHandler(address) {
      let len = 8
      return address.substr(0, len) + "…" + address.substr(address.length - len, len)
    }
  },
  data() {
    return {
      loading: false,
      identities: [],
      page: 1,
      limit: 30,
      count: 0,
      copy_hover: false,
      copy_text_before: "Copy address to clipboard",
      copy_text_after: "The address have been copied",
      copy_text: this.copy_text_before,
      addr_or_name: '',
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    page() {
      this.fetchData();
    },
    address() {
      this.reload()
    },
    addr_or_name() {
      this.debounce(this.reload, 500)()
    },
  },
  methods: {
    debounce(fn,wait){
        var timer = null;
        return function(){
            if(timer !== null){
                clearTimeout(timer);
            }
            timer = setTimeout(fn,wait);
        }
    },
    reload() {
      this.page = 1;
      this.count = 0;
      this.fetchData();
    },
    pageChange(page) {
      this.page = page;
    },

    fetchData() {
      this.loading = true;
      let params = {
        offset: (this.page - 1) * this.limit,
        limit: this.limit,
      };

      if (this.addr_or_name) {
        params.search = this.addr_or_name.toLowerCase()
      }

      this.$http
        .get(`/encryptedchat/identity/`, { params: params })
        .then((data) => {
          console.log(!this.address, data)
          this.identities = data.results;
          this.count = data.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    convertTime: function (time) {
      let tss = new Date(time).getTime() / 1000
      var cts = parseInt((Date.now() + 500) / 1000);
      return formatTS(cts - tss);
    },
    copy(msg) {
      this.$copyText(msg).then(
        () => {
          this.copy_text = this.copy_text_after;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  },
};
</script>

<style scoped>
.container {
  padding-left: 0 !important;
  /* width: 110%; */
}
.row {
  padding-left: 15px !important;
}
.deposit {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #96DBFF;
  color: #138EED;
  margin-top: 15px;
}
.withdraw {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #ffeecf;
  color: #e77e1b;
  margin-top: 15px;
}
.interest {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #d9f7be;
  color: #5b8c00;
  margin-top: 15px;
}
.staking-type {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.type-img { 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.copy_btn {
  display: inline;
  margin-left: 15px;
  padding: 0px 4px;
  /* border-radius: 50%; */
  /* box-shadow: 0 0 5px #eee; */
  cursor: pointer;
}

.v-icon {
  font-size: 14px;
  line-height: 18px;
}
.v-icon:hover{
  color:#138EED;
}
</style>
