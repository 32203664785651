<template>
  <v-container class="explorer-rnodes">
    <search-bar />
    <div
      class="text-center"
      v-if="loading"
      style="margin-top: 100px; margin-bottom: 100px"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="30"
      ></v-progress-circular>
    </div>
    <v-row style="margin-top: 50px" v-if="!loading">
      <v-col lg="2" />
      <v-col lg="8" class="page-header">
        <v-row>
          <v-col lg="2">
            <v-row class="number-font">{{ term }}</v-row>
            <v-row class="index-font">Current Term</v-row>
          </v-col>
          <v-col lg="1"/>
          <v-col lg="2">
            <v-row class="number-font">{{ rnodes | length }}</v-row>
            <v-row class="index-font">RNodes</v-row>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row style="margin-top: 80px">
          <h3 class="text-left poppins-font">Rank</h3>
        </v-row>
      </v-col>
      <v-col lg="2" />
    </v-row>
    <v-row v-if="!loading">
      <v-spacer />
      <v-col lg="8">
        <div class="info-table">
          <div class="info-table-list">
            <div class="info-table-list-content">
              <div class="table">
                <div class="row" style="padding-left: 25px; margin-top: 30px">
                  <div class="col text-truncate list-header col-xl-1 col-lg-1 col-md-1 col-sm-12">Rank</div>
                  <div class="col text-truncate list-header col-xl-4 col-lg-4 col-md-4 col-sm-12">RNodes</div>
                  <div class="col text-truncate list-header col-xl-2 col-lg-2 col-md-2 col-sm-12">Total-Rewards</div>
                  <div class="col text-truncate list-header col-xl-2 col-lg-2 col-md-2 col-sm-12" style="margin-left: -32px">Yesterday-Rewards</div>
                  <div
                    class="col text-truncate list-header col-xl-2 col-lg-2 col-md-2 col-sm-12"
                    
                  >
                    Reputation
                  </div>
                  <div
                    class="col text-truncate list-header col-xl-1 col-lg-1 col-md-1 col-sm-12"
                    style="padding-left: 16px"
                  >
                    Status
                  </div>
                </div>

                <div
                  class="row row-content "
                  style="padding-left: 32px"
                  v-for="(rnode, index) in rnodes"
                  v-bind:key="rnode.address"
                >
                  <div class="col text-truncate col-xl-1 col-lg-1 col-md-1 col-sm-12"><span>Rank:</span>{{ index + 1 }}</div>
                  <div class="col text-truncate col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <span>RNodes:</span>
                    <router-link
                      :to="'/explorer/address/' + rnode.address"
                      style="
                        color: #1ba3ff;
                        font-family: 'Helvetica Neue,Helvetica,Arial,sans-serif';
                      "
                      >{{ rnode.address | addrHandler }}</router-link>
                  </div>
                  <div class="col text-truncate card-grey col-xl-2 col-lg-2 col-md-2 col-sm-12" style="padding-left: 0px">
                    <span>Total-Rewards:</span>
                    {{ parseFloat(rnode.total_reward).toFixed(2) }} CPC
                  </div>
                  <div class="col text-truncate card-grey col-xl-2 col-lg-2 col-md-2 col-sm-12" style="padding-left: 0px">
                    <span>Yesterday-Rewards:</span>
                    {{ parseFloat(rnode.yesterday_reward).toFixed(2) }} CPC
                  </div>
                  <div class="col text-truncate card-grey col-xl-2 col-lg-2 col-md-2 col-sm-12">
                    <span>Rpt:</span>
                    {{ rnode.rpt }}
                  </div>
                  <div class="col text-truncate card-grey col-xl-1 col-lg-1 col-md-1 col-sm-12" style="padding-left: 0px">
                    <span>Status:</span>
                    {{ rnode.status }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from "./SearchBar.vue";
export default {
  components: { SearchBar },
  name: "RNodes",
  data() {
    return {
      loading: false,
      term: 0,
      count: 0,
      rnodes: [],
    };
  },
  filters: {
    length(val) {
      return val.length;
    },
    addrHandler(address) {
      let len = 12
      return address.substr(0, len) + "…" + address.substr(address.length - len, len)
    },
  },
  mounted() {
    this.fecthData()
  },
  methods: {
    fetchTerm() {
      this.$http.get()
    },


    fecthData() {
      this.loading = true
      this.$http.get('./explorer/api/rnode-detail/?limit=200').then(data => {
        this.count = data.count
        this.rnodes = data.results
        this.term = data.results[0].Term
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
};
</script>

<style scoped>
.explorer-rnodes {
  margin-top: 100px;
  margin-bottom: 10px;
  min-width: 100%;
}

.number-font {
  color: #305278;
  font-size: 32px;
  font-family: poppins-italic;
}

.index-font {
  color: #305278;
  font-size: 20px;
  font-family: Poppins;
  letter-spacing: 3px;
}

.row-content {
  padding: 0%;
}

.text-truncate {
  padding-top: 0px;
  font-size: 14px;
}

</style>

