<template>
  <v-container>
    <div class="text-center" v-if="loading" style="margin-top: 100px; margin-bottom: 100px">
      <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <div class="info-table" style="width: 100%; margin-left: 0px; margin-right: 0px">
        <div class="info-table-list">
          <div class="info-table-list-content">
            <div class="table">
              <div class="row list-header">
                <div class="col text-truncate  ">Node</div>
                <div class="col text-truncate  ">Reward Yesterday</div>
                <div class="col text-truncate  ">Reward Today</div>
                <div class="col text-truncate  ">Total Reward</div>
                <div class="col text-truncate  ">Operation Time</div>
              </div>
              <div v-if="workers.length == 0" style="
                      color: #aaa;
                      font-size: 0.8rem;
                      width: 100%;
                      text-align: center;
                    ">
                No Nodes
              </div>
              <div class="row row-content" v-for="worker in workers" v-bind:key="worker.id">
                <div class="col text-truncate">
                  <span>Node:</span><router-link :to="'/explorer/address/' + worker.address" v-cloak>{{ worker.address }}</router-link>
                </div>

                <div class="col text-truncate">
                  <span>Yesterday:</span>{{ parseFloat(worker.yesterday_reward).toFixed(2) }} CPC
                </div>
                <div class="col text-truncate type-img "> 
                    <span>Today:</span>{{ parseFloat(worker.today_reward).toFixed(2) }} CPC
                </div>

                <div class="col text-truncate  ">
                  <span>Total:</span> {{ parseFloat(worker.total_reward).toFixed(2) }} CPC
                </div>
                <div class="col text-truncate  ">
                  <span>Time:</span>{{ worker.operation_time | tsFliter }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <pagination style="margin-top: 20px" :page="page" :count="count" :limit="limit" :change="pageChange" v-if="  count > 0" />
      </div>
    </v-row>
  </v-container>
</template>

<script>
// import { formatTS } from "@/utils/ts";
import Pagination from "../Pagination.vue";
import { ts_time } from '../../utils/tstotime.js'


export default {
  components: { Pagination },
  name: "NodeList",
  props: ['address'],
  filters: {
    truncateAddr(addr) {
      if (addr.length < 40) {
        return addr
      }
      return addr.substr(0, 14) + "…"
    },
    tsFliter(ts) {
      return ts_time(ts)
    }
  },
  data() {
    return {
      loading: false,
      workers: [],
      page: 1,
      limit: 30,
      count: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    page() {
      this.fetchData();
    },
    address() {
      this.reload()
    },

  },
  methods: {
    reload() {
      this.page = 1;
      this.count = 0;
      this.fetchData();
    },
    pageChange(page) {
      this.page = page;
    },

    fetchData() {
      this.loading = true;
      let params = {
        offset: (this.page - 1) * this.limit,
        limit: this.limit,
      };

      this.$http
        .get(`/staking/worker/`, { params: params })
        .then((data) => {
          // console.log(!this.address, data)
          this.workers = data.results;
          this.count = data.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.container {
  padding-left: 0 !important;
  /* width: 110%; */
}
.row {
  padding-left: 15px !important;
}
.deposit {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #96DBFF;
  color: #138EED;
  margin-top: 15px;
}
.withdraw {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #ffeecf;
  color: #e77e1b;
  margin-top: 15px;
}
.interest {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #d9f7be;
  color: #5b8c00;
  margin-top: 15px;
}
.staking-type {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.type-img { 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
</style>
