<template>
  <div class="site">
    <div class="site-content-contain">
      <div id="content" class="site-content">
        <div id="content" class="site-content">
          <div class="wrap">
            <div id="primary" class="content-area">
              <main id="main" class="site-main" role="main">
                <section
                  class="section-partners py-5 d-flex align-items-center justify-content-center"
                  style="color: #383c43"
                >
                  <div class="container px-0 h-100">
                    <div class="row ml-0 mr-0">
                      <div class="blog-view-holder">
                        <div class="upper col-sm-7 col-12 mx-auto">
                          <h3 class="mt-3 text-left">
                            CPChain - RNode Ecosystem Structure
                          </h3>
                          <h6 class="font-weight-light first">
                            by CPChain at Aug 31, 2018
                          </h6>
                          <div class="image-holder py-4">
                            <img
                              class="w-100"
                              src="@/assets/img/str-release.png"
                            />
                          </div>
                          <p id="d7ab" class="graf graf--p graf-after--figure">
                            The origin of Blockchain is distributed ledger
                            technology and decentralization to develop a fair
                            distribution of digital assets. In the CPChain
                            Ecosystem, we guarantee that the majority of the
                            nodes will have the right to participate in
                            supporting the ecosystem. The return on investment
                            from such participation is directly related to the
                            contribution from the nodes.
                          </p>
                          <p>
                            Below you can find the CPChain RNode Ecosystem
                            Structure chart:
                          </p>
                        </div>

                        <div class="middle col-sm-7 col-12 mx-auto">
                          <div class="image-holder-main pt-3">
                            <img
                              class="w-100"
                              src="@/assets/img/ecosystem.png"
                            />
                            <figcaption class="text-center pt-3">
                              CPChain RNode Structure working flow
                            </figcaption>
                          </div>
                        </div>
                        <div
                          class="bottom pb-5 pt-0 mx-auto col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 d-flex align-items-center justify-content-center flex-column"
                        >
                          <div class="box-holder">
                            <h5>1. CPChain Nodes Roles</h5>
                            <p>
                              <b>Economy Node: </b>Requires a minimum of 20,000
                              CPC tokens for participation. Investors who meet
                              this requirement may participate as an economy
                              node and have the right to vote in the community.
                            </p>
                            <p>
                              <b>Reputation Node: </b>Requires a minimum of
                              200,000 CPC tokens for participation. Investors
                              with the basic configuration of computing and
                              storing can participate to support the CPChain
                              Open Transmission Protocol (COTP).
                            </p>
                            <p>
                              <b>Industry Node: </b>IoT Industry partners and
                              CPChain ecosystem’s peer developers have the right
                              to participate as an Industry Node.
                            </p>
                          </div>

                          <div class="box-holder">
                            <h5 class="mt-0 pt-4">2. Reputation Nodes</h5>
                            <p class="mb-0 pb-4">
                              A node has to meet one of the following
                              requirements to become a Reputation Node:
                            </p>
                            <p>
                              <b>
                                a. Economy Node + Computing and Storage Node: </b
                              ><br />
                              An economy node must lock-up a specific amount of
                              tokens 200,000 minimum and 5,000,000 maximum) for
                              90 days and must satisfy the software, hardware,
                              and network connection requirements. The locked up
                              tokens have a positive correlation with the basic
                              rewards. Reputation nodes will be refunded after
                              they leave the election.
                            </p>
                            <p>
                              <b
                                >b. Industry Node + Computing and Storage
                                Node:</b
                              ><br />
                              An industry node will be upgraded to a reputation
                              node once meeting all the hardware, software, and
                              network requirements. Industry nodes must be
                              verified by the CPChain foundation.
                            </p>
                            <p>
                              Reputation Nodes have the right to be elected as a
                              committee member and to be granted rewards from
                              the Blockchain.
                            </p>
                          </div>

                          <div class="box-holder">
                            <h5>
                              3.DPoR Consensus Overview
                            </h5>
                            <p>
                              The CPChain consensus system adopts the Dynamic
                              Proof of Reputation Agreement (DPoR) which is
                              developed by Shanghai Jiao Tong University
                              Distributed and Smart System Lab.
                            </p>
                            <p>
                              The CPChain Ecosystem is divided into three layers
                              as seen below. Users can be upgraded to reputation
                              nodes after an initial admission test. The
                              specific election algorithm will pick out a
                              certain number of nodes from Layer 2 to conduct
                              the Layer 3 dynamic committee in order to maintain
                              the blockchain.
                            </p>
                            <p>
                              Layer 3 primarily solves the consensus problem of
                              creating, verifying, broadcasting, and adding
                              blocks onto the chain. Generally speaking, DPor is
                              an appropriate solution for the three issues of
                              consensus under the large-scale network, which are
                              node Reputation Assessment, Node Election and
                              Byzantine Fault Tolerance (BFT) Consensus within
                              the committee.
                            </p>
                          </div>

                          <div class="">
                            <div class="image-holder-main">
                              <img
                                class="w-100"
                                src="@/assets/img/protocol.png"
                              />
                              <figcaption class="text-center pt-3">
                                DPor Consensus Protocol Overview
                              </figcaption>
                            </div>
                          </div>

                          <div class="box-holder">
                            <h5>
                              Node Reputation Value Assessment Procedure (RVAP)
                            </h5>
                            <p>
                              Based on data from blockchain, CPChain will
                              conduct the node reputation assessment model to
                              calculate and assess the Reputation Value (RV) of
                              every node in the system. The reputation model
                              will be divided into five parts:
                            </p>
                            <ul class="list-unstyled">
                              <li class="mb-2">
                                1.<b>&nbsp; Account Balance</b>
                              </li>
                              <li class="mb-2">
                                2.<b>&nbsp; Transaction</b>
                              </li>
                              <li class="mb-2">
                                3.<b
                                  >&nbsp; Proxy Reputation</b
                                >
                              </li>
                              <li class="mb-2">
                                4.<b
                                  >&nbsp; Data Contribution</b
                                >
                              </li>
                              <li class="mb-2">
                                5.<b>&nbsp; Blockchain Maintenance</b>
                              </li>
                            </ul>
                          </div>

                          <div class="box-holder">
                            <p>
                              <b>1. Account Balance:</b>
                              <br />
                              CPC token balance in RNode’s account will have a
                              positive correlation with the Reputation Value. It
                              will contribute 40%～50% in the Reputation Value
                              Assessment Procedure (RVAP).
                            </p>
                          </div>

                          <div class="box-holder">
                            <p>
                              <b>2. Transaction:</b><br />
                              “Transaction” refers to actions in which a RNode
                              uses CPC to buy data in CPChain’s PDash data
                              marketplace. It will contribute 15%～20% in RVAP.
                            </p>
                          </div>

                          <div class="box-holder">
                            <p>
                              <b>3. Proxy: </b><br />
                              Serving as the role of proxy, a RNode will earn
                              reputation by assisting others in the marketplace.
                              It will contribute 10%～15% in RVAP.
                            </p>
                          </div>

                          <div class="box-holder">
                            <p>
                              <b>4. Data Contribution:</b
                              ><br />
                              A node will earn reputation value by uploading
                              data, it will include base reputation value and
                              bonus. A RNode will earn reputation value by
                              uploading data and will earn a bonus when a
                              related transaction happens. This part will
                              contribute 15%～20% in RVAP. 
                            </p>
                          </div>

                          <div class="box-holder">
                            <p>
                              <b
                                >5. Blockchain Maintenance: </b
                              ><br />
                              All the committee members will be granted a
                              certain amount of reputation value after blocks
                              being produced by the committee. This part will
                              contribute 10%～15%. 
                            </p>
                          </div>

                          <div class="box-holder">
                            <p>
                              <b>Characteristics of DPoR:</b
                              ><br />
                              The design of DPoR has three layers of
                              consideration for the RNodes with regard to value
                              investment, volume contribution, and chain
                              maintenance. It avoids the risks in the
                              traditional centralized masternode structure in
                              which token balance or computing power are heavily
                              abused. As for how to comprehensively value the
                              RNode contribution to the entire CPChain
                              ecosystem, DPoR intends to build up a
                              decentralized, diverse and fair network.
                              Furthermore, the data set for calculating the
                              reputation value will be originated from the
                              Blockchain with complete traceability and
                              temper-resistance. The election result from the
                              model can be verified.
                            </p>
                          </div>

                          <div class="box-holder">
                            <h5>
                              Dynamic Committee Election
                            </h5>
                            <p>
                              For every committee round, 30–35 reputation nodes
                              will be elected. The committee is responsible for
                              the maintenance of existing blocks, and adding,
                              broadcasting and verifying new blocks. A certain
                              amount of rewards would be granted. DPoR system
                              election is based on the reputation nodes’
                              reputation value and random seeds. The election
                              algorithm will be disclosed, and any node could
                              execute the algorithm automatically, which makes
                              the outcome verifiable, thereby ensuring the
                              reliability of the election process. The election
                              result has a certain randomness, and the
                              possibility of one node be selected is
                              proportional to its reputation value. Currently,
                              one round is expected to be 5–10 minutes.
                            </p>
                            <h6>
                              <b>Characteristics</b>
                            </h6>
                            <p>
                              The DPOR election is based on nodes’ reputation
                              value and random seeds. Every candidate node has
                              the opportunity to be elected. The outcome cannot
                              be predicted by any node which ensures the process
                              is fair and secure. The election process is
                              completely anonymous, therefore, users’ privacy is
                              protected.
                            </p>
                          </div>

                          <div class="box-holder">
                            <h5>Within Committee: LBFT</h5>
                            <p>
                              DPoR uses Lightweight Byzantine Fault Tolerance
                              (LBFT) to achieve a fast agreement among the
                              committee members. LBFT has two stages: block
                              distribution and signature collection (the
                              committee needs to sign and verify a new block).
                              Currently, the system can tolerate up to one-third
                              of nodes having downtime or becoming malicious.
                              With the release of the main chain in future and
                              the optimized supervision and alternative
                              mechanisms after that, it is expected to achieve
                              up to half nodes have downtime or less than half
                              of the committee members become malicious.
                              Moreover, LBFT includes the fault tolerance
                              mechanism for extreme situations, i.e., the
                              majority of the committee is having downtime or
                              becoming malicious. This mechanism aims to enhance
                              the stability and robustness.
                            </p>
                          </div>

                          <div class="box-holder">
                            <div class="image-holder-main">
                              <img
                                class="w-100"
                                src="@/assets/img/lbft.png"
                              />
                            </div>
                          </div>

                          <div class="box-holder col-sm-12 px-0">
                            <h5>
                              4. Node Entitlements & Rewards
                            </h5>
                            <p>
                              CPChain’s ecosystem is established by a lot of
                              Internet of Things (IoT) enterprises, developers
                              and users. It is a long-term process. As a result,
                              CPChain will divide the incentive system into two
                              stages. In the first stage, CPChain Foundation
                              would be the main fund provider, for the ecosystem
                              establishment and the chain maintenance. The next
                              stage is mainly performed by the market. With the
                              optimization of CPChain ecosystem and the increase
                              in data sharing and transferring, the reward for
                              RNodes will mainly be generated by contracts and
                              market transactions.
                            </p>
                            <p>
                              In the first stage, reputation nodes’ entitlements
                              will be allocated to two parts:
                            </p>
                            <p>
                              <b><i>Basic Rewards:</i></b>
                              CPChain will create a reward pool with 5 million
                              CPC annually (1.25 million CPC quarterly, 13,700
                              CPC daily). The RNode and the Economy Node receive
                              the corresponding CPC reward based on the ratio of
                              the locked margin to the total margin. (Economy
                              Node and RNode will both need a 90-day lock-up
                              session). 
                            </p>
                            <p>
                              <b><i>Blockchain maintenance rewards:</i></b
                              ><br />
                              Committee Nodes are entitled to blockchain
                              maintenance rewards. Every block generates fixed
                              rewards. Block rewards will be decreased by 25%
                              every year, and run out in 5 years. There would be
                              40 million CPC in the first year, and following
                              with 30 million, 22.5 million, 17 million and
                              12.75 million for the rest of four years
                              respectively. 
                            </p>
                            <p class="w-100">
                              <b
                                >Any questions about our Rnode Ecosystem can be
                                posted in our upcoming AMA:</b>
                            </p>
                            <a
                              class="w-100 d-block"
                              href="https://www.reddit.com/r/CPChain_Official/comments/9btryw/cpchains_third_ama_rnode_version_official_thread/"
                              style="overflow-wrap: break-word"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.reddit. com/r/ CPChain _Official/
                              comments/ 9btryw/ cpchains_ third_ama_ rnode_
                              version _official _thread/
                            </a>
                            <br />

                            <p>
                              <i
                                >* Around 3,000,000 blocks will be generated
                                annually.</i
                              ><br />
                              <i
                                >** Please note that according to changes in the
                                market environment, for the sake of community
                                interest, CPChain keeps the right to amend the
                                numbers above in the future.</i
                              >
                            </p>
                          </div>
                        </div>
                        <!-- body content -->
                      </div>
                      <!-- blog-view holder  -->
                    </div>
                  </div>
                </section>
              </main>
              <!-- #main -->
            </div>
            <!-- #primary -->
          </div>
          <!-- .wrap -->
        </div>
      </div>
      <!-- #content -->
    </div>
    <!-- .site-content-contain -->
  </div>
  <!-- #page -->
</template>

<script>
export default {
  name: "RNode",
};
</script>

<style scoped>
.site {
  margin-top: 100px;
}
</style>
