<template>
  <section class="section-eight pt-sm-5 mt-sm-5 py-0 my-0">
    <div class="container mt-sm-5 pt-4">
      <div class="row flex-column align-items-center justify-content-center">
        <div class="upper col-xl-5 col-lg-5 col-md-5 col-sm-12">
          <h2
            class="text-lext text-center poppins-font"
            style="font-weight: bold"
            id="roadmap"
          >
            {{ $t("roadmap") }}
          </h2>
          <hr class="middle-hr mb-0" />
        </div>
        <div class="swiper-container-roadmap w-100">
          <div class="">
            <div class="roadmap-web">
              <div
                class="middle-second col-xl-8 col-md-8 col-lg-8 col-sm-12 d-flex flex-row py-5 mx-auto"
              >
                <div
                  class="left-side col-xl-6 col-lg-6 col-md-6 col-2 px-0 border-top-0 border-left-0 border-bottom-0"
                >
                  <div
                    class="left-side-holder d-none d-md-block d-md-block d-lg-block d-xl-block pt-5 mt-5"
                  >
                    <div :style="item.style" class="first text-left pt-5" v-for="item in roadmap_left" v-bind:key="item.time">
                      <h6 class="text-primary">{{ item.time }}:</h6>
                      <hr class="left-hr w-100" :class="{'pulse': item.current, 'now-quarter': item.current}"/>
                      <p style="color: #4671a2; font-family: 'Poppins'" v-html="item.event"></p>
                    </div>
                  </div>
                </div>
                <div class="right-side col-xl-6 col-lg-6 col-md-6 col-10 px-0">
                  <div class="right-side-holder">
                    <div class="first text-right pt-4 pb-0 pb-sm-5 mb-5" v-for="item in roadmap_right" v-bind:key="item.time">
                      <h6 class="text-primary">{{ item.time }}:</h6>
                      <hr class="right-hr w-100" :class="{'pulse': item.current, 'now-quarter': item.current}"/>
                      <p style="color: #4671a2; font-family: 'Poppins'" v-html="item.event"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="roadmap-mob" v-if="is_mobile">
              <div
                class="middle-second col-xl-8 col-md-8 col-lg-8 col-sm-12 d-flex flex-row py-5 mx-auto"
              >
                <div
                  class="left-side col-xl-6 col-lg-6 col-md-6 col-2 px-0 border-top-0 border-left-0 border-bottom-0"
                >
                  <div
                    class="left-side-holder d-none d-md-block d-md-block d-lg-block d-xl-block pt-5 mt-5"
                  ></div>
                </div>
                <div class="right-side col-xl-6 col-lg-6 col-md-6 col-10 px-0">
                  <div class="right-side-holder">
                    <div class="first text-right pt-4 pb-0 pb-sm-5 mb-5" v-for="item in roadmap" v-bind:key="item.time">
                      <h6 class="text-primary">{{ item.time }}:</h6>
                      <hr class="right-hr w-100" :class="{'pulse': item.current, 'now-quarter': item.current}"/>
                      <p style="color: #4671a2; font-family: 'Poppins'" v-html="item.event"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Roadmap",
  data() {
    let is_mobile = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return {
      is_mobile: is_mobile,
      roadmap: [
        {
          'time': '2018 Q2',
          'event': 'Pdash 1.0 Release'
        },
        {
          'time': '2018 Q4',
          'event': 'CPChain Alpha Mainnet launch<br/>CPChain RNode Structure Release'
        },
        {
          'time': '2019 Q2',
          'event': 'CPChain Formal Mainnet Launch'
        },
        {
          'time': '2019 Q4',
          'event': 'CPChain Ecosystem Stabilization<br />Traceability and Authentication related project implementation'
        },
        {
          'time': '2020 Q2',
          'event': 'DID authentication system realization<br />Mass adoption of IoT devices Identification and Authentication'
        },
        {
          'time': '2020 Q4',
          'event': 'Data encryption services Consolidation<br />Homomorphic encryption and Blackbox isolation data processing services relization',
        },
        {
          'time': '2021 Q1',
          'event': 'Upgrade the CPC-Wallet to be decentralized<br /> Design the CPChain DApp Ecosystem and refine the website<br /> Develop the Identity and Message DApp <br /> The first stage of the BSIM system',
        },
        {
          'time': '2021 Q2',
          'event': 'Encrypted Chat Group <br/>PDash on CPChain mainnet<br/>RNode Staking<br/>',
          'current': false,
          'style': {
            'margin-top': '100px'
          }
        },
        {
          'time': '2021 Q3',
          'event': `Refactoring backend: use clickhouse instead of MongoDB <br/>
                    Refactoring frontend with nuxt.js<br/>
                    PDash Token: The liquidity incentive tokens for the PDash community<b>(Beta)</b> <br/>`,
          'style': {
            'margin-top': '0px'
          }
        },
        {
          'time': '2021 Q4',
          'event': `Redpack for Encrypted Chat & Group<br/>
                    Support Ledger Wallet`,
          'current': false,
          'style': {
            'margin-top': '180px'
          }
        },
        {
          'time': '2022 Q1',
          'event': `cpchain-typescript-sdk<br/>
                    cpchain-cli for developers<br/>
                    the Big Data Dashboard of CPChain (Smart Contracts)<br/>
                    Refactoring the Encrypted Chat Group`,
          'current': false,
          'style': {
            'margin-top': '100px'
          }
        }, {
          'time': '2022 Q2',
          'event': `Airdrop contract<br/>
                    RPS Contract (Play to earn)<br/>
                    Event APIs server for dapps`,
          'current': false,
          'style': {
            'margin-top': '100px'
          }
        }, {
          'time': '2022 Q3',
          'event': `Launch Airdrop service<br/>
                    Launch RPS dapp<br/>
                    CPChain Wallet extension for browser`,
          'current': true,
          'style': {
            'margin-top': '10px'
          }
        },
      ]
    };
  },
  computed: {
    roadmap_left() {
      let arr = []
      for(var i in this.roadmap) {
        if(i%2==1) {
          arr.push(this.roadmap[i])
        }
      }
      return arr
    },
    roadmap_right() {
      let arr = []
      for(var i in this.roadmap) {
        if(i%2==0) {
          arr.push(this.roadmap[i])
        }
      }
      return arr
    }
  }
};
</script>
