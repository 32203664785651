<template>
  <v-container class="explorer-page">
    <div
      class="text-center"
      v-if="loading"
      style="margin-top: 100px; margin-bottom: 100px"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="30"
      ></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <search-bar />
    </v-row>
    <v-row v-if="!loading">
      <data-header
        :blockHeight="blockHeight"
        :txs="txs"
        :bps="bps"
        :tps="tps"
        :rnode="rnode"
        :committee="committee"
        :proposer="proposer"
      />
    </v-row>
    <v-row v-if="!loading">
      <chart :chart_data="chart_data" />
    </v-row>
    <auto-data-list :blocks="blocks" :txs="txs_list" v-if="!loading" />
  </v-container>
</template>

<script>
import DataHeader from "./DataHeader.vue";
import SearchBar from "./SearchBar.vue";
import Chart from "./Chart";
import AutoDataList from "./AutoDataList.vue";

const tableItemLimit = 20;

export default {
  components: { SearchBar, DataHeader, Chart, AutoDataList },
  name: "Explorer",
  data() {
    return {
      loading: false,
      timer: null,

      // header
      blockHeight: 0,
      txs: 0,
      bps: 0,
      tps: 0,
      rnode: 0,
      committee: 1,
      proposer: 12,

      // chart data
      chart_data: [],

      // blocks
      blocks: [],

      // txs
      txs_list: [],

      // websocket
      ws: null,
    };
  },
  mounted() {
    this.fetchData();
    setTimeout(() => {
      this.timer = setInterval(this.updateTimeTicker, 1000);
    }, 1000);

    // start websocket
    this.initWebsocket();
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    initWebsocket() {
      const url = process.env.VUE_APP_WS_SERVER + "/ws/explorer/"
      this.ws = new WebSocket(url);
      this.ws.onopen = () => {
        if (this.ws.readyState == WebSocket.OPEN) {
          console.log("->Connected..");
        } else {
          console.log("->Connection open failed");
        }
      };
      this.ws.onmessage = (evt) => {
        var msg = evt.data;
        msg = JSON.parse(msg).message;
        msg = JSON.parse(msg);
        // header
        this.setHeader(msg.header);

        // blocks
        if (this.blocks.length > 0) {
          if (this.blocks[0].id < msg.block.id) {
            this.autoGenerateBlock(msg.block);
          }
        }
        msg.txs.forEach((tx) => {
          this.autoGenerateTx(tx);
        });
      };

      this.ws.onerror = () => {
        console.log("->websocket error");
      };
      this.ws.onclose = () => {
        console.log("->disconnected..");
        setTimeout(() => {
          this.initWebsocket();
        }, 2000);
      };
    },
    setHeader(header) {
      this.blockHeight = header.blockHeight;
      this.txs = header.txs;
      this.bps = header.bps;
      this.tps = header.tps;
      this.rnode = header.rnode;
      this.committee = header.committee;
      this.proposer = header.proposer;
    },
    fetchData() {
      this.loading = true;
      this.$http
        .get("./explorer/api/dashboard/")
        .then((data) => {
          // header
          this.setHeader(data.header);
          // chart
          if (data.chart.length) {
            this.chart_data = JSON.parse(data.chart);
          } else {
            this.chart_data = []
          }
          // blocks
          this.blocks = data.blocks.reverse();
          // txs
          this.txs_list = data.txs.reverse();

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.error(err)
        });
    },
    autoGenerateBlock(block) {
      this.blocks.unshift(block);
      if (this.blocks.length > tableItemLimit) {
        this.blocks.pop();
      }
    },
    autoGenerateTx(tx) {
      this.txs_list.unshift(tx);
      if (this.txs_list.length > tableItemLimit) {
        this.txs_list.pop();
      }
    },
  },
};
</script>

<style scoped>
.explorer-page {
  margin-top: 100px;
  margin-bottom: 10px;
  min-width: 100%;
}
</style>
