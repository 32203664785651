<template>
  <main id="main" role="main">
    <cover />
    <introduction />
    <major-scenarios />
    <solutions />
    <videos />
    <roadmap />
    <ecosystem />
    <download />
    <contact/>
  </main>
</template>

<script>
import Cover from "@/components/Cover";
import Introduction from "../components/Introduction.vue";
import MajorScenarios from "../components/MajorScenarios.vue";
import Solutions from "../components/Solutions.vue";
import Videos from "../components/Videos.vue";
import Roadmap from "../components/Roadmap.vue";
import Ecosystem from "../components/Ecosystem.vue";
import Download from "../components/Download.vue";
import Contact from "../components/Contact.vue";

export default {
  name: "Index",
  components: {
    Cover,
    Introduction,
    MajorScenarios,
    Solutions,
    Videos,
    Roadmap,
    Ecosystem,
    Download,
    Contact
  },
  data() {
    return {};
  },
  watch: {
    $route: {
      handler: function (val) {
        let path = val.path.substring(1);
        if (path) {
          this.go(path);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  mounted() {
    let path = this.$route.path.substring(1);
    if (path) {
      this.go(path);
    }
  },
  methods: {
    go(anchor) {
      setTimeout(function () {
        document.querySelector("#" + anchor).scrollIntoView({
          // behavior: "smooth",
          // block: "start",
          // inline: "start"
        });
      }, 50);
    },
  },
};
</script>

<style type="text/css" scoped>
@media (min-width: 375px) {
  .mediaHidden {
    display: none;
  }
}

@media (min-width: 1024px) {
  /* .mediaHidden{ 
    } */
}

html {
  scroll-behavior: smooth;
}

.main {
  z-index: 10000;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: none; */
}

.mainclose {
  display: none;
}

@media (min-width: 375px) {
  .board {
    display: flex;
    z-index: 100000;
    width: 24.96rem;
    height: 37.76rem;
    border-radius: 2.4rem;
    display: flex;
    flex-direction: column;

    background: -webkit-linear-gradient(
      left top,
      rgba(0, 75, 191, 1),
      rgba(0, 103, 212, 1)
    );
    /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(
      bottom right,
      rgba(0, 75, 191, 1),
      rgba(0, 103, 212, 1)
    );
    /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(
      bottom right,
      rgba(0, 75, 191, 1),
      rgba(0, 103, 212, 1)
    );
    /* Firefox 3.6 - 15 */
    background: linear-gradient(
      to bottom right,
      rgba(0, 75, 191, 1),
      rgba(0, 103, 212, 1)
    );
    /* 标准的语法（必须放在最后） */
  }
  .left {
    margin-left: 1.4rem;
    margin-top: 1.52rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .message {
    width: 16.56rem;
    height: 9.6rem;
    background-image: url("{% static 'img/mainnetwelcome/text.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .learn {
    display: none;
  }

  .right {
    position: absolute;
    width: 21.6rem;
    height: 21.28rem;
    /* background-color: red; */
    margin-top: 11.12rem;
    margin-left: 1.68rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .outside {
    z-index: 200000;
    position: absolute;
    width: 21.28rem;
    height: 21.28rem;
    background-image: url("{% static 'img/mainnetwelcome/outside.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .outsideplay {
    animation: outsidetrans 20s linear 0s infinite;
    -webkit-animation: outsidetrans 20s linear 0s infinite;
    -moz-animation: outsidetrans 20s linear 0s infinite;
    -webkit-animation: outsidetrans 20s linear 0s infinite;
    -o-animation: outsidetrans 20s linear 0s infinite;
  }

  .inside {
    width: 11.56rem;
    height: 11.56rem;
    background-image: url("{% static 'img/mainnetwelcome/inside.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
  }

  .insideplay {
    animation: insidetrans 20s linear 0s infinite;
    -webkit-animation: insidetrans 20s linear 0s infinite;
    -moz-animation: insidetrans 20s linear 0s infinite;
    -webkit-animation: insidetrans 20s linear 0s infinite;
    -o-animation: insidetrans 20s linear 0s infinite;
  }

  .pause {
    animation-play-state: paused;
  }

  .logo {
    width: 0.3.24rem;
    height: 4.44rem;
    background-image: url("{% static 'img/mainnetwelcome/logo.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
  }

  .bubble {
    z-index: 500000;
    border-radius: 50%;
    background-image: url("{% static 'img/mainnetwelcome/bubble.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
  }

  .close {
    z-index: 500000;
    width: 2.04rem;
    height: 2.04rem;
    border-radius: 50%;
    background-image: url("{% static 'img/mainnetwelcome/close.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    margin-top: -21.52rem;
    /* margin-left: 4.70rem; */
  }

  .ball {
    z-index: 500000;
    border-radius: 50%;
    background-image: url("{% static 'img/mainnetwelcome/ball.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
  }

  .bottomLearn {
    width: 7.92rem;
    height: 1.84rem;
    background-image: url("{% static 'img/mainnetwelcome/button.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 22.88rem;
    margin-left: 8.52rem;
  }
}

@media (min-width: 1024px) {
  .board {
    display: flex;
    z-index: 100000;
    width: 990px;
    height: 635px;
    border-radius: 60px;

    display: flex;
    flex-direction: row;

    background: -webkit-linear-gradient(
      left top,
      rgba(0, 75, 191, 1),
      rgba(0, 103, 212, 1)
    );
    /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(
      bottom right,
      rgba(0, 75, 191, 1),
      rgba(0, 103, 212, 1)
    );
    /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(
      bottom right,
      rgba(0, 75, 191, 1),
      rgba(0, 103, 212, 1)
    );
    /* Firefox 3.6 - 15 */
    background: linear-gradient(
      to bottom right,
      rgba(0, 75, 191, 1),
      rgba(0, 103, 212, 1)
    );
    /* 标准的语法（必须放在最后） */
  }

  .left {
    margin-left: 50px;
    margin-top: 78px;
    /* background-color: #00f; */
    width: 414px;
    height: 446px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .message {
    width: 414px;
    height: 240px;
    background-image: url("{% static 'img/mainnetwelcome/text.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .learn {
    width: 198px;
    height: 46px;
    background-image: url("{% static 'img/mainnetwelcome/button.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: inherit;
    cursor: pointer;
  }

  .right {
    position: absolute;
    width: 532px;
    height: 532px;
    /* background-color: red; */
    margin-top: 52px;
    margin-left: 514px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .outside {
    z-index: 200000;
    position: absolute;
    width: 532px;
    height: 532px;
    background-image: url("{% static 'img/mainnetwelcome/outside.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .outsideplay {
    animation: outsidetrans 20s linear 0s infinite;
    -webkit-animation: outsidetrans 20s linear 0s infinite;
    -moz-animation: outsidetrans 20s linear 0s infinite;
    -webkit-animation: outsidetrans 20s linear 0s infinite;
    -o-animation: outsidetrans 20s linear 0s infinite;
  }

  .inside {
    width: 289px;
    height: 289px;
    background-image: url("{% static 'img/mainnetwelcome/inside.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
  }

  .insideplay {
    animation: insidetrans 20s linear 0s infinite;
    -webkit-animation: insidetrans 20s linear 0s infinite;
    -moz-animation: insidetrans 20s linear 0s infinite;
    -webkit-animation: insidetrans 20s linear 0s infinite;
    -o-animation: insidetrans 20s linear 0s infinite;
  }

  .pause {
    animation-play-state: paused;
  }

  .logo {
    width: 81px;
    height: 111px;
    background-image: url("{% static 'img/mainnetwelcome/logo.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
  }

  .bubble {
    z-index: 500000;
    border-radius: 50%;
    background-image: url("{% static 'img/mainnetwelcome/bubble.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
  }

  .close {
    z-index: 500000;
    width: 51px;
    height: 51px;
    border-radius: 26px;
    background-image: url("{% static 'img/mainnetwelcome/close.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    margin-top: -358px;
    margin-left: 470px;
  }

  .ball {
    z-index: 500000;
    border-radius: 50%;
    background-image: url("{% static 'img/mainnetwelcome/ball.png' %}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
  }

  .bottomLearn {
    display: none;
  }
}

@keyframes outsidetrans {
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

@-moz-keyframes outsidetrans {
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

@-o-keyframes outsidetrans {
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

@-webkit-keyframes outsidetrans {
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes insidetrans {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes insidetrans {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-o-keyframes insidetrans {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes insidetrans {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
</style>
