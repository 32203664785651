<template>
  <v-container>
    <div class="text-center" v-if="loading" style="margin-top: 100px; margin-bottom: 100px">
      <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <div class="info-table" style="width: 100%; margin-left: 0px; margin-right: 0px">
        <div class="info-table-list">
          <div class="info-table-list-content">
            <div class="table">
              <div class="row list-header">
                <div class="col text-truncate  col-xl-2 col-lg-2 col-md-2 col-sm-12">Block Number</div>
                <div class="col text-truncate  col-xl-2 col-lg-2 col-md-2 col-sm-12">name</div>
                <div class="col text-truncate  col-xl-4 col-lg-4 col-md-4 col-sm-12">Address</div>
                <div class="col text-truncate  col-xl-2 col-lg-2 col-md-2 col-sm-12">Admin</div>
                <div class="col text-truncate  col-xl-2 col-lg-2 col-md-2 col-sm-12">Joined time</div>
              </div>
              <div v-if="group_members.length == 0" style="
                      color: #aaa;
                      font-size: 0.8rem;
                      width: 100%;
                      text-align: center;
                    ">
                No Members
              </div>
              <div class="row row-content" v-for="group_member in group_members" v-bind:key="group_member.id">
                <div class="col text-truncate col-xl-2 col-lg-2 col-md-2 col-sm-12"> 
                    <span>Block Number:</span><router-link :to="'/explorer/block/' + group_member.block_number" v-cloak>{{ group_member.block_number }}</router-link>
                </div>
                <div class="col text-truncate col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <span>Name:</span>{{ getName(group_member) | nameHandler}}
                </div>
                <div class="col text-truncate col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <span>Address:</span><router-link :to="'/explorer/address/' + group_member.address" v-cloak>{{ group_member.address | addrHandler }}</router-link>
                </div>
                <div class="col text-truncate type-img col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <span>State:</span>
                  <p :class="{
                        'banned': group_member.banned == true,
                        'member': group_member.is_admin == false && group_member.banned == false,
                        'admin': group_member.is_admin == true && group_member.banned == false,
                      }">{{ group_member | TypeofMembers }}</p>
                </div>
                <div class="col text-truncate col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <span>Joined time:</span>{{ convertTime(group_member.created_at) }} ago
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-row style="font-size: 14px; color: #aaa" v-if="count > 0" class="pl-0">
            <v-col lg="5" class="pl-0">
            <span style="padding-left: 0px; opacity: 0.8">Total Members:&nbsp;</span>
            <label>{{ count }}</label>
            </v-col>
            <v-spacer />
        </v-row>
        <pagination style="margin-top: 20px" :page="page" :count="count" :limit="limit" :change="pageChange" v-if="  count > 0" />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { formatTS } from "@/utils/ts";
import Pagination from "../Pagination.vue";
import { is_mobile } from '@/utils'

export default {
  components: { Pagination },
  name: "MemberList",
  props: ['group_id'],
  filters: {
    TypeofMembers(member) {
      if (member.banned == true) {
        return 'BANNED'
      }
      else if (member.is_admin == true) {
        return 'ADMIN'
      }
      else {
        return 'MEMBER'
      }
    },
    addrHandler(address) {
      if (is_mobile) {
        let len = 8
        return address.substr(0, len) + "…" + address.substr(address.length - len, len)
      }
      else {
        let len = 17 
        return address.substr(0, len) + "…" + address.substr(address.length - len, len)
      }
    },
    nameHandler(name) {
      if (name.length > 20) {
        let len = 10
        return name.substr(0, len) + "…" + name.substr(name.length - len, len)
      }
      return name
    }
  },
  data() {
    return {
      loading: false,
      group_members: [],
      page: 1,
      limit: 30,
      count: 0,
      is_mobile: is_mobile
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    page() {
      this.fetchData();
    },
    group_id() {
      this.reload()
    },
  },
  methods: {
    reload() {
      this.page = 1;
      this.count = 0;
      this.fetchData();
    },
    pageChange(page) {
      this.page = page;
    },

    fetchData() {
      this.loading = true;
      let params = {
        offset: (this.page - 1) * this.limit,
        limit: this.limit,
        group_id: this.group_id,
      };

      this.$http
        .get(`/groupchats/members/`, { params: params })
        .then((data) => {
          this.group_members = data.results;
          this.count = data.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getName(data) {
      if (data.alisa_name) {
        return data.alisa_name
      }
      else if (data.identity.name) {
        return data.identity.name
      }
      else {
        return data.address
      }
    },

    convertTime: function (time) {
      let tss = new Date(time).getTime() / 1000
      var cts = parseInt((Date.now() + 500) / 1000);
      return formatTS(cts - tss);
    },
  },
};
</script>

<style scoped>
.container {
  padding-left: 0 !important;
  /* width: 110%; */
}
.row {
  padding-left: 15px !important;
}
.admin {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #96DBFF;
  color: #138EED;
  margin-top: 12px;
}
.special-type {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #ffeecf;
  color: #e77e1b;
  margin-top: 15px;
}
.member {
  display: block;
  line-height: 26px;
  width: 80px;
  height: 26px;
  text-align: center;
  border-radius: 4px;
  background: #d9f7be;
  color: #5b8c00;
  margin-top: 12px;
}
.staking-type {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.type-img { 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.v-icon {
  font-size: 14px;
  line-height: 18px;
}
.v-icon:hover {
  color:#138EED;
}

</style>
