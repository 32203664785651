<template>
  <v-container class="tx-info-page">
    <search-bar />
    <div
      class="text-center"
      v-if="loading"
      style="margin-top: 100px; margin-bottom: 100px"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="30"
      ></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <v-col lg="2" />
      <v-col lg="8">
        <v-row>
          <v-col lg="12">
            <div class="page-header">
              <div class="page-header-main">
                <p class="text-center poppins-font">Transaction Information</p>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 30px">
          <v-col lg="12">
            <v-row>
              <v-col lg="12">
                <img
                  :src="require('@/assets/img/explorer_tx_info.png')"
                  alt=""
                  style="margin-top: -5px; margin-right: 10px"
                />
                <span
                  class="info-id"
                  style="font-size: 1.2rem; font-weight: 500; margin-bottom: 4px; margin-left: 5px;"
                  >{{ tx.hash.value }}</span
                >
              </v-col>
              <v-spacer />
            </v-row>
            <v-row class="poppins-light" style="margin-top: 30px">
              <v-col lg="12">
                <v-row>
                  <v-col lg="2" class="field">{{ tx["hash"].name }}</v-col>
                  <v-col> {{ tx["hash"].value }}</v-col>
                </v-row>
                <v-row>
                  <v-col lg="2" class="field">{{ tx["status"].name }}</v-col>
                  <v-col>
                    <span
                      v-if="tx['status'].value == 1"
                      class="rc-success"
                      >Success</span
                    >
                    <span v-else class="rc-fail" style="margin-top: 5px"
                      >Fail</span
                    >
                  </v-col>
                </v-row>
                <v-row v-for="f in fields_orderer" :key="f">
                  <v-col lg="2" class="field">{{ tx[f].name }}</v-col>
                  <v-col lg="10" class="value">
                    <router-link
                      :to="
                        typeof tx[f].to == 'function' ? tx[f].to(tx) : tx[f].to
                      "
                      v-if="tx[f].to"
                      >{{ handle(f) }}</router-link
                    >
                    <span v-if="!tx[f].to">{{ handle(f) }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="12">
                    <hr />
                  </v-col>
                </v-row>
                <!-- input data -->
                <v-row>
                  <v-col lg="2" class="field">{{ tx["input"].name }}</v-col>
                  <v-col lg="10" class="value">
                    <textarea
                      class="raw-data-content"
                      style="
                        height: 222px;
                        padding: 20px 30px;
                        width: 100%;
                        background: #f7f9fc;
                        border-radius: 2px;
                        font-size: 14px;
                        word-wrap: break-word;
                        position: relative;
                        font-family: Monospace;
                      "
                      v-html="input"
                    >
                    </textarea>
                    <div class="raw-data-type-form">
                      <div class="form-check-inline form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                          checked
                          @click="toggleDataType(0)"
                        />
                        <label class="form-check-label" for="inlineRadio1"
                          >Default View</label
                        >
                      </div>
                      <div class="form-check-inline form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="option1"
                          @click="toggleDataType(1)"
                        />
                        <label class="form-check-label" for="inlineRadio2"
                          >UTF-8 View</label
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="2" />
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from "./SearchBar.vue";
import { convertTime, convertDate } from "@/utils/ts";

export default {
  components: { SearchBar },
  props: ["hash"],
  name: "TxInfo",
  data() {
    return {
      input: "",
      loading: false,
      fields_orderer: [
        "blockNumber",
        "timestamp",
        "from",
        "to",
        "value",
        "gasLimit",
        "gasUsed",
        "gasPrice",
        "txfee",
      ],
      tx: {
        hash: {
          name: "TxHash:",
          value: "",
        },
        status: {
          name: "TxReceipt Status:",
          value: "",
        },
        blockNumber: {
          name: "Block Height:",
          value: 0,
          to(tx) {
            return `/explorer/block/${tx.blockNumber.value}`;
          },
        },
        timestamp: {
          name: "Time:",
          value: 0,
          handler(block, value) {
            return convertDate(value);
          },
        },
        from: {
          name: "From:",
          value: "",
          to(tx) {
            return "/explorer/address/"+tx.from.value
          }
        },
        to: {
          name: "To:",
          value: "",
          to(tx) {
            return "/explorer/address/"+tx.to.value
          }
        },
        value: {
          name: "Value:",
          value: 0,
          handler(tx) {
            return tx.value.value + " CPC";
          },
        },
        gasLimit: {
          name: "Gas Limit:",
          value: 0,
        },
        gasUsed: {
          name: "Gas Used:",
          value: 0,
        },
        gasPrice: {
          name: "Gas Price:",
          value: 0,
        },
        txfee: {
          name: "Actual Tx Cost/Fee:",
          value: 0,
        },
        input: {
          name: "Input Data:",
          value: "",
        },
        input_data: {
          value: "",
        },
      },
    };
  },
  watch: {
    hash() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http
        .get(`/explorer/api/txs/${this.hash}/`)
        .then((data) => {
          for (let k in data) {
            if (this.tx[k]) {
              this.tx[k].value = data[k];
            }
          }
          this.loading = false;
          this.toggleDataType(0);
        })
        .catch((err) => {
          this.loading = false;
          if(err.response.status == 404) {
            this.$router.push(`/search404`)
          }
        });
    },
    convertTime(ts) {
      return convertTime(ts);
    },
    handle(f) {
      return this.tx[f].handler
        ? this.tx[f].handler(this.tx, this.tx[f].value)
        : this.tx[f].value;
    },
    toggleDataType(type) {
      /**
       * Test transaction: https://cpchain.io/explorer/tx/0xc54e765fbfa03acb3953ce91e577cd478bf70664fd043de4234da91887db41b4
       */
      if (type == 0) {
        this.$data.input = this.tx["input"].value;
      } else {
        this.$data.input = this.tx["input_data"].value;
      }
    },
  },
};
</script>

<style scoped>
.tx-info-page {
  margin-top: 100px;
  margin-bottom: 50px;
  min-width: 100%;
  font-family: "Poppins", sans-serif !important;
}

.info-id {
  font-size: 1.5rem;
}

.field {
  font-size: 18px;
  font-weight: 600;
}

.value {
  font-size: 18px;
}

.rc-success {
  border-radius: 2px;
  padding: 5px 8px;
  background: #e0f0d0;
  color: #70b820;
}

.rc-fail {
  border-radius: 2px;
  padding: 5px 8px;
  background: #fbe7e5;
  color: #d50101;
}
</style>
