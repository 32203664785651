<template>
  <div></div>
</template>

<script>
import {
  Transformation, Moving,
  TextShining, Lighting, SpreadTrack, RandomTrack
} from 'floating-element'
export default {
  created() {
    var lights = [];
    var texts = []
    var rectangles = []

    for (let i = 0; i < 10; i++) {
      var light = new Transformation()
        .setAnimation(new Moving().setTrack(new SpreadTrack()))
        .setAnimation(new Lighting())
      lights.push(light);//生成的小球对象放进数组 
    }

    for (let i = 0; i < 10; i++) {
      var text = new Transformation()
        .setAnimation(new Moving().setTrack(new RandomTrack({ x: 0, y: 0 })))
        .setAnimation(new TextShining())
        .appendChild('1010')
      texts.push(text);//生成的小球对象放进数组 
    }

    for (let i = 0; i < 6; i++) {
      var rectangle = new Transformation()
        .initCssText('background:rgba(255,255,255,0.3);height:2px;width:350px;')
        .setAnimation(new Moving().setTrack(new SpreadTrack()))
      rectangles.push(rectangle);//生成的小球对象放进数组

    }
    function start() {
      for (let i = 0; i < lights.length; i++) {
        lights[i].draw();
      }

      for (let i = 0; i < texts.length; i++) {
        texts[i].draw();
      }

      for (let i = 0; i < rectangles.length; i++) {
        rectangles[i].draw();
      }
      window.requestAnimationFrame(start)
    }
    start()
  },
}
</script>

<style  scoped>
body {
  background-color: #000;
}
</style>>
 