<template>
  <div class="news-detail">
    <div class="container h-100">
      <div class="text-center" v-if="loading" style="margin-top: 100px; margin-bottom: 100px;">
        <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
      </div>
      <div class="row" v-if="!loading">
        <div class="news-view-holder" style="width: 100%">
          <div class="upper col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 mx-auto">
            <h4 class="my-3">
              <a href="javascript:void(0)">
                {{ item.title }}
              </a>
            </h4>
            <h6 class="mb-5 text-muted">
              by CPChain at {{ item.update_time }}
            </h6>
          </div>
          <div
            class="bottom py-5 mx-auto col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 d-flex justify-content-center flex-column"
            style="margin-top: -50px"
          >
            <div class="image-holder-main">
              <img
                :src="item.banner"
                class="d-block w-100 h-100 img-fluid disable-select wp-post-image"
                :alt="item.title"
                sizes="100vw"
                loading="lazy"
              />
            </div>
            <div v-html="item.content" style="margin-top: 36px" class="medium-editor"/>
            
            <!-- 推特分享 -->
            <twitter-share :text="shared_text" :url="shared_url"/>

            <!-- 媒体列表 -->
            <media-list />
          </div>

          <div class="line-holder col-sm-10 col-12 mx-auto">
            <hr />
          </div>
        </div>
        <!-- blog-view holder  -->
      </div>
    </div>
    <div class="col-sm-10 col-12 mx-auto" style="margin-top: -100px" v-if="!loading">
        <category
          name="Latest News"
          :category="item.category"
          :show_header="false"
        />
      </div>
  </div>
</template>

<script>
import Category from "./Category";
import MediaList from "./MediaList.vue";
import TwitterShare from './TwitterShare.vue';

import "@/styles/medium-editor.css"

export default {
  components: { Category, MediaList, TwitterShare },
  name: "NewsDetail",
  props: ["id"],
  data() {
    return {
      loading: false,
      item: {
        category: "",
      },
    };
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  mounted() {
    document.title = "News Detail - CPChain"
    this.fetchData();
  },
  computed: {
    shared_url() {
      return window.location.href
    },
    shared_text() {
      let summary = this.item.summary
      if (summary==null) {
        summary = ""
      }
      return `${this.item.title}: ${summary}`
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$http.get(`/news/news/${this.id}/`).then((data) => {
        this.loading = false
        this.item = data;
        document.title = this.item.title + " - CPChain"
      }).catch(() => {
        this.loading = false
      })
    },
  },
};
</script>

<style scoped>
.news-detail {
  margin-top: 150px;
}
</style>
