<template>
    <section class="section-two py-sm-5 my-sm-5 py-5 my-0" id="cpchain">
        <div class="container">
            <div class="row align-items-center justify-content-center">

                <div class="first col-sm-12 col-md-6 py-5 py-sm-0">
                    <div class="col-12 col-sm-11 poppins-font" style="padding-right: 10px;">
                        <h2 class="display-5 poppins-font mb-7"> {{ $t("homepage.introduction") }} </h2>
                        <hr class="right-hr w-100 mb-4">
                        <p style="color: #305278;font-family: 'Poppins', sans-serif !important;line-height: 1.9">
                            CPChain is a new distributed infrastructure for next generation IoT. CPChain intends to build a fundamental data platform for IoT systems in combination with distributed storage, encryption computation and blockchain technologies, providing the whole process solution from data acquisition, storage, sharing to application.
                        </p>
                        <p style="color: #305278;font-family: 'Poppins', sans-serif !important;line-height: 1.9">
                            CPChain is a promising solution to a series of challenges of the current "chimney architecture" of IoT systems, reducing connectivity cost of devices, protecting data privacy and maximizing the value of IoT data.
                        </p>

                    </div>
                </div>
                <div class="second col-sm-12 col-md-6">
                    <div class="image-holder">
                        <img src="@/assets/img/integration.png"
                                class="w-100 d-block disable-select"
                                alt="Integration of IoT & Blockchain - CPChain">
                    </div>
                </div>

            </div>
        </div>
    </section> <!-- section-two -->
</template>

<script>
export default {
    name: 'Introduction',
    data() {
        return {}
    }
}
</script>
