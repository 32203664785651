<template>
  <v-container class="group-page">
    <search-bar />
    <v-row>
      <v-col cols="0" lg="3" xl="3" />
      <v-col cols="12" lg="6" xl="6">
        <v-row >
          <v-col lg="12">
            <div class="page-header">
              <div class="page-header-main">
                <p class="text-center poppins-font">
                    {{ group_info.name }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12">
            <div class="text-center" v-if="loading" style="margin-top: 100px; margin-bottom: 100px">
              <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
            </div>
          </v-col>
        </v-row>
        <v-row class="body">
          <v-col cols="12" lg="12">
            <v-row v-if="!loading">
              <v-col lg="3" class="overview"> Overview </v-col>
              <v-spacer />
            </v-row>
            <v-row v-if="!loading">
              <v-col lg="3" class="left">Creator:</v-col>
              <v-col lg="9" class="right">
                <router-link :to="'/explorer/address/' + group_info.owner">{{
                  group_info.owner | addrHandler
                }}</router-link>
              </v-col>
            </v-row>
            <v-row v-if="!loading">
              <v-col lg="3" class="left">Description:</v-col>
              <v-col lg="8" class="right">{{ JSON.parse(group_info.extend).description }}</v-col>
            </v-row>
            <v-row v-if="!loading">
              <v-col lg="3" class="left">Message Count:</v-col>
              <v-col lg="8" class="right">{{ message_count }}</v-col>
            </v-row>
            <v-row v-if="!loading">
              <v-col lg="3" class="left">Created time:</v-col>
              <v-col lg="8" class="right">{{ convertTime(group_info.created_at) }}ago</v-col>
            </v-row>
            <v-row>
              <v-col lg="12" md="12">
                <v-tabs v-model="tab" style="margin-top:60px;margin-bottom:40px;width:300px">
                  <v-tab>Members List</v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-tabs-items v-model="tab">
              <v-tab-item style="margin-left:-14px!important">
                <v-row>
                  <v-col lg="12" style="padding: 0px;">
                    <member-list :group_id="this.group_id"></member-list>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>



<script>
import SearchBar from "../SearchBar.vue";
import { is_mobile } from '@/utils'
import { formatTS } from "@/utils/ts";
import MemberList from '@/components/explorer/MemberList.vue'

export default {
  components: { SearchBar, MemberList },
  name: "GroupInfo",
  props: ["group_id"],
  data() {
    return {
      tab: null,
      is_mobile: is_mobile,
      loading: false,
      group_info: [],
      group_member: [],
      message_count: 0,
    }
  },
  computed: {

  },
  watch: {
    group_id() {
      this.fetchData()   
    }
  },
  filters: {
    addrHandler(address) {
      if (is_mobile) {
        let len = 8
        return address.substr(0, len) + "…" + address.substr(address.length - len, len)
      }
      return address
    }
  },
  created() {

  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$http.get(`/groupchats/group/${this.group_id}/`).then(result => {
        this.group_info = result
      })
      this.$http.get(`/groupchats/message/?group_id=${this.group_id}`).then(data => {
        this.message_count = data.count
        this.loading = false
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },
    convertTime: function (time) {
      let tss = new Date(time).getTime() / 1000
      var cts = parseInt((Date.now() + 500) / 1000);
      return formatTS(cts - tss);
    },
  }
}
</script>

<style scoped>

* {
  text-transform: none !important;
}

.v-tab {
  padding-left: 0;
  /* padding-right: 20px; */
  /* margin-right: 10px; */
  padding-bottom: 5px;
  font-size: 24px;
  font-weight: bold;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0 !important;
}
.group-page {
  margin-top: 100px;
  margin-bottom: 50px;
  min-width: 100%;
  font-family: "Poppins", sans-serif !important;
}

.body {
  padding-left: 0px;
}

.body .address {
  font-size: 18px;
}

.body .address .op {
  margin-left: 5px;
  padding: 3px 7px;
  border-radius: 50%;
  box-shadow: 0 0 4px #eee;
  cursor: pointer;
}

.body .address .op:hover {
  background-color: #999;
}

.body .address .v-icon {
  font-size: 14px;
  line-height: 18px;
}

.overview {
  font-size: 25px;
  font-weight: bold;
  margin-top: 54px;
}

.locked-balance-hint {
  color: #cecece;
  font-size: 0.8em;
  font-style: italic;
}

.left {
  margin-top: 13px;
  font-size: 17px;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}

.right {
  margin-top: 13px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 18px;
  font-weight: 400;
}

.txs-header {
  border-bottom: solid 2px #305278;
  padding-bottom: 5px;
  width: 226px;
  font-size: 25px;
  font-weight: bold;
  margin: 36px 0px 15px;
}
</style>
