<template>
  <section class="py-5 my-sm-5 my-0">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center" v-if="show_header">
        <div class="left-side col-6 text-left">
          <h6 class="mb-0 poppins-medium" style="font-size: 18px">
            {{ title }}
          </h6>
        </div>
        <div class="right-side col-6 text-right">
          <router-link :to="'/news/list?title='+title" class="text-primary">{{ $t("see_all") }}</router-link>
        </div>
      </div>
      <div class="col-12 mb-4 px-0" v-if="show_header">
        <hr class="right-hr" />
      </div>

      <div class="text-center" v-if="loading" style="margin-top: 100px; margin-bottom: 100px;">
        <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
      </div>

      <div class="row" v-if="!loading">
        <news-card v-for="item in data" v-bind:key="item.title" :news="item" />
      </div>
    </div>
  </section>
</template>

<script>
import NewsCard from './NewsCard.vue';

export default {
  name: "Category",
  components: { NewsCard },
  props: {
    name: {
      type: String
    },
    category: {
      type: String
    },
    show_header: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      title: this.name,
      type: this.category,
      data: [],
    };
  },
  created() {
    this.loading = true
    // offcial announcements
    this.fetchData(this.type, 3, 0).then((res) => {
      this.data = res.results;
      this.loading = false
    }).catch(() => {
      this.loading = false
    })
  },
  methods: {
    fetchData(category, limit, offset) {
      return this.$http.get(
        `/news/news/?limit=${limit}&offset=${offset}&category=${category}`
      );
    },
  },
};
</script>
