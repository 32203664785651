<template>
  <div class="card-body">
    <v-row>
      <v-col :cols="7" class="table-item-icon">
        <p class="text-left">
          <img
            :src="require('@/assets/img/explorer_list_block.png')"
            alt=""
          /><span
            v-if="block.impeach"
            style="
              background: #eeeeee;
              color: #bbbbbb;
              padding: 4px;
              margin-left: 4px;
              border-radius: 2px;
              font-size: 10px;
            "
            >Impeach</span
          >
        </p>
      </v-col>
      <v-col :cols="5" class="table-item-sub">
        <p class="text-right text-truncate">
          Block Reward: {{ block.reward }} CPC
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="8" class="table-item-id">
        <p class="text-left text-truncate2">
          <router-link :to="'/explorer/block/' + block.hash" id="block_id"
            >#{{ block.id }}</router-link>
        </p>
      </v-col>
      <v-col :cols="4" class="table-item-bonus">
        <p class="text-right text-truncate card-link">
          <router-link :to="'/explorer/block_txs/' + block.hash" v-if="block.txs > 1"
            >{{ block.txs }} Transactions</router-link>
          <router-link :to="'/explorer/block_txs/' + block.hash" href="javascript:void(0)" v-else
            >{{ block.txs }} Transaction</router-link>
          <img :src="require('@/assets/img/explorer_txn.png')" alt="" />
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="8" class="table-item-desc">
        <p class="text-left text-truncate card-link" style="color: #1ba3ff">
          <span style="color: #305278">Produced by &nbsp;</span>
          <router-link
            v-if="block.impeach"
            :to="'/explorer/address/' + block.impeachProposer"
            :title="block.impeachProposer"
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #bbbbbb;
            "
            >{{ block.impeachProposer }}</router-link>
          <router-link
            v-else
            :to="'/explorer/address/' + block.producerID"
            :title="block.producerID"
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
            >{{ block.producerID }}</router-link>
        </p>
      </v-col>
      <v-col :cols="4" class="table-item-time">
        <p class="text-right text-truncate card-grey">
          {{ block.timeTicker }} ago
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AutoBlockCard",
  props: {
    block: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped>
.row {
  margin: 0px;
}

.card-body {
  margin-bottom: -10px;
  min-height: 105px;
}

.card-body .row {
  margin-top: -5px;
  margin-bottom: -10px;
}

.card-body .col {
  padding: 0%;
}
</style>
