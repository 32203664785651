<template>
  <section
    class="section-one d-flex align-items-center justify-content-center position-relative"
  >
    <div class="container h-100">
      <div
        class="row mt-5 position-relative text-center h-100 flex-column align-items-center justify-content-end justify-content-md-center"
      >
        <div class="text-center">
          <h1 class="col-10 col-sm-12 poppins-light p-0 mx-auto text-uppercase">
            {{ $t("homepage.title") }}
          </h1>
          <p
            class="col-10 col-sm-12 mt-4 mt-sm-0 px-0 mx-auto .poppins-light-italic"
          >
            {{ $t("homepage.subTitle") }}
          </p>
        </div>
        <div class="pt-5 mt-3 main-buttons">
          <a
            :href="'https://api.cpchain.io/static/'+this.$t('white_paper_file')"
            class="btn btn-sm btn-light rounded-circle font-weight-bold bg-white mr-0 mr-sm-2 mb-sm-0 mb-3 px-3"
            target="_blank"
            style="font-family: 'Poppins', sans-serif !important"
          >
            {{ $t("homepage.white_paper_2") }}
          </a>
          <!-- 此 Discord 邀请链接为此按钮专属链接 -->
          <!-- <a
            href="https://discord.gg/s8yg737nN4"
            @click="trackClickMedia('discord')"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-sm btn-light rounded-circle font-weight-bold bg-white ml-0 mr-0 mr-sm-2 ml-sm-2 mb-3 mb-sm-0"
            :class="{'please-click': selectedMedia === 'discord'}"
            style="font-family: 'Poppins', sans-serif !important"
          >
            <i class="fab fa-discord glyphicon pr-2"></i>
            <span>Discord</span>
          </a> -->
          <a
            href="https://twitter.com/cpchain_io"
            @click="trackClickMedia('twitter')"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-sm btn-light rounded-circle font-weight-bold bg-white ml-0 mr-0 mr-sm-2 ml-sm-2 mb-3 mb-sm-0"
            :class="{'please-click': selectedMedia === 'twitter'}"
            style="font-family: 'Poppins', sans-serif !important"
          >
            <i class="fab fa-twitter glyphicon pr-2"></i>
            <span>Twitter</span>
          </a>
          <a
            href="https://t.me/cpchain_official"
            @click="trackClickMedia('telegram')"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-sm btn-light rounded-circle font-weight-bold bg-white ml-0 mr-0 mr-sm-2 ml-sm-2 mb-3 mb-sm-0"
            :class="{'please-click': selectedMedia === 'telegram'}"
            style="font-family: 'Poppins', sans-serif !important"
          >
            <i class="fab fa-telegram glyphicon pr-2"></i>
            <span>Telegram</span>
          </a>
          <a
            href="https://github.com/CPChain"
            @click="trackClickMedia('github')"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-sm btn-light rounded-circle font-weight-bold bg-white ml-0 mr-0 mr-sm-2 ml-sm-2 mb-3 mb-sm-0"
            style="font-family: 'Poppins', sans-serif !important"
          >
            <i class="fab fa-github glyphicon pr-2"></i>
            GitHub
          </a>
          <!-- <router-link to="/rnode" class="btn btn-sm btn-light rounded-circle font-weight-bold bg-white ml-0 ml-sm-2 poppins-font"
            style="font-family: 'Poppins', sans-serif !important">{{ $t("homepage.rnode") }}</router-link> -->
        </div>
      </div>
      <div class="rounded-wrapper position-absolute">
        <div class="rounded-holder position-relative">
          <div
            class="scroll-downs d-sm-flex d-none align-items-center justify-content-center"
          >
            <a href="#cpchain">
              <div class="mousey">
                <div class="scroller"></div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="opacity-holder position-absolute w-100"></div>
    <!-- {% if not is_mobile %} -->
    <div class="video-holder position-absolute w-100 h-100">
      <video
        autoplay=""
        loop
        id="video-background"
        muted
        plays-inline
        class="w-100 video-height"
      >
        <source
          src="https://api.cpchain.io/static/img/video/final.mp4"
          type="video/mp4"
          class="w-100 h-100"
        />
      </video>
    </div>
    <!-- {% endif %} -->
  </section>
  <!-- section-one -->
</template>

<script>
import { is_mobile } from "@/utils";

const SOCIAL_MEDIA = [
  'discord',
  'twitter',
  'telegram'
]

const SOCIAL_MEDIA_KEY = 'SOCIAL_MEDIA_KEY'

export default {
    name: 'Cover',
    data() {
        return {
          is_mobile: is_mobile,
          social_medias: SOCIAL_MEDIA,
          selectedMedia: null
        }
    },
    mounted() {
      if (!localStorage.getItem(SOCIAL_MEDIA_KEY)) {
        this.storeMedias()
      } else {
        this.social_medias = localStorage.getItem(SOCIAL_MEDIA_KEY).split(',')
      }
      this.reSelect()
    },
    methods: {
      storeMedias() {
        if (!this.social_medias || !this.social_medias.length) {
          localStorage.setItem(SOCIAL_MEDIA_KEY, 'empty')
          return
        }
        localStorage.setItem(SOCIAL_MEDIA_KEY, this.social_medias)
      },
      reSelect() {
        this.selectedMedia = this.randomSelectNeedCicked()
      },
      randomSelectNeedCicked() {
        if (!this.social_medias.length) {
          return null
        }
        const randInt = Math.floor(Math.random() * 10 % this.social_medias.length);
        return this.social_medias[randInt]
      },
      trackClickMedia(name) {
        // new 
        let arr = []
        for (let i in this.social_medias) {
          if (this.social_medias[i] !== name) {
            arr.push(this.social_medias[i])
          }
        }
        this.social_medias = arr
        this.storeMedias()
        this.reSelect()
        // 点击首页 cover 社交媒体事件
        this.$gtag.event(`click-cover-social-media-${name}`, {
          'event_category': 'click-social-media',
          'event_label': 'social-media'
        })
      }
    }
}
</script>

<style scoped>

.please-click {
  animation: please-click-here 1s infinite;
  -webkit-animation: please-click-here 1s infinite;
}

.please-click span::before{
  content: " ";
  border: 5px solid rgb(236, 69, 69);
  border-radius:5px;
  position: absolute;
  z-index: 1000;
  right: 0%;
  margin-right: 15px;
  margin-top: -2px;
  animation: please-click-here 1s infinite;
  -webkit-animation: please-click-here 1s infinite;
}

@keyframes please-click-here {
  from {
    transform: scale(90%);
    -webkit-transform: scale(90%);
    filter: brightness(1);
  }
  25% {
    transform: scale(100%);
    -webkit-transform: scale(100%);
  }
  50% {
    transform: scale(110%);
    -webkit-transform: scale(110%);
    filter: brightness(1.3);
  }
  75% {
    transform: scale(100%);
    -webkit-transform: scale(100%);
  }
  to {
    transform: scale(90%);
    -webkit-transform: scale(90%);
    filter: brightness(1);
  }
}

</style>
