<template>
  <section class="section-thirteen pt-5" id="downloadApp">
    <div>
      <div id="mob" class="mob">
        <div class="mobleft">
          <span class="cpcwallet">CPC WALLET</span>
          <div>
            <div class="split"></div>
            <span class="info">Transfer CPC Anytime Anywhere</span>
            <div class="split"></div>
          </div>
        </div>
        <div class="mobright">
          <a :href="DOWNLOAD_URL" id="btnwarp" class="btnwarp">
            <span class="btntext">DOWNLOAD</span>
          </a>
        </div>
      </div>
      <div id="website" class="website">
        <div class="webleft">
          <span class="cpcwallet">CPC WALLET</span>
          <div class="group">
            <div class="split"></div>
            <span class="info">Transfer CPC Anytime Anywhere</span>
            <div class="split"></div>
          </div>
          <div class="qr">
            <img class="qrimg" src="@/assets/img/qr.png" alt="" srcset="" />
            <span class="qrtext"> {{ $t("homepage.download") }} </span>
          </div>
          <div v-if="isChrome()" style="background-color: white; border-radius: 16px; box-shadow: 5px 5px 10px #aaa; padding: 8px; margin-top: 8px">
            <div><a target="_blank" href="https://chrome.google.com/webstore/detail/cpchain-wallet/oplmecahjmhcabighioajmmpnpkdllja">Install from Chrome Web Store ></a></div>
          </div>
        </div>
        <div class="webright"></div>
      </div>
    </div>
  </section>
  <!-- section-thirteen -->
</template>

<script>

export const isChrome = () => {
  const isChromium = window.chrome
  const winNav = window.navigator
  const vendorName = winNav.vendor
  const isOpera = typeof window.opr !== 'undefined'
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1
  const isIOSChrome = winNav.userAgent.match('CriOS')

  return (
    isIOSChrome ||
    (isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false)
  )
}

export default {
  name: "Download",
  data() {
      return {
          DOWNLOAD_URL: process.env.VUE_APP_BASE_API + "/wallet/download_app"
      }
  },
  methods: {
    isChrome() {
      return isChrome()
    }
  }
};
</script>

<style scoped>
/* 手机端 */
@media (min-width: 125px) {
  .roadmap-web {
    display: none;
  }

  .roadmap-mob {
    display: block;
  }

  .videoContainer {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, auto) !important;
  }

  .website {
    display: none;
  }

  .mob {
    width: 100%;
    height: 12.24rem;
    /* background-image:  url("/static/img/mainnetwelcome/bubble.png");    */
    background-image: url("/static/img/wallet/bj.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2.64rem;
    padding-right: 2.16rem;
  }

  .mobleft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3.12rem 0;
  }

  .cpcwallet {
    width: 8.88rem;
    height: 2rem;
    font-size: 1.44rem;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: rgba(48, 82, 120, 1);
    line-height: 2.16rem;
  }

  .info {
    width: 15.84rem;
    height: 1.36rem;
    font-size: 0.96rem;
    font-family: "Poppins-Regular";
    font-weight: 400;
    color: rgba(48, 82, 120, 1);
    line-height: 1.44rem;
    margin-top: 0.24rem;
    margin-bottom: 0.32rem;
  }

  .split {
    width: 1.68rem;
    height: 2px;
    background-color: #305278;
  }

  .btnwarp {
    width: 9.28rem;
    height: 2.4rem;
    border-radius: 1.6rem;
    background-image: url("/static/img/wallet/button.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  .btntext {
    /* width: 5.68rem; */
    /* height: 17px; */
    font-size: 0.96rem;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: rgba(48, 82, 120, 1);
    /* line-height: 18px; */
  }

  .mobright {
    padding-top: 2.71rem;
    padding-left: 1.36rem;
  }
}

/* 电脑端 */
@media (min-width: 1024px) {
  .roadmap-web {
    display: block;
  }

  .roadmap-mob {
    display: none;
  }

  .videoContainer {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, auto) !important;
  }

  .mob {
    display: none;
  }

  .website {
    display: block;
    width: 100%;
    height: 540px;
    background-image: url("../assets/img/beijing1.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .webleft {
    margin-right: 228px;
    margin-top: -7px;
    height: 322px;
    /* background-color: brown; */
    width: 221px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .webright {
    width: 319px;
    height: 430px;
    background-image: url("../assets/img/iPhone.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .cpcwallet {
    width: 221px;
    height: 51px;
    font-size: 36px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: rgba(48, 82, 120, 1);
    line-height: 55px;
  }

  .group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info {
    width: 198px;
    /* height: 34px; */
    font-size: 12px;
    font-family: "Poppins-Regular";
    font-weight: 400;
    color: rgba(48, 82, 120, 1);
    line-height: 18px;
  }

  .split {
    width: 40px;
    height: 2px;
    background-color: #305278;
  }

  .qr {
    width: 108px;
    height: 159px;
    background: rgba(255, 255, 255, 1);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* padding: 12px 0 25px 0; */
  }

  .qrimg {
    width: 86px;
    height: 85px;
    margin-top: 12px;
  }

  .qrtext {
    margin-bottom: 25px;
    /* width: 65px;
                    height: 16px; */
    font-size: 11px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: rgba(48, 82, 120, 1);
    line-height: 17px;
  }
}
</style>
