<template>
  <v-container class="blocks-page">
    <search-bar />
    <v-row>
      <v-col lg="2" />
      <v-col lg="8">
        <v-row>
          <div class="page-header" style="width: 100%; font-size: 36px">
            <p class="text-center poppins-font">Blocks</p>
          </div>
        </v-row>
        <v-row>
          <blocks />
        </v-row>
      </v-col>
      <v-col lg="2" />
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from "./SearchBar.vue";
import Blocks from '../../components/explorer/Blocks.vue';

export default {
  components: { SearchBar, Blocks },
  name: "BlocksList"
};
</script>

<style scoped>
.blocks-page {
  margin-top: 100px;
  margin-bottom: 10px;
  min-width: 100%;
}
</style>
