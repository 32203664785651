<template>
  <div class="swiper-container-applications w-100">
    <div class="swiper-wrapper">
      <div class="swiper-slide row p-0 m-0 application-slide-content">
        <div class="col-sm-4 col-12 col-12">
          <div class="image-holder-app">
            <img class="w-100" :src="s_background.img" />
          </div>
        </div>
        <!-- end of image -->
        <div class="col-sm col-12">
          <h5 class="font-italic">Background</h5>
          <p
            class="font-weight-light text-justify"
            v-html="s_background.content"
          ></p>
        </div>
        <!-- end of desc -->

        <div class="col-12 mt-2 py-5">
          <h5 class="font-italic mb-0">Solution</h5>
          <br />
          <p
            class="font-weight-light text-justify"
            v-html="s_solution.content"
          ></p>
        </div>
        <!-- end of solutions -->

        <v-container>
          <v-row>
            <v-col v-for="f in s_features" :key="f.value" cols="12" lg="lg">
              <v-card>
                <v-card-title><img width="35px" height="35px" :src="f.img" /></v-card-title>
                <v-card-text>{{ f.value }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!-- end of solution-icons -->
      </div>
      <!-- end of swiper 1 -->
    </div>
    <!-- swiper-wrapper -->
  </div>
</template>

<script>
export default {
  name: "Solution",
  props: ["name", "background", "solution", "features"],
  data() {
    return {
      s_name: this.name,
      s_background: this.background,
      s_solution: this.solution,
      s_features: this.features,
      lg: 4
    };
  },
  created() {
    if(this.s_features) {
      if(this.s_features.length == 4) {
        this.lg = 3
      }
    }
  }
};
</script>

<style scoped>
</style>

