<template>
  <v-container class="page-404">
    <search-bar />
    <div class="container">
      <div class="page-header">
        <div class="page-header-main">
          <p class="text-center oops">Oops!</p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="info-table px-md-5" id="contract-info">
        <div class="text-center info-table-subtitle poppins-font">
          Seems nothing's found. Try another keyword again.
        </div>
      </div>

      <div
        class="action text-center"
        style="padding-top: -120px; margin-bottom: 300px"
      >
        <router-link
          to="/"
          class="btn btn-sm btn-light rounded-circle font-weight-bold bg-white mr-0 mr-sm-2 mb-sm-0 mb-3 text-uppercase"
        >
          Home
        </router-link>
      </div>
    </div>
  </v-container>
</template>

<script>
import SearchBar from "../explorer/SearchBar.vue";
export default {
  components: { SearchBar },
  name: "Search404",
};
</script>

<style scoped>
.page-404 {
  margin-top: 100px;
  margin-bottom: 50px;
  min-width: 100%;
  font-family: "Poppins", sans-serif !important;
}
</style>
