<template>
  <v-pagination
    v-model="m_page"
    :length="pageLength"
    :total-visible="6"
  />
</template>

<script>
export default {
  name: "Pagination",
  props: ["page", "count", "limit", "change"],
  data() {
    return {
      m_page: this.page
    }
  },
  watch: {
    m_page(val) {
      if(this.change) {
        this.change(val)
      }
    }
  },
  computed: {
    pageLength() {
      return (
        (this.count - (this.count % this.limit)) / this.limit +
        (this.count % this.limit == 0 ? 0 : 1)
      );
    },
  },
};
</script>
