<template>
  <v-container>
    <div class="text-center" v-if="loading" style="margin-top: 100px; margin-bottom: 100px">
      <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <v-col lg="12"> 
          <div class="info-table" style="width: 100%; margin-left: 0px; margin-right: 0px">
            <div class="info-table-list">
              <div class="info-table-list-content">
                <div class="table">
                  <div class="row list-header">
                    <div class="col text-truncate">TxHash</div>
                    <div class="col text-truncate">Block</div>
                    <div class="col text-truncate marginfix">Age</div>
                    <div class="col text-truncate">From</div>
                    <div class="col-1 text-truncate" v-if="address">&nbsp;</div>
                    <div class="col text-truncate">To</div>
                    <div class="col text-truncate">Value</div>
                    <div class="col text-truncate">TxFee</div>
                  </div>
                  <div v-if="txs.length == 0" style="
                      color: #aaa;
                      font-size: 0.8rem;
                      width: 100%;
                      text-align: center;
                    ">
                    No transactions
                  </div>
                  <div class="row row-content" style="padding-left: 7px" v-for="tx in txs" v-bind:key="tx.hash">
                    <div class="text-truncate" v-if="tx.status == 0">
                      <img style="height: 16px; width: 16px; margin-right: 6px" :src="require('@/assets/img/explorer_th.png')" />
                    </div>
                    <div style="width: 22px" v-if="tx.status != 0"></div>
                    <div class="col text-truncate">
                      <span>TxHash:</span>
                      <router-link :to="'/explorer/tx/' + tx.hash">{{
                        tx.hash
                      }}</router-link>
                    </div>
                    <div class="col text-truncate">
                      <span>Block:</span>
                      <router-link :to="'/explorer/block/' + tx.blockNumber" v-cloak>{{ tx.blockNumber }}</router-link>
                    </div>
                    <div class="col text-truncate marginfix">
                      <span>Age:</span>{{ convertTime(tx.timestamp) }}
                      ago
                    </div>
                    <div class="col text-truncate">
                      <span>From:</span>
                      <router-link v-if="!address || address && tx.from.toLowerCase() != address.toLowerCase()" :to="'/explorer/address/' + tx.from">{{
                        tx.from_alias ? tx.from_alias : tx.from
                      }}</router-link>
                      <label v-else>
                        {{ (tx.from_alias ? tx.from_alias : tx.from) | truncateAddr }}
                      </label>
                    </div>
                    <div class="col-1 text-truncate" v-if="address" :class="{
                        'flag-in': tx.flag == 'in',
                        'flag-out': tx.flag == 'out',
                      }">
                      <span>{{ tx.flag }}</span>
                    </div>
                    <div class="col text-truncate">
                      <span>to:</span>
                      <router-link v-if="!address && tx.to || address && tx.to && tx.to.toLowerCase() != address.toLowerCase()" :to="'/explorer/address/' + tx.to">{{ tx.to_alias ? tx.to_alias:tx.to }}</router-link>
                      <label v-if="!(!address && tx.to || address && tx.to && tx.to.toLowerCase() != address.toLowerCase())">
                        {{ (tx.to_alias ? tx.to_alias:tx.to) | truncateAddr }}
                      </label>
                      <router-link v-if="!tx.to && (!address || address != tx.contract)" :to="'/explorer/address/' + tx.contract">Contract Creation</router-link>
                      <label v-if="!tx.to && (address == tx.contract)">
                        {{ tx.to | truncateAddr }}
                      </label>
                    </div>
                    <div class="col text-truncate">
                      <span>Value:</span> {{ tx.value }} CPC
                    </div>
                    <div class="col text-truncate">
                      <span>TxFee:</span>{{ tx.txfee }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <v-row style="font-size: 14px; color: #aaa" v-if="count > 0" class="pl-0">
              <v-col lg="5" class="pl-0">
                <span style="padding-left: 29px; opacity: 0.8">Total Transactions:&nbsp;</span>
                <label>{{ count }} txs</label>
              </v-col>
              <v-spacer />
              <v-col lg="3" style="text-align: right; cursor: pointer" v-if="address">
                <v-icon size="14" @click="show_date = true;">fas fa-download</v-icon>
              </v-col>
            </v-row>
            <pagination style="margin-top: 20px" :page="page" :count="count" :limit="limit" :change="pageChange" v-if="!blockHash && count > 0" />
          </div> 
      </v-col>
    </v-row>
    <!-- Dialog for Choose Date -->
    <v-dialog v-model="show_date" width="450">
      <v-card>
        <v-card-title> STEP 1: Choose Date </v-card-title>
        <v-divider></v-divider>

        <v-card-text style="text-align: center; margin-bottom: -30px; margin-top: 30px;">
          <v-row>
            <v-col cols="12">
              <v-date-picker v-model="export_dates" range elevation="24"></v-date-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span style="font-size: 12px;">Export the earliest {{ this.explorer_export_limit }} records starting from</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="show_date = false;">
            Cancel
          </v-btn>
          <v-btn color="primary" text :disabled="date_disable" @click="show_date = false;show_verify = true;show_download = true;loaded();">
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog for Verify -->
    <v-dialog v-model="show_verify" width="450">
      <v-card>
        <v-card-title> STEP 2: Verify </v-card-title>
        <v-divider></v-divider>

        <v-card-text style="text-align: center; margin-bottom: -30px; margin-top: 30px;">
          <v-row>
            <v-col cols="12">
              <div id ="recaptcha_export" class ="recaptcha_export"></div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="show_verify = false;">
            Cancel
          </v-btn>
          <v-btn color="primary" text :disabled="show_download" @click="exportTxs()" download :href="export_url">
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import { formatTS } from "@/utils/ts";
import Pagination from "../Pagination.vue";

export default {
  components: { Pagination },
  name: "TxsList",
  props: ["blockHash", "address", "tx_type", "query_from", "query_to", "query_exclude_campaign"],
  filters: {
    truncateAddr(addr) {
      if (!addr) {
        return ""
      }
      if (addr.length < 15) {
        return addr
      }
      return addr.substr(0, 9) + "…"
    }
  },
  data() {
    return {
      show_date: false,
      show_verify: false,
      show_download: false,
      loading: false,
      txs: [],
      export_dates: [],
      explorer_export_limit: 3000,
      page: 1,
      limit: 25,
      count: 0,
      widgetId: [],
      token: "",
      verify: "",
      // sitekey: '6LcEb-QcAAAAAHF7uzMrD4NJcY0UkOo8_RAm7Rj6',
      sitekey: process.env.VUE_APP_SITEKEY,
      country: "",
    };
  },
  mounted() {
    if (this.blockHash) {
      this.fetchTxsOfBlock();
    } else {
      this.fetchData();
    }
    this.ip_country();
  },
  watch: {
    page() {
      if (this.blockHash) {
        this.fetchTxsOfBlock();
      } else {
        this.fetchData();
      }
    },
    address() {
      this.reload()
    },
    tx_type() {
      this.reload()
    },
    query_from() {
      this.reload()
    },
    query_to() {
      this.reload()
    },
    query_exclude_campaign() {
      this.reload()
    }
  },
  computed: {
    export_url() {
      if (this.export_dates.length == 2) {
        let start_at = this.export_dates[0]
        let end_at = this.export_dates[1]
        let base = process.env.VUE_APP_BASE_API
        if (base[base.length-1] != '/') {
          base += "/"
        }
        return  base + `explorer/api/export-txs/?address=${this.address}&start_date=${start_at}&end_date=${end_at}`
      }
      return "javascript:void(0)"
    },
    date_disable() {
      if (this.export_dates.length == 0) {
        return true
      }
      else {
        return false
      }
    }
  },
  methods: {
    reset() {
      window.grecaptcha.reset(this.widgetId)
    },

    verify_export() {
      this.$http.get(`/explorer/api/verify-export/?responseToken=${this.token}&country=${this.country}`).then((data) => {
        this.verify = data
        console.log(this.verify)
      })
    },

    exports_limit() {
      this.$http.get(`/api/variables/?key=EXPLORER_EXPORT_LIMIT`).then((data)=> {
        this.explorer_export_limit = data.results[0].value
      })
    },

    ip_country() {
      this.$httpip.get(`json/`).then((data) => {
        this.country = data.country
        console.log(this.country)
      })
    },


    submit: function(token) {
      // console.log(token);
      this.token = token;
      this.show_download = false;
      this.verify_export();
    },

    loaded() {
      setTimeout(() => {
        try {this.widgetId = window.grecaptcha.render("recaptcha_export", {
          sitekey: this.sitekey,
          callback: this.submit,
          "expired-callback": this.handleCaptchaExpired,
        });}
        catch {
          window.grecaptcha.reset(this.widgetId)
        }
      }, 200);
      
    },

    handleCaptchaExpired() {
      this.show_download = true
    },


    reload() {
      this.page = 1;
      this.count = 0;
      this.fetchData();
    },
    pageChange(page) {
      this.page = page;
    },
    fetchTxsOfBlock() {
      this.loading = true;
      this.$http
        .get(`/explorer/api/blocks/${this.blockHash}/`)
        .then((data) => {
          this.txs = data.transactions;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchData() {
      this.loading = true;
      this.exports_limit();
      let params = {
        page: this.page,
        limit: this.limit,
      };
      if (this.address) {
        params.address = this.address;
      }
      if (this.tx_type) {
        params.type = this.tx_type.toLowerCase();
      }
      if (this.query_from) {
        params.from = this.query_from
      }
      if (this.query_to) {
        params.to = this.query_to
      }
      if (this.query_exclude_campaign) {
        // 排除 Campaign 交易
        params.exclude_to = '0x2a186be66dd20c1699add34a49a3019a93a7fcd0'
      }
      this.$http
        .get(`/explorer/api/txs/`, { params: params })
        .then((data) => {
          this.txs = data.results;
          this.count = data.count;
          this.loading = false;
          if (this.address) {
            this.txs.forEach((item) => {
              item.flag = "in";
              if (item.from == this.address.toLowerCase()) {
                item.flag = "out";
              }
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    convertTime: function (tss) {
      var cts = parseInt((Date.now() + 500) / 1000);
      return formatTS(cts - tss);
    },
    exportTxs() {
      if (this.export_url != "javascript:void(0)") {
        this.show_verify = false
      }
    },
    convertDate(date) {
      return Math.round(new Date(date).getTime() / 1000);
    }
  },
};
</script>

<style scoped>

.pl-0 {
  padding-left: 0px;
}

.recaptcha_export {
  margin-right:auto;
  margin-left:auto;
  margin-bottom: 20px;
  /* margin: auto; */
  text-align: center;
  width:300px;
  /* margin-top:20px; */
}
</style>
